export const CONSTANTES = {
    FALLECIDO_ID: 13,
    MSJ_TITULO_ERROR : 'No hemos podido realizar esta acción',
    MSJ_TITULO_ERROR_2: 'No se pudo obtener los datos del paciente',
    MSJ_TITULO_ERROR_PAB : 'No hemos podido guardar la información de pabellón',
    MSJ_SUB_TITULO_ERROR : 'Vuelve a intentarlo. Si el problema persiste ponte en contacto con el administrador.',
    MSJ_TITULO_FALLECIDO : 'El RUT ingresado no puede ser utilizado para una admisión',
    MSJ_TITULO_NO_ENCONTRADO : 'El RUT o Pasaporte no se encuentra registrado en el sistema',
    MSJ_CONTENIDO_NO_ENCONTRADO : 'Verifique que el número de RUT o Pasaporte ingresado sea el correcto o ingrese una nueva persona.',
    MSJ_RESUMEN_ERROR: 'No se pudieron obtener los datos solicitados',
    MSJ_RUT:"El RUT ingresado corresponde a ",
    MSJ_RUT_FALLECIDO:" y el sistema indica que se encuentra fallecido",
    COTIZANTE_PACIENTE: 'Paciente',
    COTIZANTE_PACIENTE_ID: 1,
    SELECT_OPCION_DEFECTO: 'Selecciona opción',
    SIN_PREVISION: 0,
    PREVISION_PARTICULAR: 200,
    INDICADORES_GLOSA_PACIENTE_INSTITUCIONAL: 'paciente institucional',
    INDICADORES_GLOSA_SOCIO: 'socio',
    INDICADORES_GLOSA_TODO_ALEMANA: 'todo alemana',
    INDICADORES_GLOSA_ALEMANA_TOTAL: 'alemana total',
    INDICADORES_GLOSA_PLAN_PREFERENTE: 'plan preferente',
    PACIENTE_TIPO_INSTITUCIONAL: 1,
    PACIENTE_TIPO_PRIVADO: 2,
    MSJ_TITULO_NO_ROLES: 'No tiene privilegios para acceder',
    MSJ_TITULO_NO_ROLES_PERFILES: 'Error al consultar Roles y Perfiles',
    MSJ_CONTENIDO_NO_ROLES: 'Se cerrará la sesión',
    PAIS_CHILE:'chile',
    MSJ_RESPONSABLE_NO_ENCONTRADO: "El responsable del documento de respaldo debe estar registrado. ¿Quiere ingresar una nueva persona?",
    RELACION_OBLIGATORIA: "Debe seleccionar una relación con el paciente.",
    TELEFONO_OBLIGATORIO: "Debe ingresar un Teléfono Celular debido a que este paciente poseía un teléfono celular.",
    EMAIL_OBLIGATORIO: "Debe ingresar un Email debido a que este paciente poseía un Email.",
    FIJO_OBLIGATORIO: "Debe ingresar un Teléfono fijo debido a que este paciente poseía un teléfono fijo.",
    ERROR_INTEGRACION:"Ocurrio un error con integración cliente.",
    MSJ_ERROR_CAMPOS_FALTANTES:"Debe completar los campos faltantes para continuar",
    MSJ_ERROR_SERVICIO:"Problemas con cliente, contacte al administrador",
    MSJ_ERROR_SERVICIO_FASE2:"Problemas con Fase II, contacte al administrador",
    TITLE_PREVIOUS_INFO : '¿Desea mantener información entregada en admisión anterior?',
    MSJ_PREVIOUS_INFO : '¿Desea mantener información entregada en admisión anterior?',
};

export const CONST_AUTOADM = {
    TITULO_PASO2: 'Revisa los datos médicos de tu atención.',
    TIT_PABELLON_PASO2: 'Lo sentimos',
    MSJ_PABELLON_PASO2: 'No se encontró ningún pabellón agendado.',
    MSJ_UPDATE_INFO: 'Información actualizada exitosamente.',
    MSJ_NOTUPDATE_ERROR: 'No fue posible actualizar: ',
    MSJ_FORM_ERROR: 'Verifica la información ingresada ',
    MSJ_NOTFOUND_INFO: 'No se encontró información previa',
    MSJ_SEGUROS_COLECTIVOS: 'Ten en cuenta que, nosotros solo gestionamos seguros colectivos en convenio.',
    MSJ_INFO_SEGUROS_COLECTIVOS: 'Aplica para seguros en convenio, para coordinar el reembolso de hospitalización y luego informar el saldo final a pagar. Consulta con tu compañía si eres beneficiario.',
    MSJ_MAX_SEGUROS: 'Solo puedes registrar hasta 3 seguros.',
    MSJ_LIMITE_CHECKIN:'Has alcanzado el máximo de 5 check-ins permitidos.',
    TITULO_PASO1: 'Revisa tus datos para comenzar la admisión.',
    TITULO_INFO_PREVISIONAL: 'Información previsional',
    TITULAR_PREVISION: 'Por favor, ingresa los datos del titular de la previsión.',
    MSJ_SIN_CHECKINS: 'Aún no has registrado ningún check-in',
    TITULO_ADJUNTA_CEDULA: 'Ahora, adjunta la cédula de quien firma',
    FORMATO_CEDULA: 'Formatos permitidos: jpg, jpeg, png, pdf y docx. Tamaño máximo permitido: 5 MB.',
    ERROR_FORMATO_CEDULA: 'El formato de los archivos no es válido y/o son demasiado grandes.',
    HELPER_TEXT_CELULAR: 'Debes ingresar un teléfono válido.',
    HELPER_TEXT_CORREO: 'Debes ingresar un email válido.',
    HELPER_TEXT_PREVISION: 'Debes seleccionar tu previsión.',
    HELPER_TEXT_PAIS: 'Debes seleccionar el país donde vives.',
    HELPER_TEXT_CIUDAD: 'Debes ingresar tu ciudad.',
    HELPER_TEXT_COMUNA: 'Debes seleccionar tu comuna.',
    HELPER_TEXT_DIRECCION: 'Recuerda ingresar tu dirección.',
    HELPER_TEXT_NOMBRE: 'Recuerda ingresar el nombre.',
    HELPER_TEXT_APELLIDO: 'Recuerda ingresar el apellido.',
    TITULO_SEGURO_COMPLEMENTARIO: 'Datos de tu seguro complementario',
    MSJE_ISAPRE_SIN_CONV: '¡Isapre sin convenio! Debes pagar tu hospitalización de forma particular y luego solicitar el reembolso en tu previsión de salud.',
    MSJE_RESP_PAGARE: 'El día del ingreso, una persona mayor de 18 años debe firmar el pagaré con cédula chilena vigente. Si es pasaporte, contacta a pacienteinternacional@alemana.cl.',
}

export const DEC_ACCIDENTE_ISAPRE = [
    {
        "codigo": "20",
        "glosa": "Banmédica S.A."
    },
    {
        "codigo": "40",
        "glosa": "Colmena Golden Cross S.A."
    },
    {
        "codigo": "50",
        "glosa": "Consalud S.A."
    },
    {
        "codigo": "110",
        "glosa": "Isapre CruzBlanca S.A."
    },
    {
        "codigo": "120",
        "glosa": "Nueva Masvida"
    },
    {
        "codigo": "190",
        "glosa": "Vida Tres S.A."
    },
    {
        "codigo": "10",
        "glosa": "Esencial S.A."
    }
];