import { Component, OnInit ,Input} from '@angular/core';

@Component({
  selector: 'app-icon-ds',
  templateUrl: './icon-ds.component.html',
  styleUrls: ['./icon-ds.component.scss']
})
export class IconDsComponent implements OnInit {

  @Input () size: string = "30";
  @Input () iconName: string = "alarm";
  @Input () color: string = "primary";

  icon=""
  sizeRem="";
  constructor() { }

  ngOnInit(): void {
    this.sizeRem="font-size:"+parseInt(this.size)+"px;color:"+this.color;
    this.icon="bi bi-"+this.iconName;
  }

}
