<div *ngIf="size !== 'small'" class="form-floating">
    <select class="form-select select" [id]="idDs" [disabled]="disabled" (change)="changeOption($event)" [name]="name"
            [ngClass]="{'disabled-select': disabled,
                        'select-error': state === 'error',
                        'select-medium': size === 'medium',
                        'hospitalizados': variant === 'hospitalizados',
                        'urgencias': variant ==='urgencias',
                        'ambulatorios': variant ==='ambulatorios',
                        'sucursales_admisiones_realizadas': variant ==='sucursales_admisiones_realizadas',
                        'message': message !=='',
                        'message-valid': messageValid !=='',
                        'message-invalid': messageInvalid !==''
                        }">
        <option *ngFor="let item of source" [value]="item.label" [selected]="item.selected" >
            {{item.label}}
        </option>
    </select>
    <label class="label-select" for="floatingSelect">{{label}}</label>

    <div *ngIf="message !==''" class="message-select">
        {{message}}
    </div>
    <div *ngIf="messageValid !==''" class="valid-select">
        {{messageValid}}
    </div>
    <div *ngIf="messageInvalid !==''" class="invalid-select">
        {{messageInvalid}}
    </div>

</div>

<div *ngIf="size === 'small'">
    <label class="label-select-small">{{label}}</label>
    <select  class="form-select select-small" (change)="changeOption($event)" [id]="idDs"
                        [disabled]="disabled"
                        [ngClass]="{
                        'disabled-select': disabled,
                        'select-error': state === 'error',
                        'hospitalizados': variant === 'hospitalizados',
                        'urgencias': variant ==='urgencias',
                        'ambulatorios': variant ==='ambulatorios',
                        'sucursales_admisiones_realizadas': variant ==='sucursales_admisiones_realizadas',
                        'message': message !=='',
                        'message-valid': messageValid !=='',
                        'message-invalid': messageInvalid !==''}">

        <option *ngFor="let item of source" [value]="item.label" [selected]="item.selected">
            {{item.label}}
        </option>

    </select>

    <div *ngIf="message !==''" class="message-select">
        {{message}}
    </div>
    <div *ngIf="messageValid !==''" class="valid-select">
        {{messageValid}}
    </div>
    <div *ngIf="messageInvalid !==''" class="invalid-select">
        {{messageInvalid}}
    </div>
</div>

