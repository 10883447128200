import { Injectable } from '@angular/core'

@Injectable()
export class Mails{
    constructor(){
    }

    templateMail(type, parametros) {
        let cuerpoMail = ''
        if(type == 'cobroCama'){
            cuerpoMail = "<div>Estimada</div>"
            cuerpoMail = cuerpoMail + "<div>Se le informa que se asigna hab."+parametros.numHab+" paciente "+parametros.nombreApellido+" "+parametros.labelCobroCama+" N° de cuenta "+parametros.cuentaCorriente+"</div>"
            cuerpoMail = cuerpoMail + "<br><div>Saludos</div>"
            cuerpoMail = cuerpoMail + "<div>"+parametros.user+"</div>"
        }

        if(type == 'convenioAccidente'){
            cuerpoMail = "<div>Estimados</div>"
            cuerpoMail = cuerpoMail + "<div>Se informa nuevo ingreso</div><br>"
            cuerpoMail = cuerpoMail + "<div>Nombre paciente: "+parametros.nombreApellido+"</div>"
            cuerpoMail = cuerpoMail + "<div>Rut o pasaporte: "+parametros.rutPasaporte+"</div>"
            cuerpoMail = cuerpoMail + "<div>Correo del paciente: "+parametros.mailPaciente+"</div>"
            cuerpoMail = cuerpoMail + "<div>Teléfono del paciente: "+parametros.telefonoPaciente+"</div>"
            cuerpoMail = cuerpoMail + "<div>Cuenta corriente: "+parametros.cuentaCorriente+"</div>"
            cuerpoMail = cuerpoMail + "<div>Hora y fecha de ingreso: "+parametros.horaFecha+"</div>"
            cuerpoMail = cuerpoMail + "<div>Nombre de cirugia: "+parametros.cirugia+"</div>"
            cuerpoMail = cuerpoMail + "<div>Nombre de médico tratante: "+parametros.medicoTratante+"</div>"
            cuerpoMail = cuerpoMail + "<div>Nombre de isapre: "+parametros.isapre+"</div><br>"

            cuerpoMail = cuerpoMail + "<div>Saludos</div>"
            cuerpoMail = cuerpoMail + "<div>"+parametros.user+"</div><br>"
        }

        if(type == 'pacienteInternacional'){
            cuerpoMail = "<div>Estimados,</div>"
            cuerpoMail = cuerpoMail + "<div>Información ingreso de paciente " + parametros.nombreApellido + " Rut/Pasaporte " + parametros.rutPasaporte  + "</div><br>"
            cuerpoMail = cuerpoMail + "<div>Cuenta corriente: "+parametros.cuentaCorriente+"</div>"
            cuerpoMail = cuerpoMail + "<div>Hora y fecha de ingreso: "+parametros.horaFecha+"</div>"
            cuerpoMail = cuerpoMail + "<div>Nombre de cirugia: "+parametros.cirugia+"</div>"
            cuerpoMail = cuerpoMail + "<div>Nombre médico tratante: "+parametros.medicoTratante+"</div>"
            cuerpoMail = cuerpoMail + "<div>Seguro: "+parametros.seguro+"</div>"
            cuerpoMail = cuerpoMail + "<div>Poder: "+parametros.poder+"</div><br>"

            cuerpoMail = cuerpoMail + "<div>Saludos</div>"
            cuerpoMail = cuerpoMail + "<div>"+parametros.user+"</div><br>"
        }

        if(type == 'cartaEmpresa'){
            cuerpoMail = "<div>Estimados</div>"
            cuerpoMail = cuerpoMail + "<div>Paciente " + parametros.nombreApellido + " Rut/Pasaporte " + parametros.rutPasaporte  + " ingresa con carta de empresa de " + parametros.empresa + "</div><br>"
            cuerpoMail = cuerpoMail + "<div>Cuenta corriente: "+parametros.cuentaCorriente+"</div>"
            cuerpoMail = cuerpoMail + "<div>Hora y fecha de ingreso: "+parametros.horaFecha+"</div>"
            cuerpoMail = cuerpoMail + "<div>Nombre de cirugia: "+parametros.cirugia+"</div>"
            cuerpoMail = cuerpoMail + "<div>Nombre médico tratante: "+parametros.medicoTratante+"</div><br>"

            cuerpoMail = cuerpoMail + "<div>Saludos</div>"
            cuerpoMail = cuerpoMail + "<div>"+parametros.user+"</div><br>"
        }

        if(type == 'estudiosClinicos'){
            cuerpoMail = "<div>Estimados</div>"
            cuerpoMail = "<div>Se informa ingreso paciente:</div>"
            cuerpoMail = cuerpoMail + "<div>Nombre de paciente: " + parametros.nombreApellido + "</div><br>"
            cuerpoMail = cuerpoMail + "<div>Rut: " +parametros.rutPasaporte + "</div><br>"
            cuerpoMail = cuerpoMail + "<div>Cuenta Cte: "+ parametros.cuentaCorriente+"</div>"
            cuerpoMail = cuerpoMail + "<div>Procedimiento/Cirugía: "+parametros.cirugia+"</div>"
            cuerpoMail = cuerpoMail + "<div>Fecha de ingreso: "+parametros.horaFecha+"</div>"
            cuerpoMail = cuerpoMail + "<div>Médico tratante: "+parametros.medicoTratante+"</div><br>"
            cuerpoMail = cuerpoMail + "<div>Saludos</div>"
            cuerpoMail = cuerpoMail + "<div>"+parametros.user+"</div><br>"
        }

        if(type == 'gesIsapre'){
            cuerpoMail = "<div>Estimados</div>"
            cuerpoMail = "<div>Se informa ingreso Ges Isapre:</div>"
            cuerpoMail = cuerpoMail + "<div>Nombre de paciente: " + parametros.nombreApellido + "</div><br>"
            cuerpoMail = cuerpoMail + "<div>Rut: " +parametros.rutPasaporte + "</div><br>"
            cuerpoMail = cuerpoMail + "<div>Cuenta Cte: "+ parametros.cuentaCorriente+"</div>"
            cuerpoMail = cuerpoMail + "<div>Nombre Isapre: "+ parametros.empresa+"</div>"
            cuerpoMail = cuerpoMail + "<div>Nombre cirugía: "+parametros.cirugia+"</div>"
            cuerpoMail = cuerpoMail + "<div>Fecha de ingreso: "+parametros.horaFecha+"</div>"
            cuerpoMail = cuerpoMail + "<div>Médico tratante: "+parametros.medicoTratante+"</div><br>"
            cuerpoMail = cuerpoMail + "<div>Saludos</div>"
            cuerpoMail = cuerpoMail + "<div>"+parametros.user+"</div><br>"
        }

        


        return cuerpoMail;
    }


}
