import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../../environments/environment';
import { map } from 'rxjs/operators';


@Injectable({
  providedIn: 'root'
})
export class IndicadoresService {

  private headers: HttpHeaders;
  private indicadores: any;

  constructor(private http: HttpClient) {
    this.headers = new HttpHeaders();
    this.headers = this.headers.append('Content-Type', 'application/json');
  }
  
    obtenerIndicadoresServices(ppn: string, token: string) : Observable<any> {
       return this.http.get( environment.urlMsSisnadhos + "/integracion/widget/" + ppn,   {
      }).pipe(
        map(result => {
          this.setIndicadores(result);
          return result;
        })
      );
    }

    guardarIndicadoresServices(idAdmision: string, listaIndicadores: any) : Observable<any> {
       return this.http.post( environment.urlMsSisnadhos + "/admision/" + idAdmision + "/indicadores-cliente", listaIndicadores,  {
        headers: this.headers,
      });
    }

    getIndicadores() {
      return this.indicadores;
    }

    setIndicadores(indicadores) {
      this.indicadores = indicadores;
    }
    
  }

