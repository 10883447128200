import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { environment } from "src/environments/environment";

@Injectable({
    providedIn: 'root'
})
export class AgendaPabellonService {
  public headers: HttpHeaders;
  constructor(private http: HttpClient) { }

  obtenerPabellones(body: any) {
    return this.http.post(environment.urlMsSisnadhos + "/agenda-pabellon", body, {
      headers: this.headers
    });
  }



  obtenerAdmisionPabellon (id): Observable<any>{
    return this.http.get(environment.urlMsSisnadhos + "/pabellon/" + id +"/pabellon" , {
      headers: this.headers
    });
  }

  crearAdmisionPabellon (id,body: any): Observable<any>{
    return this.http.post(environment.urlMsSisnadhos + "/pabellon/" + id +"/pabellon",body, {
      headers: this.headers
    });
  }

  modificarAdmisionPabellon (id,body: any): Observable<any>{
    return this.http.put(environment.urlMsSisnadhos + "/pabellon/"+ id +"/pabellon" ,body, {
      headers: this.headers
    });
  }

}