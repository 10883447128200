import {enableProdMode, InjectionToken} from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';

enableProdMode();

export const ENVIRONMENT = new InjectionToken<Record<string, unknown>>(
  'environment'
);

const main = async () => {
  platformBrowserDynamic([
    { provide: ENVIRONMENT, useValue: environment },
  ])
    .bootstrapModule(AppModule)
    .catch((err) => console.error(err));
};

main();