export class AntecedenteHosp {
    origenProcedencia:    string;
    fechaIngreso:         any;//Date;
    horaIngreso:          string;
    motivoCodigo:         string;
    diagnostico:          string;
    procedimientoCirugia: string;
    medicoTratantePpn:    number;
    medicoTratanteNombre: string;
    codigoPabellon:       number;
    fechaPabellon:        any;//Date;
    horaPabellon:         string;
    medicoTratanteRut:    string;
    modalidad:            string;
    cirugiaTraumatologica: boolean;
    motivoDescrip?:        string;

    public constructor(){
        this.origenProcedencia = "";
        this.fechaIngreso = "";
        this.horaIngreso = "";
        this.motivoCodigo = "";
        this.diagnostico = "";
        this.procedimientoCirugia = "";
        this.medicoTratantePpn = 0;
        this.medicoTratanteNombre = "";
        this.codigoPabellon = 0;
        this.fechaPabellon = "";
        this.horaPabellon = "";
        this.medicoTratanteRut = "";
        this.modalidad = "";
        this.cirugiaTraumatologica = null;
    }

    
}
