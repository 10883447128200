import { Contacto } from "./contacto.model";

export class Cliente {
    ppn: string;
    rut: string;
    nombres: string;
    primer_apellido: string;
    segundo_apellido: string;
    nombre_social: string;
    sexo: string;
    fecha_nacimiento: string;
    edad: number;
    nacionalidad: string;
    pais_residencia: string;
    contacto: Contacto;
    verification_document : string;
    isRut: boolean;
    indicadores: any;
    internacional: boolean;
    prevision: any;
    company_id: number;
    virtual_master_id: number;
    // procedencia: string;


    //Inicializa una instancia de la clase TextoLibre
    constructor() {
        this.ppn = "";
        this.rut = "";
        this.nombres = "";
        this.primer_apellido = "";
        this.segundo_apellido = "";
        this.nombre_social = "";
        this.sexo = "";
        this.fecha_nacimiento = "";
        this.edad = 0;
        this.nacionalidad = "";
        this.pais_residencia = "";
        this.contacto = new Contacto;
        this.verification_document = "";
        this.isRut = false;
        this.indicadores = [];
        this.internacional = false;
        this.prevision =  { name: ''};
        // this.procedencia = "";
      }

}


