export function formatPabellon(pabellonDisponible: any, ppnPaciente: any): Pabellon {
    return {
        idReserva: pabellonDisponible.idReserva,
        sucursal: pabellonDisponible.sucursal,
        idSucursal: pabellonDisponible.idSucursal,
        pabellon: pabellonDisponible.pabellon,
        codPabellon: pabellonDisponible.codPabellon,
        cirugia: pabellonDisponible.cirugia,
        fechaPabellon: new Date(pabellonDisponible.fechaPabellon),
        horaPabellon: pabellonDisponible.horaPabellon,
        diagnostico: pabellonDisponible.diagnostico,
        ppnMedico: pabellonDisponible.ppnMedico,
        ppnPaciente: ppnPaciente, 
        enlaceQr: pabellonDisponible.enlaceQR,
        base64Qr: pabellonDisponible.base64QR,
        idIngresoQr: pabellonDisponible.idIngresoQR,
        apellidoIngresoQr: pabellonDisponible.apellidoIngresoQR,
        nombreVisor: pabellonDisponible.nombreVisor,
        codModalidad: pabellonDisponible.codModalidad,
        nombreModalidad: pabellonDisponible.nombreModalidad,
        codDestino: pabellonDisponible.codDestino,
        nombreDestino: pabellonDisponible.nombreDestino,
        codDiasHospitalizacion: pabellonDisponible.codDiasHospitalizacion,
        nombreDiasHospitalizacion: pabellonDisponible.nombreDiasHospitalizacion
    };
}

export interface Pabellon {
    idReserva: number;
    sucursal: string;
    idSucursal: number;
    pabellon: string;
    codPabellon: number;
    cirugia: string;
    fechaPabellon: any;
    horaPabellon: string;
    diagnostico: null;
    ppnMedico: string;
    ppnPaciente: string;
    enlaceQr: string;
    base64Qr: string;
    idIngresoQr: string;
    apellidoIngresoQr: string;
    nombreVisor: string;
    codModalidad: number;
    nombreModalidad: string;
    codDestino: number;
    nombreDestino: string;
    codDiasHospitalizacion: number;
    nombreDiasHospitalizacion: string;
}