<div class="container w-100 px-3 pb-5"
  style="background-color: transparent !important; padding: 0px !important">
  <div class="header-sep">
    <cas-text variant="headline2">
      {{titulo}}
    </cas-text>
  </div>
  <cas-text variant="headline3">
    {{ paciente.nombres | titlecase }}
    {{ paciente.primer_apellido | titlecase }}
    {{ paciente.segundo_apellido | titlecase }}
  </cas-text>
  <div class="iconos-linea">
    <div class="icono-texto">
      <cas-icon name="flat-identification" size="16" class="icono"></cas-icon>
      <cas-text variant="caption1">{{ paciente.rut }}</cas-text>
    </div>
    <div class="icono-texto">
      <cas-icon name="flat-birth" size="16" class="icono"></cas-icon>
      <cas-text variant="caption1">{{ paciente.edad }} años</cas-text>
    </div>
    <div class="icono-texto">
      <cas-icon name="flat-sex" size="16" class="icono"></cas-icon>
      <cas-text variant="caption1">{{ paciente.sexo }}</cas-text>
    </div>
    <div class="icono-texto-a tooltip-icono-large">
      <cas-tooltip [placement]="tooltipPlacement" [content]="tooltipContent">
        <app-icon-ds color="#FFB300" iconName="question-circle-fill" size="16"></app-icon-ds>
      </cas-tooltip>
    </div>
  </div>
</div>


<div class="container w-100 px-3 py-3 pb-5 card" style="border-radius: 8px;" *ngIf="infoLoaded$ | async"><!-- container-tablet-->

  <form [formGroup]="formDatosPersonales">
    <!-- Seccion primera: Campos de Acompañante -->
    <div class="d-flex flex-wrap align-center justify-content-center w-100" style="margin-top:1vh">
      <!-- Correo -->
      <div class="col-12 col-md-6 col-xl-6 p-1">
        <cas-input class="input-auto" formControlName="correo" helperText="{{helperTextCorreo}}"
          isInputError="{{ isCorreoError }}" label="E-mail" value="{{ this.formDatosPersonales.value.correo }}"
          [placeholder]="labelCorreo" label="{{ labelCorreo }}" (change)="changeSelect($event, 'correo')" fullWidth
          required></cas-input>
      </div>
      <!-- Fin Correo -->
      <!-- Celular -->
      <div class="col-12 col-md-6 col-xl-6 p-1">
        <cas-input formControlName="celular" [label]="labelCelular" (change)="changeSelect($event, 'celular')"
          [placeholder]="labelCelular" class="input-auto" helperText="{{helperTextCelular}}"
          isInputError="{{ isCelularError }}" value="{{ this.formDatosPersonales.value.celular }}" fullWidth required
          (keyup)="onKeyUp($event)"></cas-input>
      </div>
      <!-- Fin Celular -->
      <!-- Pais -->
      <div class="col-12 col-md-6 col-xl-6 p-1">
        <mat-form-field class="custom-select-material input-auto">
          <mat-select id="id-cas-pais" formControlName="pais" placeholder="País"
            (selectionChange)="changeSelect($event, 'pais')">
            <mat-option *ngFor="let pais of nombresPaises" [value]="pais">
              {{ pais }}
            </mat-option>
          </mat-select>
          <mat-hint *ngIf="isPaisError" class="error-text">{{helperTextPais}}</mat-hint>
        </mat-form-field>
      </div>
      <!-- Fin Pais -->
      <!-- Ciudad -->
      <div class="col-12 col-md-6 col-xl-6 p-1">
        <cas-input formControlName="ciudad" label="{{labelCiudad}}" [placeholder]="labelCiudad"
          (change)="changeSelect($event, 'ciudad')" class="input-auto" helperText="{{helperTextCiudad}}"
          isInputError="{{ isCiudadError }}" value="{{ this.formDatosPersonales.value.ciudad }}" fullWidth
          required></cas-input>
      </div>
      <!-- Fin Ciudad -->
      <!-- Comuna -->
      <div class="col-12 col-md-6 col-xl-6 p-1">
        <mat-form-field class="custom-select-material input-auto">
          <mat-select formControlName="comuna" placeholder="Comuna"
            (selectionChange)="changeSelect($event, 'comuna')" [disabled]="isComunaDisabled">
            <mat-option *ngFor="let comuna of nombresComunas" [value]="comuna">{{ comuna }}</mat-option>
          </mat-select>
          <mat-hint *ngIf="isComunaError" class="error-text">{{helperTextComuna}}</mat-hint>
        </mat-form-field>
      </div>
      <!-- Fin Comuna -->
      <!-- Direccion -->
      <div class="col-12 col-md-6 col-xl-6 p-1">
        <cas-input formControlName="direccion" label="{{ labelDireccion }}" id="direccion"
          (change)="changeSelect($event, 'direccion')" [placeholder]="labelDireccion"
          helperText="{{helperTextDireccion}}" isInputError="{{ isDireccionError }}"
          value="{{ this.formDatosPersonales.value.direccion }}" class="input-auto" fullWidth required></cas-input>
      </div>
      <!-- Fin Direccion -->

    </div>
    <!-- Fin Seccion primera-->
    <!-- Segunda seccion informacion previsional -->
    <div class="d-flex flex-wrap w-100">
      <cas-text variant="headline5">{{tiuloInfoPrevisional}}</cas-text>
    </div>
    <div class="d-flex flex-wrap align-center justify-content-center w-100">
      <!-- Prevision -->
      <div class="col-12 col-md-6 col-xl-6 p-1">
        <mat-form-field class="custom-select-material input-auto">
          <mat-select formControlName="prevision" placeholder="Previsión"
            (selectionChange)="changeSelect($event, 'prevision')" [ngClass]="{ 'error-border': isPrevisionError }">
            <mat-option *ngFor="let prevision of nombresIsapres" [value]="prevision">{{ prevision }}</mat-option>
          </mat-select>
          <mat-hint *ngIf="isPrevisionError" class="error-text">{{helperTextPrevision}}</mat-hint>
        </mat-form-field>
      </div>
      <!-- Fin Alerta isapre sin convenio -->
      <!-- Eres titular -->
      <div class="col-12 col-md-6 col-xl-6 p-1">
        <mat-form-field class="custom-select-material input-auto">
          <mat-select formControlName="titular" placeholder="¿Eres el titular?"
            (selectionChange)="changeSelect($event, 'titular')" [ngClass]="{ 'error-border': isTitularError }">
            <mat-option *ngFor="let titular of listaSelect" [value]="titular"
              >
              {{ titular }}
            </mat-option>
          </mat-select>
          <mat-hint *ngIf="isTitularError" class="error-text">Ingresa
            información del titular.</mat-hint>
        </mat-form-field>
      </div>
    </div>

   <!-- Inicio. Mensaje sin convenio -->
    <div *ngIf="noTieneConvenio" class="col-xl-12 col-lg-12 col-md-12 p-1">
      <div style="border: 1px solid #8d84e4; background:#eeecfb; border-radius:9px; padding: 16px">
        <div class="row">
          <div class="col-1" style="text-align: center;">
            <mat-icon class="material-icons-outlined ntt-icono-info">info</mat-icon>
          </div>
          <div class="col-11" style="text-align: left;">{{ this.mensajeTooltip }}</div>
        </div>
      </div>
    </div>
    <!-- Fin. Mensaje sin convenio -->


    <!-- Fin Seccion segunda-->
    <div class="col-xl-12 col-lg-12 col-md-12 p-1" *ngIf="formDatosPersonales.value.titular === 'No'">
      <cas-text variant="headline5">{{titularPrevision}}</cas-text>
    </div>
    <div class="d-flex flex-wrap align-center justify-content-center w-100" *ngIf="formDatosPersonales.value.titular === 'No'">
      <!-- Rut -->
      <div class="col-12 col-md-6 col-xl-6 p-1">
        <!-- Campo "Rut" -->
        <div style="width: 25%;float: left;">
          <div style="width: 100%;">
            <mat-form-field class="custom-select-material-rut" style="width: 100% !important;">
              <mat-select formControlName="rut">
                <mat-option [value]="'Rut'">Rut</mat-option>
                <mat-option [value]="'Pasaporte'">Pasaporte</mat-option>
              </mat-select>
            </mat-form-field>
          </div>
        </div>
        <!-- Campo "rutTitular" -->
        <div style="width: 70%; float: right;">
          <div style="width: 100%;">
            <cas-input label="Ingrese {{formDatosPersonales.value.rut}}" formControlName="rutTitular"
              (change)="changeSelect($event, 'rutTitular')" fullWidth helperText="Ingresa {{this.formDatosPersonales.value.rut}} del Titular"
              isInputError="{{ isRutTitularError }}" value="{{ this.formDatosPersonales.value.rutTitular }}"
              required></cas-input>
          </div>
        </div>
      </div>
      <div style="clear: both;"></div>
      <!-- Fin Rut -->

      <!-- Nombre del titular -->
      <div class="col-12 col-md-6 col-xl-6 p-1">
          <cas-input label="Nombre" (change)="changeSelect($event, 'nombreTitular')"
            helperText="Ingresa el nombre del titular" isInputError="{{ isNombreTitularError }}"
            value="{{ this.formDatosPersonales.value.nombreTitular }}" class="input-auto" fullWidth
            required></cas-input>
      </div>
      <!-- Fin Nombre del titular -->

       <!-- Apellido del titular -->
       <div class="col-12 col-md-6 col-xl-6 p-1">
          <cas-input label="Apellido"
          (change)="changeSelect($event, 'apellidoTitular')" helperText="Ingresa el apellido del titular"
          isInputError="{{ isApellidoTitularError }}" value="{{ this.formDatosPersonales.value.apellidoTitular }}"
          class="input-auto" fullWidth required></cas-input>
      </div>
      <!-- Fin Apellido del titular -->

        <!-- Parentesco -->
        <div class="col-12 col-md-6 col-xl-6 p-1">
          <mat-form-field class="custom-select-material input-auto">
            <mat-select formControlName="parentesco" placeholder="Parentesco"
              (selectionChange)="changeSelect($event, 'parentesco')" [compareWith]="compareFn"
              [ngClass]="{ 'error-border': isParentescoError }">
              <mat-option *ngFor="let parentesco of listaCotizantesAux" [value]="parentesco"
                >{{ parentesco.valor }}</mat-option>
            </mat-select>
            <mat-hint *ngIf="isParentescoError" class="error-text">
              Ingresa el parentesco con el titular.
            </mat-hint>
          </mat-form-field>
        </div>
        <!-- Fin Parentesco -->
      <!-- Fin No es titular -->
    </div>
    <!-- Fin Seccion segunda-->
    <!-- Tercera seccion seguro complementario -->

    <div class="d-flex flex-wrap align-center w-100">
      <div class="col-12 col-md-12 col-xl-12 p-1">
        <cas-text variant="headline5">{{tituloSeguro}}</cas-text>
      </div>
      <div class="col-12 col-md-6 col-xl-6 p-1">
        <mat-form-field class="custom-select-material input-auto">
          <mat-select placeholder="¿Tienes seguro complementario?" formControlName="seguroComplementario"
            (selectionChange)="onChangeTieneSeguro($event.value)">
            <mat-option [value]="true">Sí
            </mat-option>
            <mat-option [value]="false">No
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>
    </div>

    <div class="d-flex flex-wrap align-center w-100" *ngIf="tieneSeguro" formArrayName="seguros">
      <div *ngFor="let seguro of seguros.controls; let i = index;" class="py-3 d-flex flex-wrap col-12 col-md-6 col-xl-6 p-1" style="padding-left: 0px !important;">
        <div class="col-9 ps-0 pe-1">
          <input [formControlName]="i" (keyup)="changeSeguro($event, i)" class="form-control pseudo-cas-input"
            placeholder="¿Cuál es el nombre de tu seguro?" required>
          <div *ngIf="seguros.at(i).status=='INVALID'" class="error-text">Verifica el seguro
            ingresado.
          </div>
        </div>
        <div class="d-flex col-3 ps-0 pe-1 align-items-center">
          <cas-button variant="icon1" iconName="flat-plus" *ngIf="i == seguros?.length -1 && i<2"
            (click)="addSeguro(i); " [attr.disabled]="isSeguroError || seguros.at(i).status=='INVALID'"
            iconSize="12">
          </cas-button>
          &nbsp;
          <cas-button variant="icon1" iconName="flat-minus" *ngIf="i == seguros?.length -1" iconSize="12"
            (click)="removeSeguro(i)" [attr.disabled]="i == 0">
          </cas-button><!---->
        </div>
      </div>
      <!-- </form> -->
    </div>
      <!-- <pre>{{this.formDatosPersonales.value | json}}</pre> -->

    <div class="d-flex flex-wrap align-center w-100" style="padding-left: 0px !important;">
      <div class="col-9 ps-0 pe-1 mb-3 text-area-auto" *ngIf="tieneSeguro && seguros?.length==3">
        <cas-alert text="{{ this.msjMaxSeguros }}" variant="warning"></cas-alert>
      </div>
      <div class="section-container w-100 d-flex align-items-start text-area-auto" *ngIf="tieneSeguro">
        <!-- Alerta de seguros colectivos -->
        <div class="col-9 ps-0 pe-1" style="width: 100%;">
          <cas-alert text="{{ msjSegurosColectivos }}" variant="warning"></cas-alert>
        </div>
        <!-- Tooltip -->
        <!--<div class="" style="align-self: start;">
          <cas-tooltip placement="left" [content]="msjInfoSegurosColectivos">
            <app-icon-ds color="#FFB300" iconName="question-circle-fill" size="16"></app-icon-ds>
          </cas-tooltip>
        </div> -->

        <app-icon-ds
          color="#FFB300"
          iconName="question-circle-fill"
          size="16"
          [matTooltip]="msjInfoSegurosColectivos"
          matTooltipPosition="left"
          matTooltipClass="custom-tooltip"
          (click)="toggleTooltip()"
        ></app-icon-ds>
      </div>
    </div>
    <div style="display: none;">
      <button #myButton id="myButton">Click me!</button>
    </div>
  </form>
  <!-- Inicio. Botonera -->
  <div class="botonera d-flex flex-wrap gap-2 w-100 my-4">
    <cas-button text="Volver" variant="text1" (click)="volver()"></cas-button>
    <cas-button text="Continuar" variant="primary1" (click)="guardarYcontinuar()"></cas-button>
    
  </div>
  <!-- Fin. Botonera -->

  <div class="body-cas" *ngIf="isExito">
    <cas-modal width="500px" open="true">
      <div class="icon-container">
        <app-icon-ds class="iconButton" iconName="check-circle-fill" size="32" color="green"></app-icon-ds>
      </div>
      <cas-text variant="headline1" style="text-align: center;">Éxito</cas-text>
      <br>
      <cas-text variant="body1" style="text-align: center;">La carga de Documentos se ha realizado
        correctamente</cas-text>
      <div class="top-right-icon">
        <cas-icon name="flat-close" size="16" (click)="close('Exito')"></cas-icon>
      </div>
      <div class="bottom">
        <div class="separator"></div>
        <button class="custom-button" style="width: 90%;" (click)="close('Exito')">Continuar</button>
      </div>
    </cas-modal>
  </div>

</div>
<app-loader></app-loader>
