import { EventEmitter, Injectable } from '@angular/core';
import { AntecedenteHosp } from '../../model/antecedentesHosp.model';
import { CheckinModel } from 'src/app/model/checkinModel.model';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Cliente } from '../../model/cliente.model';
import { AutoAdmisionService } from '../auto-admision/auto-admision.service';
import { Subject } from 'rxjs';

interface InfoPaciente {
  paises: any[];
  comunas: any[];
  isapres: any[];
}

@Injectable({
  providedIn: 'root'
})
export class SharedAutoAdmisionService {

  private nvoAntecedenteHosp: AntecedenteHosp = new AntecedenteHosp();
  private nvoCheckinSelected: CheckinModel = new CheckinModel();

  public motivos: any[] = [];
  public allParams: any[] = [];
  public allSucursales: any[] = [];
  public sucursalesString: any[] = [];
  public sucHospInicial: any;
  public tieneCheckin: boolean = false;

  msjeDescargaDeclaracion: any;

  public dataEmitter: EventEmitter<any> = new EventEmitter();
  public stepperEmitter: EventEmitter<number> = new EventEmitter();
  estiloActivo: boolean = true;
  private headers: HttpHeaders;
  public listaInfoPaciente: InfoPaciente;
  public listaComunas: any[] = [];
  public listaPaises: any[] = [];
  public listaIsapres: any[] = [];
  public nombresComunas: string[] = [];
  public nombresPaises: string[] = [];
  public nombresIsapres: string[] = [];
  public idAdmision: string;

  public listaCotizantes: any[] = [];
  public listaSeguros: any[] = [];

  titular: string = '';
  ppnHash: string;
  private globalPaciente =  new Cliente();
  //private token: string ="eyJhbGciOiJSUzI1NiIsInR5cCI6IkpXVCIsImtpZCI6IlFqTkdSVUUyT1VWRU1UQTJSVGt3TmpBd09EWTRNelE0UVRoRU1FSkVOVGd5T1RaQ1JFRXdRZyJ9.eyJpc3MiOiJodHRwczovL2FsZW1hbmEtcG9jLmF1dGgwLmNvbS8iLCJzdWIiOiJhZHxpZHNjZXJ0fDE1Mzc2NzA0MSIsImF1ZCI6WyJodHRwczovL2FsZW1hbmEtcG9jLmF1dGgwLmNvbS9hcGkvdjIvIiwiaHR0cHM6Ly9hbGVtYW5hLXBvYy5hdXRoMC5jb20vdXNlcmluZm8iXSwiaWF0IjoxNzMwNzI1ODAwLCJleHAiOjE3MzA4MTIyMDAsInNjb3BlIjoib3BlbmlkIHByb2ZpbGUgZW1haWwgcmVhZDpjdXJyZW50X3VzZXIgdXBkYXRlOmN1cnJlbnRfdXNlcl9tZXRhZGF0YSBkZWxldGU6Y3VycmVudF91c2VyX21ldGFkYXRhIGNyZWF0ZTpjdXJyZW50X3VzZXJfbWV0YWRhdGEgY3JlYXRlOmN1cnJlbnRfdXNlcl9kZXZpY2VfY3JlZGVudGlhbHMgZGVsZXRlOmN1cnJlbnRfdXNlcl9kZXZpY2VfY3JlZGVudGlhbHMgdXBkYXRlOmN1cnJlbnRfdXNlcl9pZGVudGl0aWVzIG9mZmxpbmVfYWNjZXNzIiwiZ3R5IjpbInJlZnJlc2hfdG9rZW4iLCJwYXNzd29yZCJdLCJhenAiOiJ4cExCZGQ2VTA3UWNwenE1TTd0bHFvWDJ1Qm9jeElJcyJ9.yEAni9DgUyN82a1LkHKZ0xeDbhWnzqjyXqXY-McOp8f3_JE79Pz59tMqn-8EzlmV8WoVTSoUEkUniDASl-YoGf1V6BFGLCchFTa0N6Nv_ooMz9MRP_6BTaI38ExyKKNV3y-yzbN6Wxyd8YUmW7WDofBMIc0I9SCmm9oBguigWPRwag3mZ6SH_-NFWbDG7gwNFoOcmNdGnaAPFU0k-7lZrahHt3lboye1b7SRyYBo7sslpxS-MWUlJiju8xBBA7d1CXcEASwj__q-As4wr6zylU1hxT26NoB-rlMGgYuI1JJbzpvX9fHdZUOmK_qdfj5nO9eHng9eBGhbigqapZzweQ";

  private finishSubject = new Subject<void>();
  finishLoaded$ = this.finishSubject.asObservable();

  constructor(
    private http: HttpClient,
    private autoAdmisionService: AutoAdmisionService) {
    this.headers = new HttpHeaders();
    this.headers = this.headers.append('Content-Type', 'application/json');
    this.init();
  }

  private async init(): Promise<void> {
    await this.waitForHash();
    this.cargarInformacion();
}

  emitData(data: any) {
    //console.log("data", data);
    this.dataEmitter.emit(data);
  }

  private waitForHash(): Promise<void> {
    return new Promise((resolve) => {
        const interval = setInterval(() => {
            const tokenGlobal = (window as any).tokenGlobal;
            if (tokenGlobal) {
                clearInterval(interval);
                resolve();
            }
        }, 100);
    });
}

  cargarInformacion() {
    console.log('entré a cargar información');
   this.autoAdmisionService.obtenerInfoPaciente().subscribe({
        next: (data: InfoPaciente) => {
          console.log("data opacietes", data);
            this.listaInfoPaciente = data;

            this.listaComunas = Array.isArray(data.comunas) ? data.comunas.filter((e) => { return (e.comuNombre.toLowerCase() !== 'sin información' && e.comuNombre.toLowerCase() !== 'otra') }) : [];
            this.listaPaises = Array.isArray(data.paises) ? data.paises.filter((e) => { return (e.paisNombre.toLowerCase() !== 'sin información' && e.paisNombre.toLowerCase() !== 'otra')}) : [];
            this.listaIsapres = Array.isArray(data.isapres) ? data.isapres : [];

            /* Agregar "Sin Información" al principio de las listas */
            this.listaComunas.unshift({ comuNombre: 'Sin Información' });
            this.listaPaises.unshift({ paisNombre: 'Sin Información' });

            this.nombresComunas = this.listaComunas.map(comuna => comuna.comuNombre);
            this.nombresPaises = this.listaPaises.map(pais => pais.paisNombre);
            this.nombresIsapres = this.listaIsapres.map(isapre => isapre.glosa);

            this.finishLoaded$
        },
        error: (error) => {
            console.error('Error loading InfoPaciente:', error);
        }
    });

    //console.log(this.listaComunas, this.listaPaises, this.listaIsapres);
}


  buscarObjetoPorNombre(nombre: string, atributo: string, arreglo: any[]): any{

      const objetoEncontrado = arreglo?.find(objeto => objeto[atributo] === nombre);
      return objetoEncontrado ? objetoEncontrado : null;


}

  /* Método para obtener el emisor de datos */
  getDataEmitter() {
    return this.dataEmitter;
  }

  getStepperEmitter(){
    return this.stepperEmitter;

  }
  setStepper(paso: number){
    this.stepperEmitter.emit(paso)
  }

  toggleEstilo(condition) {
    this.estiloActivo = condition;
  }

  get antecedenteHosp(): AntecedenteHosp {
    return this.nvoAntecedenteHosp;
  }

  set antecedenteHosp(valor: AntecedenteHosp) {
    this.nvoAntecedenteHosp = valor;
  }

  get checkinSelected(): CheckinModel {
    return this.nvoCheckinSelected;
  }

  set checkinSelected(value: CheckinModel) {
    this.nvoCheckinSelected = value;
  }
  setIdAdmision(id: string): void {
    this.idAdmision = id;
  }

  getIdAdmision(): string {
    return this.idAdmision;
  }

  get pacienteSelected(): Cliente {
    return this.globalPaciente;
  }

  set pacienteSelected(valor: Cliente) {
    this.globalPaciente = valor;
  }

  obtenerParametroPorCat(categoria: string, llaveOvalor: string, stringBuscado: string): any{
    return this.allParams.find(elemento => elemento.categoria === categoria && elemento[llaveOvalor] === stringBuscado);
  }



}
