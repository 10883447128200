import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { environment } from "src/environments/environment";

@Injectable({
    providedIn: 'root'
})
export class ApiDocService {
    private headers: HttpHeaders;
    constructor(private http: HttpClient) {
        this.headers = new HttpHeaders();
        this.headers = this.headers.append('Content-Type', 'application/json');
        this.headers = this.headers.append('Access-Control-Allow-Origin', '*');
        this.headers = this.headers.append('Access-Control-Allow-Methods', 'OPTIONS,POST,GET');
        this.headers = this.headers.append("Access-Control-Allow-Headers", "Content-Type, Authorization, X-Requested-With");
     }

    apiDoc(params: any): Observable<any> {
        return this.http.post(environment.urlMsAutoadhos + '/api-doc', params);
    }

    signApi(body: any): Observable<any> {
        return this.http.post(environment.urlMsApiFirma + '/integracion/sing-apis', body,{
            headers: this.headers,
        });
    }

    signApiObtenerDoc(documentId: string): Observable<any> {
        return this.http.get(environment.urlMsApiFirma + `/integracion/sing-apis/${documentId}/documentId`, {
            headers: this.headers,
        });
    }

    obtenerDataCheckIn(admision: string): Observable<any> {
        return this.http.get(environment.urlMsSisnadhos + `/imagen-checkin/${admision}/imagenCheckin`, {
            headers: this.headers,
        });
    }

    cancelarEnvioSignApi(body: any): Observable<any> {
        return this.http.post(environment.urlMsApiFirma + '/integracion/sing-apis/cancelarDocumento', body,{
            headers: this.headers,
        });
    }

    actualizarEdoFirma(body: any): Observable<any> {
        return this.http.post(environment.urlMsSisnadhos + '/integracion/sing-apis/guardarFirmaSignApi', body,{
            headers: this.headers,
        });
    }

    declaracionAccidenteApi(admision: string, isapre: string): Observable<any> {
        return this.http.get(environment.urlMsSisnadhos + `/formulario/admisionId/${admision}/isapre/${isapre}`, {
            headers: this.headers,
        });
    }

    declaracionAccAutoAdm(admision: string, isapre: string): Observable<any> {
        return this.http.get(environment.urlMsAutoadhos + `/admision/autoAdm/${admision}/isapre/${isapre}`, {
            headers: this.headers,
        });
    }


    convenioAccidenteApi(admision: string): Observable<any> {
        return this.http.get(environment.urlMsSisnadhos + `/formulario/admisionId/${admision}`, {
            headers: this.headers,
        });
    }

    generacionLinkWsp(body: any): Observable<any> {
        return this.http.post(environment.urlApiWhatsapp + '/twilio/generacionLinkWsp', body,{
            headers: this.headers,
        });
    }

    generacionQrWsp(body: any): Observable<any> {
        return this.http.post(environment.urlApiWhatsapp + '/twilio/generacionQrWsp', body,{
            headers: this.headers,
        });
    }



}
