<div class="row" *ngIf="paso === 2 || paso === 1 || paso === 3" style="height: 122px;">
    <div class="col-lg-6">
        <div class="progressbar">
            <div class="progressbar-container">
                <ul class="progressbar-list">
                    <li [class.active]="paso === 1">Mis datos</li>
                    <li [class.active]="paso === 2">Hospitalización</li>
                    <li [class.active]="paso === 3">Acompañante</li>
                </ul>
            </div>
        </div>
    </div>
</div>