import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

import * as base64js from 'base64-js';
import { catchError, switchMap } from 'rxjs/operators';
import { Observable, throwError } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class SharepointService {

  private headers: HttpHeaders;

  constructor(private http: HttpClient) {
    this.headers = new HttpHeaders();
    this.headers = this.headers.append('Content-Type', 'application/json');
  }

  crearFolder(params: any) {
    return this.http.post(environment.urlMsSisnadhos + '/sharepoint-cola/crearFolder', params, {
      headers: this.headers
    });
  }

  updateMetadatos(params: any, ppn) {
    return this.http.post(environment.urlMsSisnadhos + '/sharepoint-graph/actualizarMetadata/' + ppn + '/ppn', params, {
      headers: this.headers
    });
  }

  cargarFileCola(params: any) {
    return this.http.post(environment.urlMsSisnadhos + '/sharepoint-cola/crearDocumento', params, {
      headers: this.headers
    });
  }

  obtenerEstadoFileCola(uid: any) {
    return this.http.get(environment.urlMsSisnadhos + '/sharepoint-cola/' + uid + '/obtener-estado', {
      headers: this.headers
    });
  }

  obtenerDocsManuales(ppn: any) {
    return this.http.get(environment.urlMsSisnadhos + '/sharepoint-cola/' + ppn + '/obtener-documentos', {
      headers: this.headers
    });
  }


  cargarDocSharepoint(urlPdf: string, params: any): Observable<any> {
    return this.http.get(urlPdf, { responseType: 'arraybuffer' }).pipe(
      switchMap((response: ArrayBuffer) => {
        const arrayBuffer = new Uint8Array(response);
        const base64String = base64js.fromByteArray(arrayBuffer);
  
        params.base64 = base64String;
  
        return this.http.post(environment.urlMsSisnadhos + '/sharepoint-cola/crearDocumento', params, {
          headers: this.headers
        });
      }),
      catchError((error: any) => {
        console.log(error);
        return throwError(error);
      })
    );
  }

  obtenerEstadoFirma(docId: any) {
    return this.http.get(environment.urlMsSisnadhos + '/integracion/sing-apis/' + docId + '/obtener-edoFirma', {
      headers: this.headers
    });
  }

  obtenerUrlDescarga(sharepointIdDocumento: any) {
    const headers = new HttpHeaders({'Accept': 'text/plain'});
    return this.http.get(environment.urlMsSisnadhos + '/sharepoint-cola/' + sharepointIdDocumento + '/idDocumento', { headers: headers, responseType: 'text' });
  }

}
  

