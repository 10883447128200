import { Injectable } from '@angular/core'
import { Cliente } from 'src/app/model/cliente.model';

@Injectable()
export class ClienteUtil{
    constructor(){
    }

    construirRespuestaObtenerClientePorDocumento(rsCliente: any, rsContacto: any, rsDirecciones: any){

		let paciente = new Cliente();
		paciente = {
			rut: rsCliente.identification_document,
			ppn: rsCliente.ppn,
			nombres: rsCliente.names,
			primer_apellido: rsCliente.last_name,
			segundo_apellido: rsCliente.mothers_last_name,
			nombre_social: rsCliente.name_social,
			sexo: this.formateoSexo(rsCliente.gender),
			fecha_nacimiento: rsCliente.birth_date,
			edad: this.calcularEdad(rsCliente.birth_date),
			nacionalidad: rsCliente.nationality.demonym,
			pais_residencia: rsCliente.nationality.name,
			contacto: undefined,
			verification_document : rsCliente.verification_document,
			isRut: rsCliente.identification_type === 1,
			indicadores: undefined,
			internacional: false,
			prevision: rsCliente.health_care,
			company_id: rsCliente.company_id,
			virtual_master_id: rsCliente.virtual_master_id
			};

        var response = {
            code: '01',
            cliente: paciente,
        };
		

		let contacto ={
			telefonoCelular: '',
			telefonoFijo: '',
			email: '',
			pais: {id: 0,
				nombre: ''  },
			ciudad: {id:0,nombre:''},
			comuna: {
				id: 0,
				nombre: ''  },
			region: {
				id: 0,
				nombre: '' 
			},
			direccion: ''
		}
			
			 contacto = {
				telefonoCelular: this.obtenerDatosContacto(rsContacto, 'celular').value,
				telefonoFijo: this.obtenerDatosContacto(rsContacto, 'fijo').value,
				email: this.obtenerDatosContacto(rsContacto, 'email').value,
				pais: this.obtenerDatosDireccion(rsDirecciones, 'country'),
				ciudad: this.obtenerDatosDireccion(rsDirecciones, 'city'),
				comuna: this.obtenerDatosDireccion(rsDirecciones, 'commune'),
				region: this.obtenerDatosDireccion(rsDirecciones, 'region'),
				direccion: this.obtenerDatosDireccion(rsDirecciones, 'address')
			};
		
       

        response.cliente.contacto = contacto;

       return response;
    }

    obtenerDatosContacto(contactos: any, tipo: string) : any {
		if (null === contactos || contactos == 404 || contactos == '') {
			return '';
		}
		var contacto = '';
		contactos.contacts.forEach(function (valor) {
			if ('celular' == tipo && 4 === valor.type.id && 1 === valor.classification.id) {//clasificacion = 1
				contacto = valor;
				return;
			} else if ('email' == tipo && 3 === valor.type.id && 1 === valor.classification.id) { //clasificacion = 1
				contacto = valor;
				return;
			} else if ('fijo' == tipo && 1 === valor.type.id && 1 === valor.classification.id) { //clasificacion = 1
				contacto = valor;
				return;
			}

		});
		return contacto;
	}
	
	obtenerDatosDireccion(direcciones: any, tipo: string) : any {
		if ('address' === tipo && null === direcciones) { return ''} 
		else if (null === direcciones) {
			return {
				id: 0,
				nombre: ''
			}
		}
		
		
		var direccion: any = '';
		direcciones.addresses.forEach(function (valor) {
			if ( 'address' === tipo) {
				direccion = (valor.address) ? valor.address : ''
				return;
			}else if ( 'commune' === tipo) {
				if(valor.commune){
					direccion = {
						id: (valor.commune.id) ? valor.commune.id : -1,
						nombre: (valor.commune.name) ? valor.commune.name : ''
					}
					return;
				}else{
					direccion = {
						id: -1,
						nombre: ''
					}
					return;
				}
				
			}else if ( 'city' === tipo) {
				direccion = {
					id: -1,
					nombre: (valor.city) ? valor.city : ''
				}
				return;
			}else if ( 'country' === tipo) {
				if(valor.country){
				direccion = {
					id: valor.country.id,
					nombre: valor.country.name
				}
			
				return;
			}else{
				direccion = {
					id: -1,
					nombre: ''
				}
				return;
			}
			}else if ( 'region' === tipo) {
				if(valor.region){
				direccion = {
					id: (valor.region.id || valor.region.id == 0) ? valor.region.id : -1,
					nombre: (valor.region.name) ? valor.region.name : ''
				}
				return;
			}else{
				direccion = {
					id: -1,
					nombre: ''
				}
				return;
			}
			}
		});
		return direccion;
	}
	
    calcularEdad(dateString) {		
		var today = new Date();
		var birthDate = new Date(dateString);
		var age = today.getFullYear() - birthDate.getFullYear();
		var m = today.getMonth() - birthDate.getMonth();
		if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
			age--;
		}
		return age;
	}

	formateoSexo(params) {
		return params == 'F' ? 'Femenino' : 'Masculino'
	}

	construirRespuestaCotizante(rsCliente: any, indicadores: any) {
		let cotizante = new Cliente();
		cotizante = {
			rut: rsCliente.identification_document,
			ppn: rsCliente.ppn,
			nombres: rsCliente.names,
			primer_apellido: rsCliente.last_name,
			segundo_apellido: rsCliente.mothers_last_name,
			nombre_social: rsCliente.name_social,
			sexo: this.formateoSexo(rsCliente.gender),
			fecha_nacimiento: rsCliente.birth_date,
			edad: this.calcularEdad(rsCliente.birth_date),
			nacionalidad: rsCliente.nationality.demonym,
			pais_residencia: rsCliente.nationality.name,
			contacto: undefined,
			verification_document : rsCliente.verification_document,
			isRut: rsCliente.identification_type === 1,
			indicadores: "",
			internacional: false,
			prevision: rsCliente.health_care,
			company_id: rsCliente.company_id,
			virtual_master_id: rsCliente.virtual_master_id
		};

		return cotizante;
	}

}
