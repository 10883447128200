import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ProfesionalesService {

  private headers: HttpHeaders;

  constructor(private http: HttpClient) {
    this.headers = new HttpHeaders();
    this.headers = this.headers.append('Content-Type', 'application/json');
  }

  profesionalesServices(params:any): Observable<any>{
      return this.http.get(environment.urlMsSisnadhos +"/integracion/profesional?fullText=" + params, {
        headers: this.headers
      });
    }

  obtenerProfesionalPorPpn(ppn:number): Observable<any>{
      return this.http.get("https://sisnadhos-back-cache.prod.caas.alemana.cl/v1/profesional/obtenerProfesionalesPpn/ppn=" + ppn, {
        headers: this.headers
      });
    }

  }
