import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class ValidacionesService {
  private headers: HttpHeaders;

  constructor(private http: HttpClient) {
    this.headers = new HttpHeaders();
    this.headers = this.headers.append('Content-Type', 'application/json');
  }

  validarDatosIngreso(datos, pabellon) {
    console.log("datos ",datos,"pabellon ", pabellon);
    const errors = {
      msjMotivo: '',
      msjTrauma: '',
      msjMedicoTratante: '',
      msjFechaIngreso: '',
      msjSucursalHos: '',
      msjProdCirujia: '',
      msjHoraPabellon: '',
      msjDiagnostico: '',
      msjFechaPabellon: '',
      mensajeGeneral: ''
    };

    if (!datos.motivoDescrip) {
      errors.msjMotivo = "Motivo";
    }

    if (datos.motivoDescrip === 'Cirugía' || datos.motivoDescrip === 'Parto') {
      if (!datos.medicoTratanteNombre || !datos.medicoTratantePpn) {
        errors.msjMedicoTratante = "Médico Tratante";
      }
      if (datos.cirugiaTraumatologica == null  && datos.motivoDescrip === 'Cirugía') {
        errors.msjTrauma = "Cirugía traumatológica";
      }
      else{
        errors.msjTrauma = "";
      }

      if (pabellon) {
        errors.msjSucursalHos = !datos.sucursalHospitalizacion ? "Sucursal de Hospitalización" : '';
        errors.msjProdCirujia = !datos.procedimientoCirugia ? "Procedimiento" : '';
        errors.msjHoraPabellon = !datos.horaPabellon ? "Hora de Pabellón" : '';
        errors.msjDiagnostico = !datos.diagnostico ? "Diagnóstico" : '';
        errors.msjFechaPabellon = !datos.fechaPabellon ? "Fecha de Pabellón" : '';
      } else {
        errors.msjFechaIngreso = !datos.fechaIngreso ? "Fecha de Ingreso" : '';
      }
    } else {
      errors.msjMedicoTratante = !datos.medicoTratanteNombre ? "Médico Tratante" : '';
      errors.msjFechaIngreso = !datos.fechaIngreso ? "Fecha de Ingreso" : '';
    }

    if (errors.msjMotivo || errors.msjMedicoTratante || errors.msjSucursalHos || errors.msjProdCirujia || errors.msjHoraPabellon || errors.msjDiagnostico || errors.msjFechaPabellon || errors.msjFechaIngreso) {
      errors.mensajeGeneral = "Faltan los siguientes datos obligatorios:\n" +
        `${errors.msjMotivo}\n` +
        `${errors.msjMedicoTratante}\n` +
        `${errors.msjSucursalHos}\n` +
        `${errors.msjProdCirujia}\n` +
        `${errors.msjHoraPabellon}\n` +
        `${errors.msjDiagnostico}\n` +
        `${errors.msjFechaPabellon}\n` +
        `${errors.msjTrauma}\n` +
        `${errors.msjFechaIngreso}`;
    } else {
      errors.mensajeGeneral = "Datos válidos";
    }
    console.log("... error:", errors);
    return errors;
  }
}