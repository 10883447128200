import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent, HttpErrorResponse } from '@angular/common/http';
import { Observable, throwError, of } from 'rxjs';
import { catchError, retryWhen, delay, take, concatMap } from 'rxjs/operators';
 
@Injectable()
export class CorsInterceptor implements HttpInterceptor {
  
  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    // Excluir el servicio de médicos
    if (req.url.includes('profesional?fullText')) {
      return next.handle(req);
    }
    
    return next.handle(req).pipe(
      retryWhen(errors =>
        errors.pipe(
          concatMap((error) => {
            console.log("entre interceptor,"+ error);
            if (error instanceof HttpErrorResponse && error.status === 0) {
              console.error('CORS error, retrying...', error);
              // Retrasar el reintento (ejemplo: 1 segundo)
              return of(null).pipe(delay(500));
           }
            return throwError(error);
          }),
          take(3) // Número máximo de reintentos
        )
      ),
      catchError((error: HttpErrorResponse) => {
        // Manejo de otros errores
        return throwError(error);
      })
    );
  }
}