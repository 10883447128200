<!--Para primer tipo de search (icono a la izquierda)-->
<div *ngIf="left" class="mb-3">
    
    <div style="display:flex">
        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-search " [ngClass]="{'custom-search-one-s1': size === 's1', 'custom-search-one-s2': size === 's2','custom-search-one-s3': size === 's3'}" viewBox="0 0 16 16">
            <path d="M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001c.03.04.062.078.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1.007 1.007 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0z"/>
        </svg>

        <input type="text" class="form-control "[ngClass]="{'custom-form-control-one-s1': size === 's1', 'custom-form-control-one-s2': size === 's2','custom-form-control-one-s3': size === 's3'}" id="myForm" placeholder={{placeholder}}>
    </div>
</div>

<!--Para segundo tipo de search (icono a la derecha)-->
<div *ngIf="right" class="mb-3">
    
    <div style="display:flex">
        
        <input type="text" class="form-control " [ngClass]="{'custom-form-control-two-s1': size === 's1', 'custom-form-control-two-s2': size === 's2','custom-form-control-two-s3': size === 's3'}" id="myForm" placeholder={{placeholder}}>

        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-search " [ngClass]="{'custom-search-two-s1': size === 's1', 'custom-search-two-s2': size === 's2','custom-search-two-s3': size === 's3'}" viewBox="0 0 16 16">
            <path d="M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001c.03.04.062.078.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1.007 1.007 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0z"/>
        </svg>
    </div>
</div>

<!--Para tercer tipo de search (icono a ambos lados)-->
<div *ngIf="both" class="mb-3">
    
    <div style="display:flex">
        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-search " [ngClass]="{'custom-search-three-left-s1': size === 's1', 'custom-search-three-left-s2': size === 's2','custom-search-three-left-s3': size === 's3'}" viewBox="0 0 16 16">
            <path d="M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001c.03.04.062.078.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1.007 1.007 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0z"/>
        </svg>
        
        <input type="text" class="form-control " [ngClass]="{'custom-form-control-three-s1': size === 's1', 'custom-form-control-three-s2': size === 's2','custom-form-control-three-s3': size === 's3'}" id="myForm" placeholder={{placeholder}}>
        
        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-search " [ngClass]="{'custom-search-three-right-s1': size === 's1', 'custom-search-three-right-s2': size === 's2','custom-search-three-right-s3': size === 's3'}" viewBox="0 0 16 16">
            <path d="M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001c.03.04.062.078.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1.007 1.007 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0z"/>
        </svg>
    </div>
</div>

<!--Para cuarto tipo de search (icono y simbolo arroba)-->
<div *ngIf="special"class="mb-3">
    
    <div style="display:flex">
        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-search custom-search-fourth-left" viewBox="0 0 16 16">
            <path d="M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001c.03.04.062.078.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1.007 1.007 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0z"/>
        </svg>
        
        <input type="text" class="form-control custom-form-contro-fourth" id="myForm" placeholder={{placeholder}}>

        <div class="card custom-card" >
            <div class="card-body ">
              <h5 class="card-title custom-card-title">@</h5>
        </div>
        

    </div>
</div>
