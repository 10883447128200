import { Component, EventEmitter,Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-spinners-ds',
  templateUrl: './spinners-ds.component.html',
  styleUrls: ['./spinners-ds.component.scss']
})
export class SpinnersDsComponent implements OnInit {

  @Input () color: string = "";
  @Input () size: string = "";
  @Input () msg: string = "";

  s1: boolean = true;
  s2: boolean = true;

  constructor() { }

  ngOnInit(): void {

    if (this.size === 's1'){//spinner pequeño
      this.s2 = false;
    }

    if (this.size === 's2'){//spinner grande
      this.s1 = false;
    }

  }

}
