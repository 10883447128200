export const environment = {
  production: false,
  urlSistem: 'https://api-admision.dev.caas.alemana.cl/cliente',
  urlMsSisnadhos: "https://sisnadhos-back-node.dev.caas.alemana.cl/v1",
  urlMsAutoadhos: "https://autoadho.dev.alemanago.cl/v1",
  // urlMsSisnadhos: "http://localhost/v1",
  urlIndicadoresBase: "https://www.alemana.cl/app_corporativas/indicadoresClientes/",
  urlClientes: 'http://certadmin.alemana.cl/admin/clientesNaturalesV3/InterfazClientesWeb', //cambia segun ambiente
  urlFuncionRetorno: 'https://api-admision.dev.caas.alemana.cl/cliente/xml',
  urlCuentaFase2: 'http://desaf2.alemana.cl/sisalud2/ctacte/CTAConsCuentaContexto.do?ctaNumCuenta=',

  urlMsApiFirma: "https://sisnadhos-api-firma.dev.caas.alemana.cl/v1",
  urlMsApiLog: "https://api-log-auditoria.dev.caas.alemana.cl/log-auditoria",
  urlMsApiBrazalete : "https://sisnadhos-api-brazalete.dev.caas.alemana.cl/v1/api-brazalete",
  urlApiWhatsapp: 'https://sisnadhos-api-whatsapp.dev.caas.alemana.cl/v1',

  urlPais: "/integracion/parametroGeneralClinica/paises",
  urlSucursales: "/integracion/parametroGeneralClinica/sucursales",
  urlComunas: "/integracion/parametroGeneralClinica/comunas",
  urlIsapres: "/integracion/parametroGeneralClinica/isapres",
  urlAcompanante: "/acompanante",
  urlRelaciones: "/prm-admision/acompanante?holdingId=0&sucursalId=0",
  urlImagenCorporativa: "https://d51h1y0hva8v.cloudfront.net/docs/librariesprovider6/corporativo/material-de-apoyo/cas_horizontal_verde.png?sfvrsn=7f6454a3_2",

  googleTagManagerId: "",


  azureADConfig: {
    clientId: '193f0058-4304-4027-ab3d-9c798094af63',
    authority: 'https://login.microsoftonline.com/9298dcf5-8f4f-4ba9-a331-6f84f599a983',
    urlApp: 'https://sisnadhos-front-angular.dev.caas.alemana.cl/',
    // urlApp: 'http://localhost:4200/',
    authUrl: {
      urlAppDerivador: 'https://sisnadhos-back-node.dev.caas.alemana.cl/v1/integracion/derivador/',
      urlAppWidget: 'https://sisnadhos-back-node.dev.caas.alemana.cl/v1/integracion/widget/',
      scopeAppDerivador: 'api://d92765a1-2904-4a81-9c73-3446826bb3cd/.default',
      scopeAppWidget: 'api://b30fd147-57ec-4c53-abfc-053e4747d300/.default',
      urlRoles: 'https://sisnadhos-back-node.dev.caas.alemana.cl/v1/integracion/roles-perfiles',
      scopeRoles: 'api://4913a648-3c00-47e8-aca5-2afeb5e2edbe/.default',
      urlApiDoc: 'https://sisnadhos-back-node.dev.caas.alemana.cl/v1/api-doc',
      scopeApiDoc: 'api://e5ee132a-9b30-4e61-a935-4fecf53f9846/.default'
    }
  },
  urlBotonera: "http://sondaf2-11g.alemana.cl/sisalud2/admision/ADM_HospitalizacionV3.do?idAdm=",
  urlBotoneraRespaldo: "http://sondaf2-11g.alemana.cl/sisalud2/admision/ADM_RespaldoPreAdmV3.do?xNavCode=&cliId=",
  urlBotoneraEtiqueta: "http://sondaf2-11g.alemana.cl/sisalud2/admision/ADM_ConsNroCarpetaAnexa_PreV3.do?xNavCodeRetorno=menu&xTraCode=9&estId=&cliId=",

  urlApiDocsCasDirecta: "https://cas-ms-api-docs.dev.caas.alemana.cl",
  urlApiDocsCasTyk: "https://gtw-dev.alemana.io/public/cas-ms-api-docs-azure"
};
