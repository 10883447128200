import { Component, Inject, EventEmitter, Output } from '@angular/core';
import { Router } from '@angular/router';
import { MsalService, MsalBroadcastService, MSAL_GUARD_CONFIG, MsalGuardConfiguration } from '@azure/msal-angular';
import { InteractionStatus, PopupRequest } from '@azure/msal-browser';
import { Subject } from 'rxjs';
import { filter, takeUntil } from 'rxjs/operators';
import { AdmisionService } from './servicios/admision/admision.services';
import { SharedAutoAdmisionService } from './servicios/autoAdmision/shared-auto-admision.service';
import { environment } from '@environments/environment';
import * as pkg from '../../package.json';


@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  title = 'sisnadhos-front-angular';
  isIframe = false;
  loginDisplay = false;
  private readonly _destroying$ = new Subject<void>();
  labelHolding = '';
  holdingId = '';
  routeIsMenu = false;
  urlLogo = environment.urlImagenCorporativa;  


  @Output () onClickButton: EventEmitter<any> = new EventEmitter();

  constructor(
    @Inject(MSAL_GUARD_CONFIG) private msalGuardConfig: MsalGuardConfiguration, 
    private broadcastService: MsalBroadcastService, 
    private authService: MsalService,
    private router: Router,
    private admisionService: AdmisionService,
    public sharedAutoAdmisionService: SharedAutoAdmisionService
    ) { }

  ngOnInit() {
    console.log("version:", pkg.version);
    this.isIframe = window !== window.parent && !window.opener;
    this.router.initialNavigation();
    // this.broadcastService.inProgress$
    // .pipe(
    //   filter((status: InteractionStatus) => status === InteractionStatus.None),
    //   takeUntil(this._destroying$)
    // )
    // .subscribe(() => {
    //   //this.setLoginDisplay();
    // })
  }

  clickBoton(event: Event) {
    this.onClickButton.emit(event);
  }

  login() {
    if (this.msalGuardConfig.authRequest){
      this.authService.loginPopup({...this.msalGuardConfig.authRequest} as PopupRequest)
        .subscribe({
          next: (result) => {
            this.authService.instance.setActiveAccount(result.account);
            this.setLoginDisplay();
            this.router.navigate(['/multiHolding',{}]);
          },
          error: (error) => console.log(error)
        });
    } else {
      this.authService.loginPopup()
        .subscribe({
          next: (result) => {
            this.authService.instance.setActiveAccount(result.account);
            this.setLoginDisplay();
          },
          error: (error) => console.log(error)
        });
    }
  }

  logout() { // Add log out function here

    this.admisionService.eliminarPermiso(sessionStorage.getItem('Usuario')).subscribe(
      (response: any) => {
        
      },
      () => { }
    );

    sessionStorage.removeItem('idAdmision');
    sessionStorage.removeItem('numeroDocumento');
    sessionStorage.removeItem('documentoAux');
    sessionStorage.removeItem('ppn');
    sessionStorage.removeItem('idDoc');
    sessionStorage.removeItem('documentoAux');
    sessionStorage.removeItem('numeroDocumento');
    sessionStorage.removeItem('tipoDocAux');
    sessionStorage.removeItem('Holding');
    sessionStorage.removeItem('currentUser');
    sessionStorage.removeItem('paso2-correos');
    sessionStorage.removeItem('PERMISOS');
    this.authService.logoutPopup({
      mainWindowRedirectUri: "/"
    });



  }

  setLoginDisplay() {
    this.loginDisplay = this.authService.instance.getAllAccounts().length > 0;
  }

  ngOnDestroy(): void {
    this._destroying$.next();
    this._destroying$.complete();
  }

  cerrarSesion(){
    
    this.admisionService.eliminarPermiso(sessionStorage.getItem('Usuario')).subscribe(
      (response: any) => {
        
      },
      () => { }
    );

    sessionStorage.removeItem('idAdmision');
    sessionStorage.removeItem('numeroDocumento');
    sessionStorage.removeItem('documentoAux');
    sessionStorage.removeItem('ppn');
    sessionStorage.removeItem('idDoc');
    sessionStorage.removeItem('documentoAux');
    sessionStorage.removeItem('numeroDocumento');
    sessionStorage.removeItem('tipoDocAux');
    sessionStorage.removeItem('Holding');
    sessionStorage.removeItem('currentUser');
    sessionStorage.removeItem('paso2-correos');
    this.authService.logout({
    });

  }

  validarParametrosSession(){
    const nombreUsuario =  sessionStorage.getItem('userNombreCompleto');
    const holding = sessionStorage.getItem('labelHolding');
    const idHolding = sessionStorage.getItem('Empresa');
    return nombreUsuario && holding && idHolding;
  }
}
