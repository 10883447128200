import {ChangeDetectorRef, Component, ElementRef,  HostListener,  NgZone,  OnInit, Renderer2, ViewChild, AfterViewChecked, ChangeDetectionStrategy } from '@angular/core';
import { AbstractControl, FormArray, FormBuilder, FormControl, FormGroup, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { Cliente } from '../../../model/cliente.model';
import { SharedAutoAdmisionService } from '../../../servicios/autoAdmision/shared-auto-admision.service';
import { ClienteService } from '../../../servicios/cliente/cliente.service';
import { BehaviorSubject, Observable, forkJoin, from, of, throwError } from 'rxjs';
import { ContactInfo } from '../../../model/contacto.model';
import { MatSnackBar } from '@angular/material/snack-bar';
import { CONST_AUTOADM } from '../../../../app/const';
import { catchError, delay, retryWhen, switchMap, take, tap } from 'rxjs/operators';
import { AdmisionService } from '../../../servicios/admision/admision.services';
import { LoaderService } from '../../../servicios/loader/loader.service';
import { CuentaCorrienteService } from '../../../servicios/cuentaCorriente/cuenta-corriente.service';
import { SharepointService } from '../../../servicios/sharepoint/sharepoint.service';
import { AutoAdmisionService } from '../../../servicios/auto-admision/auto-admision.service';
import { Comuna } from '../../../model/comuna.model';
import { MatTooltip } from '@angular/material/tooltip';

@Component({
  selector: 'app-paso1-auto-admision',
  templateUrl: './paso1-auto-admision.component.html',
  styleUrls: ['./paso1-auto-admision.component.css'],
})
export class Paso1AutoAdmisionComponent implements OnInit, AfterViewChecked {
  @ViewChild(MatTooltip) tooltip!: MatTooltip;
  idAdmisionActual: number = 0;

  loading: boolean = true;

  labelCorreo: string = 'Email';
  labelPais: string = 'País';
  labelCelular: string = 'Celular';
  labelCiudad: string = 'Ciudad';
  labelComuna: string = 'Comuna';
  labelPrevision: string = 'Previsión';
  labelDireccion: string = 'Calle y numeración';

  formDatosPersonales!: FormGroup;
  form_seguros: FormGroup;
  segurosAux: any[] = [];

  token: string = 'ok';

  objectPaciente: any = null;
  infoContacto: any = null;
  infoDireccion: any = null;
  objectDireccion: any = null;
  objectContacto: any = null;
  infoPrevision: any = null;
  objectPrevision: any = null;
  previsionToSave: any = null;
  infoLoaded: boolean = false;
  infoCotizante: any = null;
  infoSeguros: any = null;

  tengoCheckin: boolean = false;
  listaComunas = this.sharedService.listaComunas;
  listaPaises = [];
  listaIsapres = this.sharedService.listaIsapres;

  nombresComunas = this.sharedService.nombresComunas;
  nombresIsapres = [];
  nombresPaises = [];
  listaCotizantes: any[] = this.sharedService.listaCotizantes; 
  listaCotizantesAux: any[] = [];

  tieneSeguro: boolean = this.sharedService.checkinSelected.seguroComplementario;

  noTieneConvenio: boolean = false;
  obtenerMensajeNoConvenio: any;
  mensajeTooltip: string;
  isDireccionError = false;
  isCelularError = false;
  isCorreoError = false;
  isCiudadError = false;
  isPaisError = false;
  isComunaError = false;
  isPrevisionError = false;
  isTitularError = false;
  isRutTitularError = false;
  isNombreTitularError = false;
  isApellidoTitularError = false;
  isParentescoError = false;
  isSeguroError = false;
  isExito: boolean = false;
  isError: boolean = false;
  isErrorDocumentoReq: boolean = false;
  isErrorDocumento: boolean = false;
  dorsal: string = ''
  frontal: string = ''
  isDorsal = false;
  isFrontal = false;

  isConfirm: boolean = false;
  hola: boolean = true;

  camposValidados: boolean = true;
  iconModal: string;
  errorIntegracion: boolean = false;
  durationInSeconds = 5;
  tooltipPlacement: string;
  tooltipContent: string = '';

  parentescoMock: string[] = ['Padre', 'Madre', 'Hijo'];
  msjSegurosColectivos = CONST_AUTOADM.MSJ_SEGUROS_COLECTIVOS;
  msjInfoSegurosColectivos = CONST_AUTOADM.MSJ_INFO_SEGUROS_COLECTIVOS;
  tiuloInfoPrevisional = CONST_AUTOADM.TITULO_INFO_PREVISIONAL;
  tituloSeguro = CONST_AUTOADM.TITULO_SEGURO_COMPLEMENTARIO;
  titularPrevision = CONST_AUTOADM.TITULAR_PREVISION;
  helperTextCelular = CONST_AUTOADM.HELPER_TEXT_CELULAR;
  helperTextCorreo = CONST_AUTOADM.HELPER_TEXT_CORREO;
  helperTextPrevision = CONST_AUTOADM.HELPER_TEXT_PREVISION;
  helperTextPais = CONST_AUTOADM.HELPER_TEXT_PAIS;
  helperTextCiudad = CONST_AUTOADM.HELPER_TEXT_CIUDAD;
  helperTextComuna = CONST_AUTOADM.HELPER_TEXT_COMUNA;
  helperTextDireccion = CONST_AUTOADM.HELPER_TEXT_DIRECCION;

  listaSelect: string[] = ['Seleccione Opción', 'Si', 'No'];

  msjMaxSeguros = CONST_AUTOADM.MSJ_MAX_SEGUROS;

  paciente = new Cliente();
  public nuevoSeguro: FormControl = new FormControl('', Validators.required);
  extensionFile='';
  fileDoc='';
  nameDoc='';
  fileDocFrente: string = '';
  fileDocDorso: string = '';
  extensionFileFrente: string = '';
  extensionFileDorso: string = '';

  private infoLoadedSubject = new BehaviorSubject<boolean>(false);
  infoLoaded$ = this.infoLoadedSubject.asObservable();
  titulo = CONST_AUTOADM.TITULO_PASO1
  isComunaDisabled;
  regexseguro: any = /^[a-zA-ZñÑáéíóúÁÉÍÓÚüÜ0-9+]+(?:[\s.-][a-zA-ZñÑáéíóúÁÉÍÓÚüÜ0-9+]+)*\.?$/;

  private domMutationObserver: MutationObserver;

  constructor(
    private admisionService: AdmisionService,
    private autoAdmisionService: AutoAdmisionService,
    private clienteService: ClienteService,
    public router: Router,
    public sharedService: SharedAutoAdmisionService,
    private fb: FormBuilder,
    private elementRef: ElementRef,
    private snackBar: MatSnackBar,
    private loadingService: LoaderService,
    private cuentaCorriente: CuentaCorrienteService,
    private sharepointService: SharepointService,
    private cdr: ChangeDetectorRef,
    private ngZone: NgZone,
    private renderer: Renderer2
  ) {
    this.listaPaises = [...sharedService.listaPaises];
    this.nombresIsapres = [...sharedService.nombresIsapres];
    this.nombresPaises = [...sharedService.nombresPaises];
    console.log('paises', sharedService.nombresPaises);
    
  }

  ngAfterViewChecked(): void {
    this.cdr.detectChanges();    
  }

  ngOnInit() {
    this.setTooltipPlacement();
    this.sharedService.setStepper(1);
    this.obtenerInfoDatosPersonales(this.sharedService.ppnHash).subscribe({
      next: () => {
        this.loadingService.setLoading(false);
        this.infoLoadedSubject.next(true);
      },
      error: (error) => {
        this.loadingService.setLoading(false);
        this.infoLoadedSubject.next(true);
        //this.showSnackbar(CONST_AUTOADM.MSJ_NOTFOUND_INFO);
        console.info('Error al cargar los datos', error);
      }
    });
    const paciente: string = '1';
    this.listaCotizantesAux = this.sharedService.listaCotizantes.filter(
      (item) => item.llave !== paciente
    );
    this.listaCotizantesAux.unshift({ valor: 'Seleccione Opción', llave: null });
    this.someExternalEvent();

    this.ngZone.runOutsideAngular(() => {
      this.domMutationObserver = new MutationObserver(() => {
        const button = document.getElementById('myButton') as HTMLButtonElement;
        button.click();
      });

      this.domMutationObserver.observe(this.elementRef.nativeElement, {
        childList: true,
        subtree: true,
        attributes: true
      });
    });
  }

  getTrainingName(number){
    console.log(number);
  }

  someExternalEvent() {
    this.ngZone.run(() => {
        // Aquí actualizas los datos
        this.inicializarForm();
        // Esto asegurará que Angular detecte los cambios
      });
    } 
  
  ngAfterViewInit(): void {
    //const button = document.getElementById('myButton') as HTMLButtonElement;
    console.log("Deployando ngAfter")
    this.cdr.detectChanges();
  }

  @HostListener('window:resize')
  onResize() {
    this.setTooltipPlacement();
  }

  resetForm() {
    this.formDatosPersonales = this.fb.group({
      correo: '', //
      pais: '', //
      celular: '',
      ciudad: '',
      comuna: '',
      prevision: '',
      direccion: '',
      titular: ['Seleccione Opción', Validators.required],
      nombreTitular: '',
      apellidoTitular: '',
      parentesco: '',
      seguroComplementario: '',
      //nombreSeguro: '',
      rut: ['Rut'],
      rutTitular: '',
      seguros: this.fb.array([
        ['', [Validators.required,  Validators.minLength(2), Validators.maxLength(80), 
          this.regexValidator(this.regexseguro, { 'invalidFormat': true })
        ]],
      ])
    })

  }

  get seguros(): FormArray {
    return this.formDatosPersonales.get('seguros') as FormArray;
  }

  changeSeguro(ev: any, index: number) {
    // const input = event.target as HTMLInputElement;
    let value = ev.target.value;
    console.log('changeseguro......', value, index);
    this.formDatosPersonales.get('seguros').updateValueAndValidity();
    const control = (this.formDatosPersonales.get('seguros') as FormArray).at(index);
    const listaSegurosIngresados = [];

    const nuevoSeguro = {
      ppnSeguro: '10',
      nombreSeguro: ev.target.value,
    };

    listaSegurosIngresados.push(nuevoSeguro);

    if (this.regexseguro.test(value) && value?.length > 2) {
      console.log("Formato de texto correcto");
      this.formDatosPersonales.controls['seguros'].setErrors(null);
      this.isSeguroError = false;
    } else {
      this.formDatosPersonales.controls['seguros'].setErrors({ 'invalidText': true });
      this.isSeguroError = true;
    }
    console.log('this.formDatosPersonales.get(seguros)', this.formDatosPersonales.get('seguros'));
    console.log('this.formDatosPersonales.controls[seguros]', this.formDatosPersonales.controls['seguros']);
    //this.marcarConError('seguro', valor);
  }

  regexValidator(regex: RegExp, error: ValidationErrors): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      if (!control.value) {
        return null;
      }
      const valid = regex.test(control.value);
      return valid ? null : error;
    };
  }

  findMatch(lista: string[], value: string): string | null {
    const match = lista.find((item) => item === value);
    return match ? match : null;
  }

  compareFn(o1: any, o2: any): boolean {
    return o1 && o2 ? o1.llave === o2.llave : o1 === o2;
  }

  compareTt(t1: any, t2: any): boolean {
    return t1 && t2 ? t1.llave === t2.llave : t1 === t2;
  }

  inicializarForm() {
    this.resetForm();
    /** SI HAY CHECKIN SELECCIONADO */
    if (this.infoContacto != null && this.infoDireccion != null && this.infoPrevision!= null) {
      console.log("pais entrante ", this.infoDireccion.country?.name);
      console.log("comuna entrante ", this.infoDireccion.commune?.name);

      console.log('init form 1: ', this.sharedService.nombresPaises);
      console.log('init form 2: ', this.sharedService.listaPaises);
      console.log('init form 3: ', this.infoPrevision);
      
      
      const pais = this.findMatch(
        this.sharedService.nombresPaises,
        this.infoDireccion.country?.name
      );
      console.log("pais selecionado ", pais);
      const comuna = this.findMatch(
        this.sharedService.nombresComunas,
        this.infoDireccion.commune?.name
      );
      console.log("comuna selecionado ", comuna);
      const prevision = this.findMatch(
        this.sharedService.nombresIsapres,
        this.infoPrevision?.name
      );
      let objFromInfoCotizante = (this.infoCotizante && this.infoCotizante?.descripcionCotizante)
      ? this.listaCotizantes.find((element) => element.llave == this.infoCotizante?.descripcionCotizante) 
      : this.listaCotizantesAux[0];
 
      const parentesco = objFromInfoCotizante;//objFromInfoCotizante;
      this.infoLoaded = false;
      setTimeout(() => {

        const selectPais = this.nombresPaises.find(f => f ===  this.infoDireccion.country?.name);
  
        this.formDatosPersonales.get('pais').setValue(selectPais);
        this.formDatosPersonales.get('pais').updateValueAndValidity();

        let titularEncontrado = (this.infoCotizante?.descripcionCotizante) ? (this.infoCotizante?.descripcionCotizante == '1')
        ? this.listaSelect[1] : this.listaSelect[2] : this.listaSelect[0];

        console.log('titencontrado...', titularEncontrado);
        console.log('listaSelect...', this.listaSelect);
        console.log('infocoti...', this.infoCotizante);
        console.log('rut o pasaporte', this.infoContacto.documentoNumero);
        
        this.formDatosPersonales.patchValue({
          correo: this.getContactValue('E-mail'),
          celular: this.getContactValue('Celular'),
          ciudad: this.infoDireccion.city,
          comuna: this.infoDireccion.commune?.name,
          prevision: this.infoPrevision?.name,
          direccion: this.infoDireccion.address ?? this.infoDireccion.addresses[0].address,
          titular: titularEncontrado,
          rutTitular: this.infoCotizante?.documentoNumero ?? '',
          nombreTitular: this.infoCotizante?.nombres ?? '',
          apellidoTitular: this.infoCotizante?.apellidoPaterno ?? '',
          parentesco: {llave: parentesco.llave, valor: parentesco.valor},
          seguroComplementario: this.sharedService.checkinSelected.seguroComplementario?? false,
          rut: this.checkIfRutOPasaporte(this.infoCotizante?.documentoNumero)
        });
      if(this.formDatosPersonales.value.pais?.toLowerCase() !== "chile"){
        this.isComunaDisabled = true;
      } else {
        this.isComunaDisabled = false;
        console.log("QUE PAIS TENGO 2 ", this.isComunaDisabled)
      }

      
      this.noMantieneConvenio(this.formDatosPersonales.value.prevision);
      this.isConfirm = false;
    }, 3000)
      console.log('como quedo el form inicializar form', this.formDatosPersonales.value);
      this.loadingService.setLoading(false);
      this.cdr.detectChanges();

    //}, 300)
    
    }
    
  }


  addSeguro(index: number) {
    const seguros = this.formDatosPersonales.get('seguros') as FormArray;
    // if (this.nuevoSeguro.invalid || this.seguros.length == 3) return;
    // const newSeg = this.nuevoSeguro.value;
    if(index==-1){seguros.clear()}
    if (seguros.length < 3) {
       seguros.push(this.fb.control('', [Validators.required, Validators.minLength(2), Validators.maxLength(80),
        this.regexValidator(this.regexseguro, { 'invalidFormat': true })
       ]));

      //this.nuevoSeguro.reset();
    } else {
      console.error('No se pueden agregar más de 3 seguros');
    }   
  }

  removeSeguro(index: number) {
    const seguros = this.formDatosPersonales.get('seguros') as FormArray;
    seguros.removeAt(index);
  }

  onChangeTieneSeguro(value: any) {
    this.tieneSeguro = value;
    this.formDatosPersonales.get('seguroComplementario').setValue(value);
    this.formDatosPersonales.get('seguros').updateValueAndValidity();
  }

  preCargarSeguros(): void {
    const seguros = this.formDatosPersonales.get('seguros') as FormArray;
    seguros.clear();
    console.log("test:", this.infoSeguros);
    this.infoSeguros?.map((seguro, index) => {
      if (index < 3) {
        if(seguro.ppnSeguro=='10' && seguro.nombreOtro?.includes(',')){
          let otrosSeguros = seguro.nombreOtro.split(',');
          otrosSeguros?.map((nombreOtro)=>{
            this.agregarSeguro(nombreOtro?.trim(), seguros);
          }); 
        }
        else if(seguro.ppnSeguro=='10' && seguro.nombreSeguro == 'Otro'){
          let textoOtro = (seguro.nombreOtro != '' && seguro.nombreOtro != null) 
            ? seguro.nombreOtro?.trim() : seguro.nombreSeguro?.trim();
          this.agregarSeguro(textoOtro, seguros);
        }
        else{
          this.agregarSeguro(seguro.nombreSeguro, seguros);
        }
      }
    });
  }
  agregarSeguro(valorInicial: string = '', seguros): void {
    seguros.push(
      this.fb.control(valorInicial, [
        Validators.required,
        Validators.minLength(3),
        Validators.maxLength(80),

        this.regexValidator(
         this.regexseguro,
          { invalidFormat: true }
        ),
      ])
    );
    
  }
  /**Obtiene info del paciente desde servicio de integración */
  setInfoPaciente(objPaciente: any, objDireccion: any, objContacto: any) {
    this.tengoCheckin = this.sharedService.tieneCheckin;

    this.paciente.ppn = this.sharedService.checkinSelected.ppnPaciente;
    this.paciente.rut = (objPaciente.identification_type == 1)
        ? `${objPaciente.identification_document}-${objPaciente.verification_document}`
        : objPaciente.identification_document;
    this.paciente.nombres = objPaciente?.names;
    this.paciente.primer_apellido = objPaciente.last_name;
    this.paciente.segundo_apellido = objPaciente.mothers_last_name;
    this.paciente.nombre_social = objPaciente?.name_social;
    this.paciente.sexo = objPaciente.gender == 'F' ? 'Femenino' : 'Masculino';
    this.paciente.fecha_nacimiento = objPaciente.birth_date;
    this.paciente.edad = this.calcularEdad(objPaciente.birth_date);
    this.paciente.nacionalidad = objPaciente.nationality.demonym;
    this.paciente.pais_residencia = objPaciente.nationality?.name;
    //this.paciente.contacto = objPaciente. ;// esto lo estructura al guardar
    this.paciente.verification_document = objPaciente.verification_document;
    this.paciente.isRut = (objPaciente.identification_type == 1) ? true : false;
    //this.paciente.indicadores = objPaciente.;
    //this.paciente.internacional = objPaciente.;
    this.paciente.prevision = objPaciente.health_care;
    this.paciente.contacto.comuna = objDireccion?.commune ?? new Comuna();
    //this.paciente.company_id = objPaciente.;esto lo estructura al guardar
    //this.paciente.virtual_master_id = objPaciente.;esto lo estructura al guardar
    this.sharedService.checkinSelected.numeroFichaClinica = '0';
    this.sharedService.checkinSelected.numeroCarpeta = 0;
    this.sharedService.pacienteSelected = this.paciente;
    //console.log('data', objPaciente, this.paciente);
  }

  obtenerInfoDatosPersonales(ppn: string): Observable<any> {
    this.loadingService.setLoading(true);

    const obsInfoContacto = this.autoAdmisionService.obtenerContactos(this.sharedService.ppnHash, 'ppn').pipe(
      catchError(error => {
        console.error('Error al obtener contacto', error);
        return of(null);
      })
    );
    
    const obsInfoDirecciones = this.autoAdmisionService.obtenerDirecciones(this.sharedService.ppnHash, 'ppn').pipe(
      catchError(error => {
        console.error('Error al obtener direcciones', error);
        return of(null);
      })
    );
    
    const obsInfoPaciente = this.autoAdmisionService.obtenerClientePorHash(ppn, 'ppn').pipe(
      catchError(error => {
        console.error('Error al obtener datos del paciente', error);
        return of(null);
      })
    );

    const obsInfoCotizante = this.sharedService.checkinSelected.id === 0 
      ? of(null) 
      : this.autoAdmisionService.previsiones(this.sharedService.checkinSelected.id).pipe(
          catchError(error => {
            console.error('Error al obtener cotizante', error);
            return of(false); 
          })
        );

    console.log("ObsInfoCotizante", obsInfoCotizante);

    const obsInfoSeguros = this.admisionService.obtenerSegurosComplementarios(this.sharedService.checkinSelected.id).pipe(
      retryWhen(errors => errors.pipe(
        take(3),
        tap(() => {
          console.warn('Reintentando obtener infoSeguros...');
        }),
        delay(1000)
      )),
      catchError((error) => {
        console.error('Error al obtener seguros', error);
        return of(false);
      })
    );
    
    console.log("test obsInfoSeguros", obsInfoSeguros);
    
    const obsInfoPacienteAutoadmision = this.autoAdmisionService.obtenerClienteAutoadmision(this.sharedService.checkinSelected.id).pipe(
      catchError(error => {
        console.error('Error al obtener datos del paciente', error);
        return of(null);
      })
    );
    
    return forkJoin([obsInfoContacto, obsInfoDirecciones, obsInfoPaciente, obsInfoCotizante, obsInfoSeguros, obsInfoPacienteAutoadmision]).pipe(
      tap(([infoContacto, infoDirecciones, infoPaciente, infoCotizante, infoSeguros, infoPacienteAutoadm]) => {
        console.log("test", { infoContacto, infoDirecciones, infoPaciente, infoCotizante, infoSeguros, infoPacienteAutoadm });

        if (!infoPaciente) {
          console.warn('No se encontró información del paciente');
        }

        if (!infoPacienteAutoadm?.message && this.sharedService.checkinSelected.autoAdmision && this.sharedService.checkinSelected.id != 0) {
          this.infoContacto = infoPacienteAutoadm?.contacto?.contacts || [];
          this.objectContacto = infoPacienteAutoadm?.contacto || {};
          this.infoDireccion = infoPacienteAutoadm?.direccion?.addresses[0] || null;
          this.objectDireccion = infoPacienteAutoadm.direccion || null;
          this.infoPrevision = infoPacienteAutoadm?.informacion?.health_care || null;
          this.objectPaciente = infoPacienteAutoadm?.informacion || null;
          this.infoCotizante = infoCotizante || null;
          this.infoSeguros = infoSeguros || null;
        } else {
          this.infoContacto = infoContacto?.contacts || [];
          this.objectContacto = infoContacto || {};
          this.infoDireccion = infoDirecciones?.addresses[0] || null;
          this.objectDireccion = infoDirecciones || null;
          this.infoPrevision = infoPaciente?.health_care || null;
          this.objectPaciente = infoPaciente || null;
          this.infoCotizante = infoCotizante || null;
          this.infoSeguros = infoSeguros || null;
        }

        if (this.objectPaciente) {
          this.setInfoPaciente(this.objectPaciente, this.infoDireccion, this.infoContacto);
        }

        this.inicializarForm();
      }),
      tap(() => {
        console.log("pase por infoLoaded");
        this.loadingService.setLoading(false);

        // Aquí se verifica si `infoSeguros` está vacío
        console.log("test this.tieneSeguro", this.tieneSeguro);
        if (this.infoSeguros?.length > 0) {
          this.preCargarSeguros();
        } else if (this.tieneSeguro) {
          // Si el array está vacío, intentamos hacer el retry
          throw new Error('infoSeguros está vacío, se intentará de nuevo.');
        }
      }),
      catchError(error => {
        this.loadingService.setLoading(false);
        this.resetForm();
        this.showSnackbar(CONST_AUTOADM.MSJ_NOTFOUND_INFO);
        console.info('Error al cargar los datos', error);
        return throwError(error);
      })
    );
  }

  calcularEdad(fechaNacimiento: string): number {
    const hoy = new Date();
    const fechaNac = new Date(fechaNacimiento);
    let edad = hoy.getFullYear() - fechaNac.getFullYear();
    const mes = hoy.getMonth() - fechaNac.getMonth();

    if (mes < 0 || (mes === 0 && hoy.getDate() < fechaNac.getDate())) {
      edad--;
    }

    return edad;
  }

  getContactValue(contactType: string) {
    if (this.infoContacto) {
      const contact = this.infoContacto.find(
        (contact) => contact.type.description === contactType
      );
      return contact ? contact.value : '';
    }
    return '';
  }

  salir() {
    window.location.href =
      'https://www.clinicaalemana.cl/informacion-para-pacientes/todo-sobre-hospitalizacion';
  }

  async guardarYcontinuar() {

    if (!this.validarCampos()) { 
      //alert('todo ok');
      try {
        await this.guardar().toPromise();
        setTimeout(() => {
          this.router.navigate(['autoAdmision/paso2']);
        }, 500); // 1000ms = 1 segundo de retraso
      } catch (error) {
        this.showSnackbar('Error durante el proceso de guardado.');
      }
    } 
    else {
       this.showSnackbar(CONST_AUTOADM.MSJ_FORM_ERROR);
       //alert('validar');
    }
  }

  public onKeyUp(event: any) {
    const NUMBER_REGEXP = /^\s*(\-|\+)?(\d+-?|(\d*(\-.\d*)))([eE-][+-]?\d+)?\s*$/;
    let newValue = event.target.value;
    if (!NUMBER_REGEXP.test(newValue)) {
        event.target.value = newValue.slice(0, -1);
    }
    if (newValue.length > 20) {
        event.target.value = newValue.slice(0, -1);
    }
  }


  validarCampos(): boolean {
    console.log("validar campos rut paciente, ", this.sharedService.checkinSelected.documentoNumero);
    console.log("validar campos rut titular, ", this.formDatosPersonales.value.rutTitular);
    console.log("Ciudad", this.formDatosPersonales.value.ciudad)
    const ciudad = this.formDatosPersonales.value.ciudad;
    const regexCiudad = /^[a-zA-Z\s]*$/;
    const correo = this.formDatosPersonales.value.correo;
    const regexCorreo = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}$/;
    const direccion = this.formDatosPersonales.value.direccion;
    const longitudDireccion = direccion.trim().length;
    const celular = this.formDatosPersonales.value.celular;
    const regexCelular = /^[0-9]{9}$/;
    const comuna = this.formDatosPersonales.value.comuna;
    const prevision = this.formDatosPersonales.value.prevision;
    const pais = this.formDatosPersonales.value.pais;
    const titular = this.formDatosPersonales.value.titular;
    const parentesco = this.formDatosPersonales.value.parentesco;
    const rutTitular = this.formDatosPersonales.value.rutTitular;
    const regexRutTitular = /^\d{8}-?[0-9kK]$/;
    const regexPasaporte = /^(?:[A-Z]{1,3}\d{5,8}|\d{1,9})$/;
    const nombreTitular = this.formDatosPersonales.value.nombreTitular;
    const regexNombreTitular = /^[a-zA-ZáéíóúÁÉÍÓÚüÜñÑ\s]*$/;
    const longitudNombreTitular = nombreTitular.trim().length;
    const apellidoTitular = this.formDatosPersonales.value.apellidoTitular;
    const regexApellidoTitular = /^[a-zA-ZáéíóúÁÉÍÓÚüÜñÑ\s]*$/;
    const longitudApellidoTitular = apellidoTitular.trim().length;
    
    this.isCorreoError = !regexCorreo.test(correo);
    this.isCelularError = !regexCelular.test(celular);
    this.isDireccionError = longitudDireccion < 5 || longitudDireccion > 80;
    this.isPaisError = !this.formDatosPersonales.value.pais;
    this.isTitularError = !titular || titular.toLowerCase() === 'seleccione opción';
    this.isRutTitularError = (titular === 'No' && this.formDatosPersonales.value.rut === 'Rut') ? (!regexRutTitular.test(rutTitular) || this.sharedService.checkinSelected.documentoNumero === rutTitular)
    : (titular === 'No' && this.formDatosPersonales.value.rut === 'Pasaporte')
      ? (!regexPasaporte.test(rutTitular))
      : false;                      
                          
    this.isNombreTitularError = (titular === 'No') ? (!regexNombreTitular.test(nombreTitular) || longitudNombreTitular < 2 || longitudNombreTitular > 20) : false;
    this.isApellidoTitularError = (titular === 'No') ? (!regexApellidoTitular.test(apellidoTitular) || longitudApellidoTitular < 2 || longitudApellidoTitular > 20) : false;
    this.isParentescoError = (titular === 'No') ? (!parentesco.valor || parentesco.valor.toLowerCase() === 'seleccione opción') : false;
    this.isPrevisionError = !prevision || prevision.toLowerCase() === 'sin información';
    this.isPaisError = !pais || pais.toLowerCase() === 'sin información';
    this.isCiudadError = !ciudad || !regexCiudad.test(ciudad) || !ciudad.trim();
    console.log("Ciudad Error", this.isCiudadError)

    if (!pais || pais.trim() === '' || pais.toLowerCase() === 'chile') {
      this.isComunaError =
        !comuna || comuna.toLowerCase() === 'sin información';
    } else {
      this.isComunaError = false;
    }

    const hasInvalidSeguro = this.formDatosPersonales.value.seguros?.some(element => !this.regexseguro.test(element));
    this.isSeguroError = (this.formDatosPersonales.value.seguroComplementario && this.formDatosPersonales.value.seguros?.length > 0 && hasInvalidSeguro)
    || (this.formDatosPersonales.value.seguroComplementario && this.formDatosPersonales.value.seguros?.length===0);

    this.isConfirm =
      this.isCelularError ||
      this.isCorreoError ||
      this.isCiudadError ||
      this.isDireccionError ||
      this.isPaisError ||
      this.isComunaError ||
      this.isPrevisionError ||
      this.isTitularError ||
      this.isRutTitularError ||
      this.isNombreTitularError ||
      this.isApellidoTitularError ||
      this.isParentescoError || 
      this.isSeguroError
    console.log('this.isSeguroError', this.isSeguroError);
    
    return this.isConfirm;
  }
  async volver() {
    this.router.navigate([
      'autoAdmision/antecedentesHospitalizacion',
      { ppn: this.sharedService.ppnHash },
    ]);
    //this.sharedService.checkinSelected.id = undefined;
  }

    changeSelect(ev: any, controlName: string) {
      //const valor = this.extractValue(ev);
      //if (!valor) return;
      let valor;
      if (ev.value !== undefined) {
        valor = ev.value;
      } else if (ev.target.value !== undefined) {
        valor = ev.target.value;
      } else {
        return;
      }
      console.log('controlName--->', controlName, ' ev--->', ev, 'valor--->', valor);
      switch (controlName) {
        case 'prevision':
          this.handlePrevision(valor);
          break;
        case 'pais':
          this.handlePais(valor);
          break;
        case 'titular':
          this.handleTitular(valor);
          break;
        default:
          this.formDatosPersonales.get(controlName).setValue(valor);
          this.formDatosPersonales.get(controlName).updateValueAndValidity();
      }
    
      this.marcarConError(controlName, valor);
    }
    
    private extractValue(ev: any): any {
      return ev.value ?? ev.target?.value ?? null;
    }
  
    private handlePrevision(valor: any) {
      this.noMantieneConvenio(valor);
      this.labelPrevision = valor;
    }
    
    private handlePais(valor: string) {
      if (valor.toLowerCase() !== 'chile') {
        this.labelComuna = 'Comuna*';
        this.formDatosPersonales.get('ciudad').setValue('');
        this.formDatosPersonales.get('comuna').setValue('Sin Información');
        this.isComunaDisabled = true;
        console.log("QUE PAIS TENGO 3 ", this.isComunaDisabled)
      } else {
        this.formDatosPersonales.get('comuna').enable();
        this.isComunaDisabled = false;
        console.log("QUE PAIS TENGO 4 ", this.isComunaDisabled)
      }
    }
    
    private handleTitular(valor: string) {
      if (valor === 'Si') {
        setTimeout(() => {
          this.formDatosPersonales.patchValue({
            parentesco: { llave: this.listaCotizantes[0].llave, valor: this.listaCotizantes[0].valor },
            rutTitular: this.sharedService.checkinSelected.documentoNumero,
            apellidoTitular: this.sharedService.checkinSelected.apellidoPaterno,
            nombreTitular: this.sharedService.checkinSelected.nombres,
          });
          this.cdr.detectChanges();
        }, 100)
      } 
      else if (valor === 'No' && this.infoCotizante.documentoNumero !== this.sharedService.checkinSelected.documentoNumero) {
        const parent = this.listaCotizantes.find((element) => element.llave == this.infoCotizante?.descripcionCotizante) ?? '';
        setTimeout(() => {
          this.formDatosPersonales.patchValue({
            parentesco: { llave: parent.llave ?? '', valor: parent.valor ?? '' },
            rutTitular: this.infoCotizante.documentoNumero ?? '',
            apellidoTitular: this.infoCotizante.apellidoPaterno ?? '',
            nombreTitular: this.infoCotizante.nombres ?? '',
          });

          this.cdr.detectChanges();
        }, 100)
      } else if (valor === 'No' && this.infoCotizante.documentoNumero === this.sharedService.checkinSelected.documentoNumero) {
        setTimeout(() => {
          this.formDatosPersonales.patchValue({
            parentesco: '',
            rutTitular: '',
            apellidoTitular: '',
            nombreTitular: '',
          });
          
          this.cdr.detectChanges();
        }, 100)
      }
    }

  marcarConError(controlName: string, valor: any) {
    switch (controlName) {
      case 'ciudad':
        this.isCiudadError = valor;
        break;
      case 'celular':
        this.isCelularError = valor;
        /*if (ev.target.value.length < 9){
              this.isCelularError = true;
            } else{
              this.isCelularError = valor;
            } */
        break;
      case 'correo':
        this.isCorreoError = valor;
        break;
      case 'direccion':
        this.isDireccionError = valor;
        break;
      case 'pais':
        this.isPaisError = valor;
        this.isPaisError = false;
        break;
      case 'comuna':
        this.isComunaError = valor;
        this.isComunaError = false;
        break;
      case 'prevision':
        this.isPrevisionError = valor;
        this.isPrevisionError = false;
        break;
      case 'titular':
        this.isTitularError = valor;
        this.isTitularError = false;
        break;
      case 'nombreTitular':
        this.isNombreTitularError = valor;
        break;
      case 'apellidoTitular':
        this.isApellidoTitularError = valor;
        break;
      case 'rutTitular':
        this.isRutTitularError = valor;
        break;
      case 'parentesco':
        this.isParentescoError = valor;
        this.isParentescoError = false;
        break;
    }
  }

  noMantieneConvenio(prevision) {
    if (prevision === 'Consalud S.A.' || prevision === 'Nueva Masvida') {
      // const obtenerMensajeIsapre = this.sharedService.allParams.find(
      //   (elemt) => elemt.categoria === 'mensajeIsapre'
      // );
      this.mensajeTooltip = CONST_AUTOADM.MSJE_ISAPRE_SIN_CONV;
      //this.mensajeTooltip = obtenerMensajeIsapre.valor;
      //console.log('mensajee', this.mensajeTooltip);
      this.noTieneConvenio = true;
    } else {
      this.noTieneConvenio = false;
    }
  }

  createContactObject(typeDescription: string, value: string): ContactInfo {
    const classificationId = 1;
    const typeId = typeDescription == 'E-mail' ? 3 : 4;

    const newContact: ContactInfo = {
      classification: {
        id: classificationId,
        description: 'Particular',
      },
      type: {
        id: typeId,
        description: typeDescription,
      },
      value: value,
      status: 'S',
    };

    return newContact;
  }

  showSnackbar(message: string) {
    this.snackBar.open(message, 'Cerrar', {
      duration: this.durationInSeconds * 1000,
      horizontalPosition: 'end',
      politeness: 'polite',
    });
  }

  setTooltipPlacement() {
    const width = window.innerWidth;
    const height = window.innerHeight;
    if (width >= 1280 && height >= 720) {
      this.tooltipPlacement = 'top'; // Desktop
      this.tooltipContent = `Si necesitas modificar estos datos, debes dirigirte a una sucursal`;
    } else if (width >= 968 && width < 1280) {
      this.tooltipPlacement = 'right'; // Tablet
      this.tooltipContent = `Si necesitas modificar estos datos,<br/>debes dirigirte a una sucursal`;
    } else if ( width < 968) {
      this.tooltipPlacement = 'left'; // Mobile
      this.tooltipContent = `Si necesitas modificar estos datos,<br/>debes dirigirte a una sucursal`;
    } else {
      this.tooltipPlacement = 'top'; // Otherwise
      this.tooltipContent = `Si necesitas modificar estos datos, debes dirigirte a una sucursal`;
    }
  }

  guardarPrevision(): Observable<any> {
    //console.log('form antes de guardar prev', this.formDatosPersonales.value);
    let parent = this.formDatosPersonales.value.parentesco?? this.listaCotizantes[0];
    //console.log('ppn a guardar...', (parent.llave != '1') ? 0 : this.sharedService.checkinSelected.ppnPaciente);
    let objectPrevision = {
      descripcionCotizante: parent.llave ?? '',
      ppnCotizante: 0,
      documentoId: (this.sharedService.checkinSelected.documentoId != 0) ? this.sharedService.checkinSelected.documentoId : 1,
      documentoNumero:  (parent.llave != '1') ? this.formDatosPersonales.value.rutTitular : this.sharedService.checkinSelected.documentoNumero?? '',
      apellidoPaterno: (parent.llave != '1') ? this.formDatosPersonales.value.apellidoTitular : this.sharedService.checkinSelected.apellidoPaterno?? '',
      apellidoMaterno: '',
      nombres: (parent.llave != '1') ? this.formDatosPersonales.value.nombreTitular: this.sharedService.checkinSelected.nombres ?? '',
      ppnSeguroComplementario: '10',
      nombreSeguroComplementario: this.formDatosPersonales.value.seguros[0] ?? '',
      seguroComplementarioOtro: this.formDatosPersonales.value.seguros[1] ?? ''
    }

    return this.admisionService.modificarOCrearAntecedentesPrev(
      this.sharedService.checkinSelected.id.toString(),
      objectPrevision
    );      
  }

  setObjectSeguros() {
    let seguroOtro: any;
    //console.log('this.formDatosPersonales.value.seguros', this.formDatosPersonales.value.seguros);
    if (this.formDatosPersonales.value.seguros?.length > 0) {
      //TODO: A peticion de PO los seguros siempre seran de tipo 'Otro' y su nombre quedará separado por comma
      const nombreOtro = this.formDatosPersonales.value.seguros.join(', ');
      seguroOtro = {
        ppnSeguro: '10', 
        nombreSeguro: 'Otro',
        nombreOtro: nombreOtro,
      }
    }
    return [seguroOtro];
  }

  guardarSeguros(): Observable<any> {
    const condicionSeguro = this.formDatosPersonales.value.seguroComplementario
      && this.formDatosPersonales.value.seguros?.length > 0;
    if (condicionSeguro) {
      return this.admisionService.guardarSegurosComplementarios(
        this.sharedService.checkinSelected.id.toString(),
        this.setObjectSeguros()
      );
    }
    else {
      return of(null);
    }
  }

  /** Guardar en servicios bbdd */
  guardar(): Observable<void> {
    this.loadingService.setLoading(true);
    const { requestModificarIsapre } = this.objectsToSave();
    this.actualizarInfoObjAdm(this.paciente.contacto);
  
    const admisionObservable = this.crearOActualizarAdmision();
  
    return new Observable<void>((observer) => {
      admisionObservable.pipe(
        tap(resultadoAdmision => {
          if (!this.sharedService.checkinSelected.id || this.sharedService.checkinSelected.id === 0) {
            this.idAdmisionActual = this.recuperarMessageId(resultadoAdmision);
            this.sharedService.checkinSelected.id = this.idAdmisionActual;
            this.sharedService.checkinSelected.codigoPrevision = requestModificarIsapre?.health_care.id ?? 0;
            this.sharedService.checkinSelected.nombrePrevision = (requestModificarIsapre?.health_care.id)
              ? this.listaIsapres?.find((element) => requestModificarIsapre?.health_care.id == element.codigo).glosa
              : 'Sin información';
          }
        }),
        switchMap(() => {
          return forkJoin([
            this.guardarSeguros(),
            this.guardarPrevision(),
            this.modificarCliente(this.objectsToSave())
          ]);
        }),
        catchError(error => {
          this.loadingService.setLoading(false);
          this.showSnackbar('Error al procesar las operaciones');
          //console.error('Error en la secuencia de guardado', error);
          observer.error(error);
          return throwError(error);
        })
      ).subscribe({
        next: () => {
          this.loadingService.setLoading(false);
          //this.showSnackbar('Información guardada con éxito');
          observer.next();
          observer.complete();
        },
        error: error => {
          this.loadingService.setLoading(false);
          //this.showSnackbar('Error al procesar las operaciones');
          console.error('Error en la secuencia de guardado', error);
          observer.error(error);
        }
      });
    });
  }

  crearOActualizarContacto(contacto, tipo) {
    let tipoContacto = tipo == 'correo' ? 'E-mail' : 'Celular'
    let tieneContacto = this.getContactValue(tipoContacto)
    return (tieneContacto != '' ? this.autoAdmisionService.modificarContacto(
      contacto,
      this.sharedService.ppnHash,
      this.token,
      'put'
    ) : this.autoAdmisionService.modificarContacto(
      contacto,
      this.sharedService.ppnHash,
      this.token,
      'post'
    )).pipe(
      catchError(error => {
        console.error(`Error al procesar contacto ${tipo}`, error);
        return of({ error: true, status: error.status ?? 500 });
      })
    );
  }

  modificarCliente(request) {
    //console.log('modificarCliente', request)
    return this.autoAdmisionService.modificarClienteAutoadmision(
      this.sharedService.checkinSelected.id, 
      request
    ).pipe(
      catchError(error => {
        console.error('Error al modificar cliente', error);
        console.log(error)
        return of({ error: true, status: error.status ?? 500 });
      })
    );
  }

  crearOActualizarAdmision() {
    let bodyAutoAdmToSave = {...this.sharedService.checkinSelected};
    if(bodyAutoAdmToSave.antecedentesHospitalizacion){
      delete bodyAutoAdmToSave.antecedentesHospitalizacion;
    }
    return (this.sharedService.checkinSelected.id
      ? this.autoAdmisionService.modificarAdmision( bodyAutoAdmToSave.id.toString(), bodyAutoAdmToSave, this.sharedService.checkinSelected.usuarioCreador)
      : this.autoAdmisionService.crearAdmision(bodyAutoAdmToSave, this.token)).pipe(
        catchError(error => {
          return of({ error: true, status: error.status ?? 500 });
        })
      );
  }

  /**Armar el objeto de contacto, direcciones, prevision y this.paciente*/
  objectsToSave(): any {
    this.paciente.contacto = { ...this.formDatosPersonales.value };

    let comunaEncontrada = this.sharedService.buscarObjetoPorNombre(
      this.formDatosPersonales.value.comuna,
      'comuNombre',
      this.sharedService.listaComunas
    );
    if (comunaEncontrada) {
      this.paciente.contacto.comuna = {
        id: comunaEncontrada.idComuna,
        nombre: comunaEncontrada.comuNombre,
      };
    } else {
      console.error('Comuna no encontrada');
    }
    let paisEncontrado = this.sharedService.buscarObjetoPorNombre(
      this.formDatosPersonales.value.pais,
      'paisNombre',
      this.sharedService.listaPaises
    );
    if (paisEncontrado) {
      this.paciente.contacto.pais = {
        id: paisEncontrado.idPais,
        nombre: paisEncontrado.paisNombre,
      };
    } else {
      console.error('País no encontrado');
    }

    let previsionEncontrada = this.sharedService.buscarObjetoPorNombre(
      this.formDatosPersonales.value.prevision,
      'glosa',
      this.listaIsapres
    );
    //console.log('previsionEncontrada', previsionEncontrada);
    if (previsionEncontrada) {
      this.paciente.prevision = {
        id: previsionEncontrada.codigo,
        nombre: previsionEncontrada.glosa,
      };
    } else {
      console.error('Previsión no encontrada');
    }

    let requestModificarIsapre = {
      health_care: {
        id: previsionEncontrada
          ? parseInt(previsionEncontrada.codigo, 10)
          : null,
        name: previsionEncontrada.glosa,
        business_name: previsionEncontrada.glosa,
      },
      company_id: this.objectPaciente.company_id,
      virtual_master_id: this.objectPaciente.virtual_master_id,
    };

    let correoEncontrado = this.createContactObject(
      'E-mail',
      this.formDatosPersonales.value.correo
    );
    //console.log('correoEncontrado', correoEncontrado);

    let celularEncontrado = this.createContactObject(
      'Celular',
      this.formDatosPersonales.value.celular
    );

    return {
      correoEncontrado,
      celularEncontrado,
      requestModificarIsapre,
      requestDireccion: this.clienteService.crearRequestModificarDireccion(this.paciente.contacto, this.objectDireccion, 'autoAdmision'),
      contacto: (this.sharedService.checkinSelected.id == 0) ?  {contacts: this.infoContacto} : this.objectContacto,
      direccion: this.objectDireccion,
      informacion: this.objectPaciente
    };
  }

  recuperarMessageId(params) {
    return params.message.split(":")[1].trim();
  }

  actualizarInfoObjAdm(contacto: any) {
    let fechaActual = new Date();

    //this.sharedService.checkinSelected.ppnPaciente = parseInt(this.paciente.ppn); ya lo tiene desde el paso 0
    this.sharedService.checkinSelected.documentoId = (this.paciente.rut.includes('-')) ? 1 : 2;
    this.sharedService.checkinSelected.documentoNumero = this.paciente.rut ?? '';
    this.sharedService.checkinSelected.nombres = this.paciente.nombres?? '';
    this.sharedService.checkinSelected.apellidoPaterno = this.paciente.primer_apellido?? '';
    this.sharedService.checkinSelected.apellidoMaterno = this.paciente.segundo_apellido?? '';
    this.sharedService.checkinSelected.nombreSocial = this.paciente.nombre_social?? '';
    this.sharedService.checkinSelected.codigoGenero = this.paciente.sexo.toLowerCase() == 'femenino' ? 2 : 1;
    this.sharedService.checkinSelected.fechaNaciemiento = this.paciente.fecha_nacimiento ?? '';
    this.sharedService.checkinSelected.direccion = this.paciente.contacto.direccion ?? '';
    this.sharedService.checkinSelected.telefono = this.paciente.contacto.telefonoFijo ?? '';
    this.sharedService.checkinSelected.telefono_movil = contacto.celular ?? '';
    this.sharedService.checkinSelected.email = contacto.correo ?? '';
    this.sharedService.checkinSelected.codigoComuna = this.paciente.contacto.comuna.id ?? 0;
    this.sharedService.checkinSelected.nombreComuna = this.paciente.contacto.comuna.nombre ?? '';
    this.sharedService.checkinSelected.codigoPais = this.paciente.contacto.pais.id ?? 0;
    this.sharedService.checkinSelected.nombrePais = this.paciente.contacto.pais.nombre ?? '';
    this.sharedService.checkinSelected.ciudad = contacto.ciudad ?? '';
    // this.sharedService.checkinSelected.pacienteInternacional = !this.extranjero ? false : this.extranjero;
    this.sharedService.checkinSelected.presupuesto = false;
    this.sharedService.checkinSelected.codigoTipoPaciente = 0;
    this.sharedService.checkinSelected.nombrePrevision = this.paciente.prevision.nombre ?? '';
    this.sharedService.checkinSelected.codigoPrevision = this.paciente.prevision.id ?? '';

    // if (this.tieneFE) {
    //   this.sharedService.checkinSelected.requerimientoPoderCodigo = this.sourceReqPoder.length == 1 ? this.sourceReqPoder[0].value : this.valorReqPoder.value;
    //   this.sharedService.checkinSelected.requerimientoPoderDescripcion = this.sourceReqPoder.length == 1 ? this.sourceReqPoder[0].label : this.valorReqPoder.label;
    // }
    // this.sharedService.checkinSelected.motivoRequerimientoPoder = this.inputMotivoRequerimientoPoder == '' ? null : this.inputMotivoRequerimientoPoder;

    this.sharedService.checkinSelected.codigoTipoPaciente = 0;
    this.sharedService.checkinSelected.nacionalidad = this.paciente.nacionalidad;
    this.sharedService.checkinSelected.holdingId = 2;//TODO: Cambiar esto cuando se cuente con la info real del paciente
    this.sharedService.checkinSelected.empresaId = this.sharedService.checkinSelected.holdingId;
    this.sharedService.checkinSelected.usuarioCreador = 'Auto-Admision';
    this.sharedService.checkinSelected.fechaCreacion = fechaActual;
    this.sharedService.checkinSelected.derivadorPpn = 0;
    this.sharedService.checkinSelected.derivadorNombre = "";
    this.sharedService.checkinSelected.sucursalId = 1;
    this.sharedService.checkinSelected.sucursalHospId = 1;
    this.sharedService.checkinSelected.vigencia = true;
    this.sharedService.checkinSelected.cuentaCorriente = null;
    this.sharedService.checkinSelected.checkIn = false; 
    this.sharedService.checkinSelected.observaciones = "";
    this.sharedService.checkinSelected.autoAdmision = true;
    this.sharedService.checkinSelected.seguroComplementario = this.formDatosPersonales.value.seguroComplementario != ''
      ? this.formDatosPersonales.value.seguroComplementario
      : false;

    //console.log('checkin', this.sharedService.checkinSelected)
  }

  checkIfRutOPasaporte(rutoPasaporte){
    const regexPasaporte = /^(?:[A-Z]{1,3}\d{5,8}|\d{1,8})$/;
    console.log(regexPasaporte.test(rutoPasaporte));
    console.log(rutoPasaporte);
    if(regexPasaporte.test(rutoPasaporte)){
      return 'Pasaporte'
    } else {
      return 'Rut'
    }
  }

  infoLoadedSubscription = this.infoLoaded$.subscribe((loaded: boolean) => {
    if (loaded) {
      setTimeout(() => {
        const button = document.getElementById('myButton') as HTMLButtonElement;
        button.click();
        this.cdr.detectChanges();
        window.dispatchEvent(new Event('resize'));
      }, 1000);

    }
  });

  toggleTooltip() {
      this.tooltip.toggle();
      setTimeout(() => {
        this.tooltip.hide();
      }, 5000);
  }
}
