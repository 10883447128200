import { BrowserModule } from '@angular/platform-browser';
import { CUSTOM_ELEMENTS_SCHEMA, ErrorHandler, Inject, InjectionToken, NgModule} from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { ClienteUtil } from './servicios/cliente/cliente.util';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import {
  MsalModule,
  MsalInterceptor,
  MsalInterceptorConfiguration,
  MSAL_INSTANCE,
  MSAL_GUARD_CONFIG, MSAL_INTERCEPTOR_CONFIG, MsalService, MsalGuardConfiguration
} from '@azure/msal-angular';
import {
  BrowserCacheLocation,
  Configuration,
  InteractionType,
  IPublicClientApplication, LogLevel,
  PublicClientApplication
} from '@azure/msal-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MsalGuard } from '@azure/msal-angular';
import { AngularMaterialModule } from './material-module';
import { MomentDateAdapter} from '@angular/material-moment-adapter';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE} from '@angular/material/core';
import { AntecedentesHospitalizacionComponent } from './componentes/autoAdmision/antecedentes-hospitalizacion/antecedentes-hospitalizacion.component';
import { Paso2AutoAdmisionComponent } from './componentes/autoAdmision/paso2-auto-admision/paso2-auto-admision.component';
import { AutoAdmisionComponent } from './componentes/autoAdmision/auto-admision.component';
import { LoaderComponent } from './componentes/autoAdmision/shared/loader/loader.component';
import { StepperComponent } from './componentes/autoAdmision/stepper/stepper.component';
import { Paso1AutoAdmisionComponent } from './componentes/autoAdmision/paso1-auto-admision/paso1-auto-admision.component';
import { Paso3AutoAdmisionComponent } from './componentes/autoAdmision/paso3-auto-admision/paso3-auto-admision.component';
import { environment } from '@environments/environment';

import { InputDsComponent } from '../app/componentes/DS/input-ds/input-ds.component';
import { SelectDsComponent } from '../app/componentes/DS/select-ds/select-ds.component';
import { RadioDsComponent } from '../app/componentes/DS/radio-ds/radio-ds.component';
import { ButtonDSComponent } from '../app/componentes/DS/button-ds/button-ds.component';
import { CheckboxDsComponent } from '../app/componentes/DS/checkbox-ds/checkbox-ds.component';
import { SearchDsComponent } from './componentes/DS/search-ds/search-ds.component';
import { SpinnersDsComponent } from './componentes/DS/spinners-ds/spinners-ds.component';
import { IconDsComponent } from './componentes/DS/icon-ds/icon-ds.component';
import { GlobalErrorHandler } from './utilitarios/global-error-handler.service';
import { Filtros } from './utilitarios/filtros';
import { Mails } from './utilitarios/mails';
import { Verificaciones } from './utilitarios/verificaciones';
import { ErrorDialogService } from './utilitarios/errors/error-dialog.service';
import { CorsInterceptor } from './servicios/interceptor/interceptor.service';
import { HtmlParser } from '@angular/compiler';

export const MY_DATE_FORMAT = {
  parse: {
    dateInput: 'DD-MM-YYYY',
  },
  display: {
    dateInput: 'DD-MM-YYYY',
    monthYearLabel: 'MMM YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'MMMM YYYY',
  },
};

export function loggerCallback(_logLevel: LogLevel, message: string): void {
  console.log(message);
}

export function MSALInstanceFactory(): IPublicClientApplication {

  const isIE = window.navigator.userAgent.indexOf('MSIE ') > -1 || window.navigator.userAgent.indexOf('Trident/') > -1;
  return new PublicClientApplication({
    auth: {
      clientId: environment.azureADConfig.clientId,
      authority: environment.azureADConfig.authority,
      redirectUri: '/',
    },
    cache: {
      cacheLocation: BrowserCacheLocation.LocalStorage,
      storeAuthStateInCookie: isIE,
    },
    system: {
      loggerOptions: {
        loggerCallback,
        logLevel: LogLevel.Error,
        piiLoggingEnabled: false
      }
    },
  });
}

export function MSALInterceptorConfigFactory(): MsalInterceptorConfiguration {
  const protectedResourceMap = new Map<string, Array<string>>();
  protectedResourceMap.set(environment.azureADConfig.urlApp, ['user.read']);
  return {
    interactionType: InteractionType.Redirect,
    protectedResourceMap,
  };
}

export function MSALGuardConfigFactory(): MsalGuardConfiguration {
  return { interactionType: InteractionType.Redirect, authRequest: { scopes: ['user.read'] } };
}

@NgModule({
  declarations: [
    AppComponent,
    AntecedentesHospitalizacionComponent,
    Paso2AutoAdmisionComponent,
    AutoAdmisionComponent,
    LoaderComponent,
    StepperComponent,
    Paso1AutoAdmisionComponent,
    Paso3AutoAdmisionComponent,
    InputDsComponent,
    SelectDsComponent,
    RadioDsComponent,
    ButtonDSComponent,
    CheckboxDsComponent,
    SearchDsComponent,
    SpinnersDsComponent,
    IconDsComponent
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    FormsModule,
    HttpClientModule,
    AngularMaterialModule,
    MsalModule,
    ReactiveFormsModule
  ],
  providers: [
    {
      provide: ErrorHandler,
      useClass: GlobalErrorHandler,
    },
    Filtros,
    Verificaciones,
    Mails,
    ErrorDialogService,
    { provide: HTTP_INTERCEPTORS, useClass: MsalInterceptor, multi: true },
    MsalGuard,
    ClienteUtil,
    {provide: MAT_DATE_LOCALE, useValue: 'es-CL'},
    {provide: DateAdapter, useClass: MomentDateAdapter, deps: [MAT_DATE_LOCALE]},
    {provide: MAT_DATE_FORMATS, useValue: MY_DATE_FORMAT},
    {
      provide: HTTP_INTERCEPTORS,
      useClass: MsalInterceptor,
      multi: true
    },
    {
      provide: MSAL_INSTANCE,
      useFactory: MSALInstanceFactory
    },
    {
      provide: MSAL_GUARD_CONFIG,
      useFactory: MSALGuardConfigFactory
    },
    {
      provide: MSAL_INTERCEPTOR_CONFIG,
      useFactory: MSALInterceptorConfigFactory
    },
    MsalService,
    { provide: HTTP_INTERCEPTORS, useClass: CorsInterceptor, multi: true }
  ],
  schemas:[CUSTOM_ELEMENTS_SCHEMA],
  bootstrap: [AppComponent]
})

export class AppModule {
}
