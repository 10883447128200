
<div class="container justify-content-center w-100" style="padding: 0px !important;" *ngIf="!loading">

  <cas-text variant="display1" class="titulo" (click)="ver()">
    <h2>{{tituloPaso2}}</h2>
  </cas-text>

  <div class="container w-100 px-3 py-3 pb-5 card" style="background-color: white; margin-top: 50px; border-radius: 8px; margin-bottom: 50px;">

    <!-- Inicio. Form -->
    <form [formGroup]="formInfoHosp" class="flex-container wrap" style="border: none !important">


      <!-- Inicio. Motivo hospitalización / selector cuando es cirugía -->
      <div class="row w-100">

        <!-- Inicio. Motivo de hospitalización -->
        <div class="col-xl-6 col-lg-6 col-md-12 p-2">
          <mat-form-field class="custom-select-material w-100" floatLabel="always" [ngClass]="{'input-error-border': isMotivoError}" >
            <mat-select [placeholder]="labelMotivo" id="idmotivo1" formControlName="motivoDescrip"
            (selectionChange)="onChangeMotivo($event.value)"><!--  -->
              <mat-option *ngFor="let motivo of listaMotivos" [value]="motivo" [selected]="formInfoHosp.value.motivoDescrip">
                {{ motivo }}
              </mat-option>
            </mat-select>
            <mat-hint *ngIf="isMotivoError" class="error-text">
              Recuerda Ingresar el Motivo.
            </mat-hint>
          </mat-form-field>
        </div>
        <!-- Inicio. Motivo de hospitalización -->

        <!-- Inicio. Selector cuando motivo es cirugía -->
        <div class="col-xl-6 col-lg-6 col-md-12 p-2" *ngIf="(flagDeclaraAcc && selectedMotivo=='Cirugía')">
          <mat-form-field class="custom-select-material w-100" floatLabel="always"
            [ngClass]="{'input-error-border': isTraumaError}">
            <mat-select [placeholder]="labelTraumatologica" formControlName="cirugiaTraumatologica"
              (selectionChange)="onChangeTraumatologica($event.value, 'cirugiaTraumatologica')">
              <mat-option [value]="true" [selected]="formInfoHosp.value.cirugiaTraumatologica">Sí
              </mat-option>
              <mat-option [value]="false" [selected]="!formInfoHosp.value.cirugiaTraumatologica">No
              </mat-option>
            </mat-select>
          </mat-form-field>
          <mat-hint *ngIf="(flagDeclaraAcc && selectedMotivo=='Cirugía') && isTraumaError"
            class="error-text">
            Selecciona una opción según tu tipo de cirugía.
          </mat-hint>
        </div>
        <!-- Fin. Selector cuando motivo es cirugía -->

      </div>
      <!-- Fin. Motivo hospitalización / selector cuando es cirugía -->


      <!-- Inicio. Mensaje y descarga documento cuando cirugía es traumatológica  -->
      <div class="row w-100" *ngIf="formInfoHosp.value.cirugiaTraumatologica">
        <div class="col-xl-12 col-lg-12 col-md-12 p-2">
          <cas-alert style="width: 100%!important;" size="small" text="{{this.msjeDescargaDeclaracion}}"></cas-alert>
        </div>
        <div class="col-xl-12 col-lg-12 col-md-12 p-2" style="display: inline-flex; justify-content: center; align-items: center;">
          <cas-text variant="link" (click)="onDescargaDeclaracion()" style="cursor: pointer;" color="" href="#">Declaración de accidente isapre&nbsp;&nbsp;</cas-text>
          <cas-icon variant="flat" (click)="onDescargaDeclaracion()" style="cursor: pointer;" name="flat-download" size="16" ></cas-icon>
        </div>
      </div>
      <!-- Fin. Mensaje y descarga documento cuando cirugía es traumatológica  -->


      <!-- Inicio. Sucursal hospitalización / procedimiento cirugía  -->
      <div class="row w-100" *ngIf="(selectedMotivo === 'Parto' || selectedMotivo === 'Cirugía') && preSetPabellon">

        <!-- Inicio. Sucursal hospitaliación -->
        <div class="col-xl-6 col-lg-6 col-md-12 p-2">
          <cas-select class="" formControlName="sucursalHospitalizacion"
              [label]="labelSucursal" [data]="listaSucursales"
              value="{{this.formInfoHosp.value.sucursalHospitalizacion}}"
              (change)="changeSelect($event, 'sucursalHospitalizacion')" fullWidth disabled>
          </cas-select>
        </div>
        <!-- Fin. Sucursal hospitaliación -->

        <!-- Inicio. Procedimiento cirugía -->
        <div class="col-xl-6 col-lg-6 col-md-12 p-2">
          <cas-input  class=""
            formControlName="procedimientoCirugia"
            label="{{labelProcedimiento}}" value="{{this.formInfoHosp.value.procedimientoCirugia}}"
            (change)="changeSelect($event, 'procedimientoCirugia')"
            [placeholder]="labelProcedimiento" fullWidth [attr.disabled]="preSetPabellonStr">
          </cas-input>
        </div>
        <!-- Fin. Procedimiento cirugía -->

      </div>
      <!-- Inicio. Sucursal hospitalización / procedimiento cirugía  -->


      <!-- Inicio. Medico tratante / fecha de ingreso -->
      <div class="row w-100">

        <!-- Inicio. Médico tratante -->
        <div class="col-xl-6 col-lg-6 col-md-6 p-2">

          <mat-form-field class="w-100" appearance="fill" floatLabel="always" 
              [ngClass]="{'estilo-gris': (selectedMotivo === 'Parto' || selectedMotivo === 'Cirugía') && preSetPabellon}"
              style="padding-top: 18px !important; padding-left:12px !important; border: 1px solid #bcc2c6; border-radius: 9px; height: 56px; overflow: hidden;">
            
            <mat-label [ngClass]="{'estilo-gris': (selectedMotivo === 'Parto' || selectedMotivo === 'Cirugía') && preSetPabellon}">Médico Tratante</mat-label>
            
            <input type="text" matInput [formControl]="medicoControl" [matAutocomplete]="auto" [disabled]="(selectedMotivo === 'Parto' || selectedMotivo === 'Cirugía') && preSetPabellon">
            
            <mat-autocomplete #auto="matAutocomplete"  [disabled]="(selectedMotivo === 'Parto' || selectedMotivo === 'Cirugía') && preSetPabellon"
                (keydown)="(selectedMotivo === 'Parto' || selectedMotivo === 'Cirugía') && preSetPabellon ? $event.preventDefault() : null"
                [displayWith]="displayMedico" (optionSelected)="onMedicoSelected($event.option.value)" (click)="this.indCargaMedico = false;">
              <mat-option *ngFor="let medico of filteredMedicos | async" [value]="medico">
                {{medico?.cv?.informacionPersonal?.primerNombre | titlecase }}&nbsp;
                {{medico?.cv?.informacionPersonal?.segundoNombre | titlecase }}&nbsp;
                {{medico?.cv?.informacionPersonal?.apellidoPaterno | titlecase }}&nbsp;
                {{medico?.cv?.informacionPersonal?.apellidoMaterno | titlecase }}
              </mat-option>
            </mat-autocomplete>
            
            <mat-progress-bar mode="indeterminate" *ngIf="indCargaMedico && !((selectedMotivo === 'Parto' || selectedMotivo === 'Cirugía') && preSetPabellon)" style="width: 90%;"></mat-progress-bar>
          </mat-form-field>

          <div *ngIf="isMedicoError && !noResultsFound && this.medicoControl.enabled" class="error-text">
            Recuerda buscar y seleccionar un médico.
          </div>
          <div *ngIf="noResultsFound && this.medicoControl.enabled" class="error-text">
            No se encontró Médico.
          </div>
        </div>

        <!-- <div class="col-xl-6 col-lg-6 col-md-6 p-2" *ngIf="(selectedMotivo === 'Parto' || selectedMotivo === 'Cirugía') && preSetPabellon">
          <cas-input class=""
            formControlName="medicoTratanteNombre" fullWidth
            label="Medico tratante" value="{{this.formInfoHosp.value.medicoTratanteNombre}}"
            [attr.disabled]="preSetPabellonStr">
          </cas-input>
        </div> -->
        <!-- Fin. Médico tratante -->


        <!-- Inicio. Fecha de ingreso -->
        <div class="col-xl-6 col-lg-6 col-md-6 p-2" *ngIf="(selectedMotivo !== 'Parto' && selectedMotivo !== 'Cirugía') || ((selectedMotivo === 'Parto'|| selectedMotivo === 'Cirugía') && !preSetPabellon)">

          <mat-form-field class="w-100" appearance="fill" floatLabel="always" style="border: 1px solid #bcc2c6; height: 56px; border-radius: 9px; padding-top: 12px !important;">
            <mat-label style="margin-left: 12px !important;">Fecha de Ingreso</mat-label>
            <input matInput [matDatepicker]="picker" formControlName="fechaIngreso" [(ngModel)]="formInfoHosp.value.fechaIngreso" style="margin-left: 14px;" (dateInput)="onDateInput($event)" onkeydown="return false">
            <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
            <mat-datepicker #picker></mat-datepicker>
          </mat-form-field>
          <div *ngIf="formInfoHosp.get('fechaIngreso').errors?.invalidDate || isFechaIngresoError" class="error-text">
            Formato de fecha incorrecto. Usa dd-mm-aaaa.
          </div>
        </div>

      </div>
      <!-- Inicio. Medico tratante / fecha de ingreso -->


      <div class="row w-100">

        <!-- Inicio. Fecha de pabellon -->
        <div class="col-xl-6 col-lg-6 col-md-6 p-2" *ngIf="(selectedMotivo === 'Parto' || selectedMotivo === 'Cirugía') && preSetPabellon">

          <mat-form-field class="w-100" appearance="fill" floatLabel="always"
                          style="border: 1px solid #bcc2c6; height: 56px; border-radius: 9px; padding-top: 14px !important;"
                          [ngClass]="{'estilo-gris': preSetPabellonStr}">
            <mat-label style="margin-left: 12px !important;" [ngClass]="{'estilo-gris': preSetPabellonStr}">Fecha de Pabell&oacute;n</mat-label>
            <input matInput [matDatepicker]="pickerPabellon" formControlName="fechaPabellon"
                   [(ngModel)]="formInfoHosp.value.fechaPabellon"
                   style="margin-left: 14px; color: rgb(54, 72, 85)"
                   (dateInput)="onDateInput($event)"
                   onkeydown="return false"
                   [disabled]="preSetPabellonStr">
            <mat-datepicker-toggle matSuffix [for]="pickerPabellon" [ngClass]="{'no-pointer-events': preSetPabellonStr}"></mat-datepicker-toggle>
            <mat-datepicker #pickerPabellon></mat-datepicker>
          </mat-form-field>
          <div *ngIf="formInfoHosp.get('fechaPabellon').errors?.invalidDate || isFechaIngresoError" class="error-text">
            Formato de fecha incorrecto. Usa dd-mm-aaaa.
          </div>

        </div>
        <!-- Fin. Fecha de pabellon -->


        <!-- Inicio. Hora de pabellon -->
        <div class="col-xl-6 col-lg-6 col-md-6 p-2" *ngIf="(selectedMotivo === 'Parto' || selectedMotivo === 'Cirugía') && preSetPabellon">
          <cas-input
          style="font-size: 16px; text-align: center;"
          class="" icon="flat-clock" iconSize="20" alignment="trailing"
          label="{{labelHoraPab}}" formControl="horaPabellon"
          value="{{this.formInfoHosp.value.horaPabellon}}"
          [(ngModel)]="hora"
          (input)="formatoHora($event)"
          (change)="changeSelect($event, 'horaPabellon')" [attr.disabled]="preSetPabellonStr" fullWidth>
          </cas-input>
        </div>
        <!-- Fin. Hora de pabellon -->

      </div>


      <!-- Inicio. Mensaje informativo -->
      <div class="row w-100" *ngIf="(selectedMotivo === 'Parto' || selectedMotivo === 'Cirugía') && preSetPabellon">
        <div class="col-xl-12 col-lg-12 col-md-12 p-2">
          <cas-alert class="" style="width: 100%!important;" size="small"
          text="Es muy importante que llegues 2 horas antes de tu procedimiento.">
        </cas-alert>
        </div>
      </div>
      <!-- Fin. Mensaje informativo -->


      <!-- Inicio. Botonera -->
      <div class="botonera d-flex flex-wrap gap-2 w-100 my-4">
        <cas-button text="Volver" variant="text1" (click)="volver()" class="pe-1"></cas-button>
        <cas-button text="Continuar" variant="primary1" (click)="submitAntecedentes()"></cas-button>
      </div>
      <!-- Fin. Botonera -->

    </form>
    <!-- Fin. Form -->




  </div>
</div>
