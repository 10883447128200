import { Component, OnInit,Input, } from '@angular/core';

@Component({
  selector: 'app-radio-ds',
  templateUrl: './radio-ds.component.html',
  styleUrls: ['./radio-ds.component.scss']
})
export class RadioDsComponent implements OnInit {

  @Input () disabled: boolean = true;
  @Input () isDisabled: boolean = false;
  @Input () checked: boolean = true;
  @Input () isChecked: boolean = false;
  @Input () size: string="";
  @Input () label: string = "Default";
  @Input () name: string = "Name";
  @Input () radioCategory: string = "";
  @Input () idDs: string = "";


 radioNormal:boolean =true;
 radioDisabled:boolean=true;
 radioCkecked:boolean=true;
 radioCheckDisable:boolean=true
  

  constructor() { }

  ngOnInit(): void {
    
    if (this.radioCategory === 'normal'){
      this.radioDisabled = false;
      this.radioCkecked = false;
      this.radioNormal = true;
    }
    if (this.radioCategory === 'disabledRadio'){
      this.radioNormal = false;
      this.radioCkecked = false;
    }
    if (this.radioCategory=== 'checkedRadio'){
      this.radioNormal = false;
      this.radioDisabled = false;
      this.radioCkecked = true;
  }
 
}

}


