export async function descargaPdf(base64Data, nombre) {
    // Decodificar el base64 en binario y crear un blob
    const byteCharacters = atob(base64Data.split(',')[1]);
    const byteNumbers = Array.from(byteCharacters, char => char.charCodeAt(0));
    const byteArray = new Uint8Array(byteNumbers);
    const blob = new Blob([byteArray], { type: 'application/pdf' });

    // Crear un enlace temporal para abrir en una nueva pestaña
    const url = window.URL.createObjectURL(blob);

    // Abrir el archivo en una nueva pestaña
    window.open(url, '_blank');

    // Liberar la URL del blob después de abrirla
    window.URL.revokeObjectURL(url);
}
