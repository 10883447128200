import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {sourceModel} from "../../../model/DS/designSystemModel/source.model";

@Component({
  selector: 'app-select-ds',
  templateUrl: './select-ds.component.html',
  styleUrls: ['./select-ds.component.scss']
})
export class SelectDsComponent implements OnInit {

  @Input() size: string = "regular";
  @Input() label: string = "Label";
  @Input() variant: string = "";
  @Input() disabled: any = false;
  @Input() state: string = "none";
  @Input() message: string = "";
  @Input() messageValid: string = "";
  @Input() messageInvalid: string = "";
  @Input() source: Array<sourceModel> = [];
  @Input () idDs: string = "";
  @Input () name: string = "";

  @Output () onChange: EventEmitter<any> = new EventEmitter();

  constructor() { }

  ngOnInit(): void {
  }

  changeOption(event: Event) {
    // @ts-ignore
    this.onChange.emit(event.target.value);
  }

}
