

  export class Acompanante {
  documentoId: number;
  documentoNumero: string;
  apellidoPaterno: string;
  apellidoMaterno: string;
  nombres: string;
  telefonoContacto: string;
  direccion: string;
  ciudad : string;
  codigoComuna: number;
  nombreComuna: string;
  codigoPais: number;
  nombrePais: string;
  relacionPaciente: string;
  email: string;

    constructor() {
        this.documentoId =  0;
        this.documentoNumero= "";
        this.apellidoPaterno= "";
        this.apellidoMaterno= "";
        this.nombres= "";
        this.telefonoContacto="";
        this.direccion = "";
        this.ciudad = "";
        this.codigoComuna= 0;
        this.nombreComuna = "";
        this.codigoPais = 0;
        this.nombrePais =  "";
        this.relacionPaciente = "";
      }

}