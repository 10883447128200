export function formatoFechaPabellon(d) {
    let day = d.getDate();
    let dia = day.toString();
    if (day < 10) {
        dia = '0' + dia;
    }
    let month = d.getMonth() + 1;
    let mes = month.toString();
    if (month < 10) {
        mes = '0' + mes;
    }
    let year = d.getFullYear();
    return year + '-' + mes + '-' + dia;
}
export function formatoCampoFechaPabellon(d) {
    let day = d.getDate();
    let dia = day.toString();
    if (day < 10) {
        dia = '0' + dia;
    }
    let month = d.getMonth() + 1;
    let mes = month.toString();
    if (month < 10) {
        mes = '0' + mes;
    }
    let year = d.getFullYear();
    return dia + '-' + mes + '-' + year;
}

export function formatoFechaPrmGenerales(n): Date {
    const fechaActual = new Date();
    fechaActual.setDate(fechaActual.getDate() + n);
    return fechaActual;
}