export class Comuna {
    id: number;
    nombre: string;

    //Inicializa una instancia de la clase TextoLibre
    constructor() {
        this.id = 0;
        this.nombre = "";
      }

}