
  
  <!-- Forma 2 -->
  <div *ngIf= "radioDisabled">
    <div class="form-check">
     <input class="form-check-input radio-alemana" type="radio" [name]="name" [id]="idDs"  [disabled]="disabled"  [ngClass]="{'size-3': size === 's3', 'size-2': size === 's2', 'size-1': size === 's1'}">
     <label class="form-check-label" [for]="idDs">
      <!-- Este es un radio disabled ---> 
      {{label}}
     </label>
     <script>
       var radio = document.getElementById("flexRadioDefault1");
       radio.indeterminate = true
    </script>
   </div>  
 </div>
 
 <div *ngIf= "radioCkecked">
   <div class="form-check">
     <input class="form-check-input radio-alemana" type="radio" [name]="name" [id]="idDs"   [checked]="checked" [disabled]="isDisabled" [ngClass]="{'size-3': size === 's3', 'size-2': size === 's2', 'size-1': size === 's1'}">
     <label class="form-check-label" [for]="idDs">
       {{label}}
     </label>
     
   </div>
 </div>  
 
 
 
 <div *ngIf= "radioNormal">
   <div class="form-check">
     <input class="form-check-input radio-alemana" type="radio" [name]="name" [id]="idDs" [checked]="isChecked" [disabled]="isDisabled" [ngClass]="{'size-3': size === 's3', 'size-2': size === 's2', 'size-1': size === 's1'}">
     <label class="form-check-label" [for]="idDs">
      {{label}}
     </label>
     
   </div>  
 </div> 