<div *ngIf="s1">
    <div class="container custom-container-spinner">
        <div class="spinner-border custom-spinner" role="status" [ngClass]="{'text-dark': color === 'dark','custom-green': color === 'green'}">
            <span class="visually-hidden">Loading...</span>
        </div>
        <div class="loading-text">
            <p>{{msg}}</p>
        </div>
    </div>
</div>

<div *ngIf="s2">
    <div class="container custom-bigcontainer-spinner">
        <div class="spinner-border custom-bigspinner" role="status" [ngClass]="{'text-dark': color === 'dark'}">
            <span class="visually-hidden">Loading...</span>
        </div>
        <div class="loading-bigtext">
            <p>{{msg}}</p>
        </div>
    </div>
</div>
