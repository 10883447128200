import { AfterViewInit, ChangeDetectorRef, Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { Observable, of, Subject } from 'rxjs';
import { catchError, debounceTime, distinctUntilChanged, filter, map, startWith, switchMap, tap } from 'rxjs/operators';
import { SharedAutoAdmisionService } from '../../../servicios/autoAdmision/shared-auto-admision.service';
import { ProfesionalesService } from '../../../servicios/profesionales/profesionales.service';
import { CONSTANTES, CONST_AUTOADM, DEC_ACCIDENTE_ISAPRE } from '../../../const';
import { AgendaPabellonService } from '../../../servicios/agendaPabellon/agendaPabellon.service';
import { ValidacionesService } from '../../../servicios/autoAdmision/utils/validaciones-autoADM.service';
import { MatSnackBar } from '@angular/material/snack-bar';

import { formatoFechaPabellon, formatoFechaPrmGenerales } from '../../../utilitarios/transformDates.util';
import { LoaderService } from '../../../servicios/loader/loader.service';
import moment from 'moment';
import { AntecedenteHosp } from '../../../model/antecedentesHosp.model';
import { CheckinModel } from '../../../model/checkinModel.model';
import { CuentaCorrienteService } from '../../../servicios/cuentaCorriente/cuenta-corriente.service';
import { AdmisionService } from '../../../servicios/admision/admision.services';
import { ApiDocService } from '../../../servicios/apiDoc/apiDoc.service';
import { descargaPdf } from '../../../utilitarios/descargar';
import { AutoAdmisionService } from '../../../servicios/auto-admision/auto-admision.service';
import { formatPabellon } from '@app/utilitarios/formatPabellon';
import { environment } from "src/environments/environment";
import { Medico } from '@app/utilitarios/medico.interface';

@Component({
  selector: 'app-paso2-auto-admision',
  templateUrl: './paso2-auto-admision.component.html',
  styleUrls: ['./paso2-auto-admision.component.css']
})
export class Paso2AutoAdmisionComponent implements OnInit {

  @ViewChild('motivoDesc') motivoDesc: ElementRef;
  @ViewChild('suclHosp') sucHosp: ElementRef;

  formInfoHosp!: FormGroup;
  formEnviado: boolean = false;

  listaMotivos: any[] = [];
  labelMotivo: string = 'Motivo de Hospitalización';
  labelTraumatologica: string = '¿Tu cirugía es traumatológica?';

  listaObjSucursales =  this.sharedService.allSucursales;
  listaSucursales = this.sharedService.sucursalesString;
  sucursalHosp = this.sharedService.sucHospInicial;

  labelSucursal: string = 'Sucursal de Hospitalización';

  loading: boolean = true;

  trueFalse: boolean[] = [true, false]
  listaMock: string[] = ["Option 1", "juan", "Option 3", "parto", "cirugia"]
  msjSolicitudVacia = '';

  /** variables de buscador de médicos */
  dataSourceMedicos: any[] = [];
  medicoControl = new FormControl();
  filteredMedicos: Observable<Medico[]>;
  private keyupSubject = new Subject<KeyboardEvent>();
  private isValueChangesActive = true; // Flag to control valueChanges activation
  indCargaMedico: boolean = false;
  noResultsFound: boolean;
  /** */
  
  responsePabellon: any = null;
  preSetPabellon: boolean = false;
  preSetPabellonStr: string = 'false';
  tengoPabellon: boolean = false;


  labelDiagnostico: string = 'Diagnóstico';
  labelProcedimiento: string = 'Procedimiento';
  labelFechaPab: string = 'Fecha de Pabellón';
  labelHoraPab : string = 'Hora de Pabellón';
  labelFechaIng: string = 'Fecha de Ingreso';

  prmPabellon = this.sharedService.allParams?.filter(elemt => elemt.categoria === 'pabellon')[0];
  msjeDescargaDeclaracion : string = this.sharedService.msjeDescargaDeclaracion.valor?? 'Descarga la declaración, complétala y entrégala el día de tu atención.';

  selectedMotivo: string = this.sharedService.checkinSelected.motivoValor;
  sourceMotivo = [];
  hora: string = '';
  tituloPaso2 = CONST_AUTOADM.TITULO_PASO2;
  mensajeGeneral: string = '';
  durationInSeconds = 5;
  isMotivoError: boolean = false;
  isMedicoError: boolean = false;
  isFechaIngresoError: boolean = false;
  isConfirm: boolean = false;

  nombrePaciente = '';
  cuentaCorrieteAbierta = false;
  flagDeclaraAcc: boolean = false;
  isTraumaError: boolean = false;
  
  constructor(
    private fb: FormBuilder,
    private apiDocService: ApiDocService,
    private agendaPabellonService: AgendaPabellonService,
    private profesionalesService: ProfesionalesService,
    public sharedService: SharedAutoAdmisionService,
    private loadingService: LoaderService,
    private validacionesServices: ValidacionesService,
    private router: Router,
    private cuentaCorriente: CuentaCorrienteService,
    private admisionService: AdmisionService,
    private autoAdmisionService: AutoAdmisionService,
    private cdr: ChangeDetectorRef,
    public snackBar: MatSnackBar)
    {
    }

  ngOnInit() {
    /*llamar a esta linea donde requiera stepper */
    this.sharedService.setStepper(2);
    this.cargarInformacion();
    this.loadingService.setLoading(true);
    // console.log('this.sharedService.checkinSelected',this.sharedService.checkinSelected)

    this.filteredMedicos = this.medicoControl.valueChanges.pipe(
      debounceTime(100),
      distinctUntilChanged(),
      switchMap(value => {
        console.log(typeof value);
        if (!value || value.length < 3) {
          this.clearMedico();
          this.indCargaMedico = false;
          this.noResultsFound = false;
          return of([]); // Return an empty array if the input is empty or less than 3 characters
        }
        else if(typeof value != 'string'){
          this.indCargaMedico = false;
          return of([]);
        }
        else{
          this.indCargaMedico = true;
          return this.autoAdmisionService.profesionalesServices(value).pipe(
            catchError(error => {
              this.indCargaMedico = false;
              console.error('Error al obtener médicos:', error);
              return of([]); // Devuelve un observable vacío en caso de error
            }),
            tap((medicos) => {
              this.indCargaMedico = false;
              this.noResultsFound = medicos.length === 0;
            })
          );
        }
        
      })
    );

  }

    
  async cargarInformacion() {
    // Si el genero es 1, filtrar la lista de motivos para quitar "Parto"
    if (this.sharedService.checkinSelected.codigoGenero == 1) {
        this.listaMotivos = this.sharedService.motivos
            .filter(motivo => motivo.valor !== "Parto")
            .map(motivo => motivo.valor);
    } else {
        this.listaMotivos = this.sharedService.motivos.map(motivo => motivo.valor);
    }

    this.selectedMotivo = this.sharedService.checkinSelected.motivoValor ?? '';

    if ((this.sharedService.checkinSelected.motivoValor == 'Cirugía' && this.sharedService.checkinSelected.codigoPrevision != 0) ||
        this.sharedService.checkinSelected.codigoPrevision != null) {
        this.isapreDeclaraAccidente(this.sharedService.checkinSelected.codigoPrevision);
    }



    await this.obtenerPabellones();


}


  showSnackbar(message: string) {
    this.snackBar.open(message, 'Cerrar', {
      duration: this.durationInSeconds * 1000,
      horizontalPosition: 'end',
      politeness: 'polite',
    });
  }

  formatMotivos(){
    this.listaMotivos= this.sharedService.motivos.map((e)=>{return e.valor});
  }


  submitAntecedentes() {
    this.loadingService.setLoading(true);
    const idAdmision = this.sharedService.checkinSelected.id;

    /** validar si están los campos obligatorios */
    const validationErrors = this.validacionesServices.validarDatosIngreso(this.formInfoHosp.value, this.preSetPabellon);
    this.mensajeGeneral = validationErrors.mensajeGeneral
    if(this.preSetPabellon && (this.formInfoHosp.value.motivoDescrip=='Cirugía' || this.formInfoHosp.value.motivoDescrip=='Parto')){
      this.isMotivoError = !this.formInfoHosp.value.motivoDescrip;
      this.isConfirm = this.isMotivoError

    } else {
      this.isTraumaError = this.formInfoHosp.value.motivoDescrip =='Cirugía' && this.formInfoHosp.value.cirugiaTraumatologica==null;
      this.isMotivoError = !this.formInfoHosp.value.motivoDescrip;
      this.isMedicoError = !this.formInfoHosp.value.medicoTratantePpn || !this.medicoControl.value;
      this.isFechaIngresoError = !this.formInfoHosp.value.fechaIngreso;
      this.isConfirm = this.isMotivoError|| this.isMedicoError || this.isFechaIngresoError
    }

    if (this.isConfirm) {
      //this.showSnackbar(this.mensajeGeneral);
      this.loadingService.setLoading(false);
    }
    else {
      //console.log("Llegando 3")
      /** transformar objsToSave */
      const body = this.formatObjsToSave();
      //console.log('body', body);
      const guardarAdmision = async () => {
        try {
          if (this.preSetPabellon && (this.selectedMotivo === 'Cirugía' || this.selectedMotivo === 'Parto')) {
            console.log('this.hash', this.sharedService.ppnHash);
            const formattedPabellon = formatPabellon(this.responsePabellon?.pabellonDisponible, this.sharedService.ppnHash);
            /** guardar pabellón */
            await this.autoAdmisionService.actualizarOCrearPabellon(this.sharedService.checkinSelected.id, formattedPabellon).toPromise();
            console.log("pabellón actualizado");
          }

          /** actualizar auto admisión */
          await this.autoAdmisionService.actualizarAutoadmsion(idAdmision.toString(), body).toPromise();
          this.formEnviado = true;
          console.log("actualizar", body);
          this.showSnackbar(CONST_AUTOADM.MSJ_UPDATE_INFO);
          this.loadingService.setLoading(false);
          this.loading = false;
          this.router.navigate(['autoAdmision/paso3']);
        } catch (error) {
          this.loadingService.setLoading(false);
          console.error(error);
          this.showSnackbar(CONSTANTES.MSJ_TITULO_ERROR);
          this.loading = false;
        }
      };

      guardarAdmision();
    }
  }

  buscarNombrePorId(sucursalId: number): string | undefined {
    const sucursalEncontrada = this.listaObjSucursales.find(objeto => objeto.sucursalId === sucursalId);
    //console.log('sucursalEncontrada', sucursalEncontrada)
    return sucursalEncontrada?.nombreSucursal;
}

  formatObjsToSave(): any {
    //console.log("Llgeando al format")
    let fechaNow = moment(new Date()).format('yyyy-MM-DD');
    let horaNow = moment(new Date()).format('HH:mm');
    let protoInformacionHosp = {...this.formInfoHosp.value};
    let protoAntecenteHosp = new AntecedenteHosp() ;
    let protoCheckin: CheckinModel;

    /** creo los elementos parametrizados */
    this.sharedService.checkinSelected.sucursalId = (this.preSetPabellon && (this.selectedMotivo === 'Cirugía' || this.selectedMotivo === 'Parto'))
    ? this.responsePabellon?.pabellonDisponible?.idSucursal
    : this.sharedService.checkinSelected.sucursalId;

    const idSucursal = this.sharedService.checkinSelected.sucursalId;
    let nameSucNueva = this.buscarNombrePorId(idSucursal)
    //console.log('nameSucNueva', nameSucNueva)
    let nameSucAux = protoInformacionHosp.sucursalHospitalizacion;
    let sucObjAux = this.listaObjSucursales.find(e => e.nombreSucursal === nameSucAux || e.nombreSucursal === nameSucNueva);
    let motObj = this.sharedService.obtenerParametroPorCat('motivo','valor', protoInformacionHosp.motivoDescrip);

    //console.log(motObj);

    /** traspaso la información de los objetos param a los objs del service */
    this.sharedService.checkinSelected.sucursalHospId =  (this.preSetPabellon && (this.selectedMotivo === 'Cirugía' || this.selectedMotivo === 'Parto'))
    ? this.responsePabellon?.pabellonDisponible?.idSucursal 
    : sucObjAux.id;

    this.sharedService.checkinSelected.motivoValor = protoInformacionHosp.motivoDescrip;
    //console.log('protoInformacionHosp.motivoDescri', protoInformacionHosp)


    if(this.sharedService.checkinSelected.id == 0 || !this.sharedService.checkinSelected.antecedentesHospitalizacion){
      //let nuevoAntecedente = new AntecedenteHosp();
      //nuevoAntecedente.motivoCodigo = motObj.llave;
      protoInformacionHosp.motivoCodigo = motObj.llave;
      //console.log('protoInformacionHosp',protoInformacionHosp )
    }else{
      this.sharedService.checkinSelected.antecedentesHospitalizacion.motivoCodigo = motObj.llave;
      this.sharedService.antecedenteHosp.motivoCodigo = motObj.llave;
      protoInformacionHosp.modalidad = this.responsePabellon?.pabellonDisponible?.codModalidad.toString() ?? null;
    }
    /** revisar formatos de fecha pabellón e ingreso */
    let fechaIngresoRevisada = protoInformacionHosp.fechaIngreso.includes('Z')
                              ? this.formatDate(protoInformacionHosp.fechaIngreso)
                              : (protoInformacionHosp.fechaIngreso == '') ? fechaNow : protoInformacionHosp.fechaIngreso;

    let fechaPabellonRevisada = protoInformacionHosp.fechaPabellon.includes('Z')
      ? this.formatDate(protoInformacionHosp.fechaPabellon)
      : (protoInformacionHosp.fechaPabellon == '') ? fechaNow :  protoInformacionHosp.fechaPabellon;

    let horaIngresoRevisada = protoInformacionHosp.horaIngreso.includes('Z')
      ? this.formatDate(protoInformacionHosp.horaIngreso)
      : (protoInformacionHosp.horaIngreso == '') ? horaNow : protoInformacionHosp.horaIngreso ;

    let horaPabellonRevisada = protoInformacionHosp.horaPabellon.includes('Z')
      ? this.formatDate(protoInformacionHosp.horaPabellon)
      : (protoInformacionHosp.horaPabellon == '') ? horaNow : protoInformacionHosp.horaPabellon;

    /**borro las cosas del form que me sobran para los dto */
    delete protoInformacionHosp.sucursalHospitalizacion;
    delete protoInformacionHosp.motivoDescrip;
    //delete  this.sharedService.checkinSelected.fechaIngresoText

    /** traspaso la información final a los objs del body */

    protoAntecenteHosp = {...protoInformacionHosp};
    protoAntecenteHosp.fechaIngreso = fechaIngresoRevisada;
    protoAntecenteHosp.fechaPabellon = fechaPabellonRevisada;
    protoAntecenteHosp.horaIngreso = horaIngresoRevisada;
    protoAntecenteHosp.horaPabellon = horaPabellonRevisada;
    //protoAntecenteHosp.modalidad = null;
    protoAntecenteHosp.motivoCodigo = motObj.llave;
    protoAntecenteHosp.origenProcedencia = 'Admisión';

    this.sharedService.checkinSelected.antecedentesHospitalizacion = {...protoAntecenteHosp};

    this.sharedService.checkinSelected.antecedentesHospitalizacion.fechaIngreso = protoAntecenteHosp.fechaIngreso;
    this.sharedService.checkinSelected.antecedentesHospitalizacion.horaIngreso = protoAntecenteHosp.horaIngreso;

    this.sharedService.checkinSelected.antecedentesHospitalizacion.fechaPabellon = protoAntecenteHosp.fechaPabellon;
    this.sharedService.checkinSelected.antecedentesHospitalizacion.horaPabellon = protoAntecenteHosp.horaPabellon;

    protoCheckin = { ...this.sharedService.checkinSelected };

    return { admision: protoCheckin, hosp: protoAntecenteHosp }

  }

  compareCir(ci1: any, ci2: any) {
    return ci1 && ci2 ? ci1.cirugiaTraumatologica === ci2.cirugiaTraumatologica : ci1 === ci2;
  }

  onChangeTraumatologica(value: any) {
    //this.tieneSeguro = value;
    //console.log('******value', value);
    this.formInfoHosp.get('cirugiaTraumatologica').setValue(value);
    this.formInfoHosp.get('cirugiaTraumatologica').updateValueAndValidity();
    this.isTraumaError = value;
    this.isTraumaError = false;
  }

  onChangeMotivo(value: any) {
    console.log('>>>>>>>>>>>changeMotivo<<<<<<<<<<<<')
    console.log('value', value)
    //this.tieneSeguro = value;
    //console.log('******value', value, this.sharedService.checkinSelected, this.formInfoHosp);
    this.selectedMotivo = value;
    this.formInfoHosp.get('motivoDescrip').setValue(value);
    this.formInfoHosp.get('motivoDescrip').updateValueAndValidity();
    this.isapreDeclaraAccidente(this.sharedService.checkinSelected.codigoPrevision);

    this.isMotivoError = value;
    this.isMotivoError = false;

    const medicoTratantePpnControl = this.formInfoHosp.get('medicoTratantePpn');
    if (medicoTratantePpnControl) {
      this.clearMedico();
      this.medicoControl.setValue('', { emitEvent: false });
    }

    if ((value === 'Cirugía' || value === 'Parto') && this.preSetPabellon) {
      this.inicializarForm('pabellon');
    }
    else if((value !== 'Cirugía' || value !== 'Parto') && value === this.sharedService.checkinSelected.motivoValor){
      this.inicializarForm('checkin');
      this.medicoControl.enable();
    }
    else {
      this.inicializarForm('no');
      if (this.selectedMotivo != 'Cirugía') {
        this.formInfoHosp.get('cirugiaTraumatologica').setValue(null);
        this.formInfoHosp.get('cirugiaTraumatologica').clearValidators();
      }
      else {
        this.formInfoHosp.get('cirugiaTraumatologica').setValidators(Validators.required);
      }
      this.formInfoHosp.get('cirugiaTraumatologica').updateValueAndValidity();
      //this.inicializarForm('');
    }
    //this.labelMotivo = valor;
  }


  changeSelect(ev: any, controlName: string) {
    console.log('>>>>>>>>> changeSelect <<<<<<<<<')
    console.log('ev', ev);
    console.log('controlName', controlName);
    let valor = ev.value;
    switch (controlName) {
      case 'fechaIngreso':
      /* se setea el objeto fecha ingreso que se carga en el front, formato dd-mm-yyyy */ 
      this.formInfoHosp.get('fechaIngreso').setValue(valor);
      /* se setea el objeto fecha ingreso que se envia al back, formato yyyy-mm-dd */ 
      this.formInfoHosp.controls['fechaIngreso'].setValue(this.formatDate(valor));
          break;
      // case 'medicoTratanteNombre':
      //   this.formInfoHosp.get('medicoTratantePpn').setValue(0);
      //   this.formInfoHosp.get('medicoTratanteRut').setValue('');
      //   if (!valor || valor==='' || valor?.length<3){
      //     this.formInfoHosp.get('medicoTratanteNombre').setValue('');
      //   }
      //   //  console.log('******event', ev.target.label, ev.target.value, controlName);
      //   this.isMedicoError = valor;
      //   this.isMedicoError = false;
      //   break;
    }
}

onMedicoSelected(medico: Medico) {
  this.indCargaMedico = false;
  console.log({medico});
  if (medico) {
    this.medicoControl.setValue(this.formatMedico(medico), { emitEvent: false });
    this.formInfoHosp.patchValue({
      medicoTratanteNombre: this.formatMedico(medico),
      medicoTratantePpn: medico.ppn ?? 0,
      medicoTratanteRut: medico.cv.informacionPersonal.rutPasaporte ?? ''
    });
    this.isMedicoError = false;
  }
  else{
    this.clearMedico();
  }
  this.isMedicoError = (this.formInfoHosp.value.medicoTratantePpn || this.medicoControl.value=='')? false: true;
  console.log('this.formInfoHosp.value...', this.formInfoHosp.value);
  console.log('this.isMedicoError--->', this.isMedicoError);
}

clearMedico(){
  
  this.formInfoHosp.patchValue({
    medicoTratanteNombre: '',
    medicoTratantePpn: 0,
    medicoTratanteRut: ''
  });
  this.indCargaMedico = false;
}

displayMedico(medico: any): string {
  if (typeof medico === 'string') {
    return medico; // Si es un string, simplemente devuélvelo
  }

  if (medico && medico.cv && medico.cv.informacionPersonal) {
    const capitalize = (text: string): string => {
      return text.charAt(0).toUpperCase() + text.slice(1).toLowerCase();
    };

    const primerNombre = medico.cv.informacionPersonal.primerNombre ?? '';
    const segundoNombre = medico.cv.informacionPersonal.segundoNombre ?? '';
    const apellidoPaterno = medico.cv.informacionPersonal.apellidoPaterno ?? '';
    const apellidoMaterno = medico.cv.informacionPersonal.apellidoMaterno ?? '';

    return `${capitalize(primerNombre)} ${capitalize(segundoNombre)} ${capitalize(apellidoPaterno)} ${capitalize(apellidoMaterno)}`.trim();
  }

  return ''; // Devuelve una cadena vacía si no hay información
}

  formatMedico(medico: any): string {
    console.log('<<<<<<<<<<<< formatMedico >>>>>>>>>>>>>')
    this.indCargaMedico = false;

    const capitalize = (text: string): string => {
      return text.charAt(0).toUpperCase() + text.slice(1).toLowerCase();
    };

    if(medico){
      const primerNombre = medico.cv.informacionPersonal.primerNombre ?? '';
      const segundoNombre = medico.cv.informacionPersonal.segundoNombre ?? '';
      const apellidoPaterno = medico.cv.informacionPersonal.apellidoPaterno ?? '';
      const apellidoMaterno = medico.cv.informacionPersonal.apellidoMaterno ?? '';
      console.log('if medico');
      return `${capitalize(primerNombre)} ${capitalize(segundoNombre)} ${capitalize(apellidoPaterno)} ${capitalize(apellidoMaterno)}`.trim();
    }
    else{
      console.log('elsemedico');
      this.medicoControl.setValue(this.formInfoHosp.value.medicoTratanteNombre, { emitEvent: false });
      return this.formInfoHosp.value.medicoTratanteNombre;
    }

    
  }

  ver(){
    console.log(this.formInfoHosp);
    console.log(this.medicoControl);
  }

  formatoHora(event: any){
    const input = event.target as HTMLInputElement;
    let valor = input.value;

    valor = valor.replace(/[^0-9:]/g, '');
    valor = valor.slice(0,5);

    if(valor.length === 2 && !valor.includes(':')){
      valor += ':';
    }

    input.value = valor;
    this.hora = valor;
  }



  obtenerPabellones() {
    let fechaFinTemp = this.prmPabellon?.valor ?? 7;
    const fechaInicio: string = formatoFechaPabellon(new Date()).toString();
    const fechaFin = formatoFechaPabellon(formatoFechaPrmGenerales(fechaFinTemp));
    ////console.log({fechaFin});
    const requestBody = {
      ppn: this.sharedService.ppnHash,
      fechaInicio: fechaInicio,
      fechaFin: fechaFin,
      holding: "2",//TODO: Cambiar este valor a variable
      qrcode: true,
    };
    this.callPabellones(requestBody);
  }

  callPabellones(request: any) {
    console.log('selected motivo al invocar pabellon...', this.selectedMotivo);
    this.autoAdmisionService.obtenerPabellonDisponible(this.sharedService.checkinSelected.id, request)
      .subscribe((resp: any) => {
        if (resp.message !== 'ok') {
          this.loading = false;
          this.loadingService.setLoading(false);
          this.preSetPabellon = false;
          this.preSetPabellonStr = 'false';
          this.showSnackbar(CONST_AUTOADM.MSJ_PABELLON_PASO2);
          this.tengoPabellon = false;
          this.selectedMotivo= (this.sharedService.checkinSelected.motivoValor)? this.sharedService.checkinSelected.motivoValor : '';
          this.inicializarForm('checkin');

        }
        else{
          this.loading = false;
          this.loadingService.setLoading(false);
          this.responsePabellon = resp;
          this.preSetPabellon = true;
          this.preSetPabellonStr = 'true';
          this.tengoPabellon = true;
          //this.showSnackbar('Tienes un pabellón disponible');
          this.selectedMotivo= (this.sharedService.checkinSelected.motivoValor)? this.sharedService.checkinSelected.motivoValor : 'Cirugía';
          
          /** setear sucursal */      
          this.sucursalHosp = this.sharedService.allSucursales.find((e) => e.sucursalId.toString() == this.responsePabellon?.pabellonDisponible?.idSucursal.toString());
          this.sharedService.sucHospInicial = this.sucursalHosp;

          this.inicializarForm('pabellon');
        }
      }, (error) => {
        this.loading = false;
        this.loadingService.setLoading(false);
        this.resetForm();
        console.error('[ Error agendaPabellonService]', error);// llamar a snackbar con error
      });
  }

  formatDate(d) {
    const o = new Date(d);

    let day = o.getUTCDate();
    let dia = day.toString();
    if (day < 10) {
      dia = '0' + dia;
    }

    let month = o.getUTCMonth() + 1;
    let mes = month.toString();
    if (month < 10) {
      mes = '0' + mes;
    }

    let year = o.getUTCFullYear();

    return year + '-' + mes + '-' + dia;
  }


  volver(){
    this.router.navigate(['autoAdmision/paso1', {ppn: this.sharedService.ppnHash}]);
  }

  /** Inicia form vacío, con checkinSelected o con pabellon*/
  inicializarForm(from: string) {
    this.resetForm();
    let sucPabellon = this.listaObjSucursales?.find((e) => e.sucursalId == this.responsePabellon?.pabellonDisponible?.idSucursal);
    console.log('selected motivo al inicializar form...', this.selectedMotivo);
    /** SI HAY PABELLÓN DISPONIBLE & cirugía o pabellón */
    if (from == 'pabellon' && (this.selectedMotivo==='Parto' || this.selectedMotivo==='Cirugía')
      //|| (from == 'pabellon' && !this.sharedService.checkinSelected.checkIn)
      ) {
      console.log("Llegando a form pabellon")

      setTimeout(() => {
        const selectMotivo = this.listaMotivos.find(f => f === this.selectedMotivo);

        this.formInfoHosp.get('motivoDescrip').setValue(selectMotivo);
        this.formInfoHosp.get('motivoDescrip').updateValueAndValidity();

        this.medicoControl.setValue(this.responsePabellon?.nombreMedico, { emitEvent: false });
        this.medicoControl.disable();
        this.noResultsFound = false;
        this.isMedicoError = false;

        this.formInfoHosp.patchValue({
          sucursalHospitalizacion: sucPabellon.nombreSucursal,
          diagnostico: this.responsePabellon?.pabellonDisponible?.diagnostico,
          procedimientoCirugia: this.responsePabellon?.pabellonDisponible?.cirugia,
          medicoTratantePpn: this.responsePabellon?.pabellonDisponible?.ppnMedico,
          medicoTratanteNombre: this.responsePabellon?.nombreMedico,
          codigoPabellon: this.responsePabellon?.pabellonDisponible?.codPabellon,
          fechaPabellon:  this.formatDate(this.responsePabellon?.pabellonDisponible?.fechaPabellon),
          horaPabellon: this.responsePabellon?.pabellonDisponible?.horaPabellon,
          medicoTratanteRut: this.responsePabellon?.rutMedico,
          modalidad: this.responsePabellon?.pabellonDisponible?.codModalidad ?? null, //codModalidad
          cirugiaTraumatologica: this.sharedService.checkinSelected?.antecedentesHospitalizacion?.cirugiaTraumatologica ?? null
        });

        this.cdr.detectChanges();
      }, 1000)

    }
    /** SI HAY CHECKIN SELECCIONADO && No es ni cirugía ni parto */
    else if ( (from == 'pabellon' && (this.selectedMotivo !== 'Parto' && this.selectedMotivo !== 'Cirugía' && this.sharedService.checkinSelected.id != null))
      || (from == 'checkin' && this.sharedService.checkinSelected.id != null)
      || (from == 'no' && this.sharedService.checkinSelected.id != null && this.selectedMotivo === this.sharedService.checkinSelected.motivoValor) ) {
        let fechaIni = moment.utc(this.sharedService.checkinSelected.antecedentesHospitalizacion.fechaIngreso).format('DD-MM-yyyy');
        console.log('entre a checkin form', typeof this.sharedService.checkinSelected.antecedentesHospitalizacion.fechaIngreso, fechaIni);
        
        setTimeout(() => {
          const selectMotivo = this.listaMotivos.find(f => f === this.selectedMotivo);

          this.formInfoHosp.get('motivoDescrip').setValue(selectMotivo);
          this.formInfoHosp.get('motivoDescrip').updateValueAndValidity();

          this.medicoControl.setValue(this.sharedService.checkinSelected.antecedentesHospitalizacion.medicoTratanteNombre, { emitEvent: false });
          this.medicoControl.enable();

          this.formInfoHosp.patchValue({
            origenProcedencia: '',// null para poblar campo obligatorio a guardar en bbdd
            fechaIngreso: this.formatDate(this.sharedService.checkinSelected.antecedentesHospitalizacion.fechaIngreso),
            horaIngreso: this.sharedService.checkinSelected.antecedentesHospitalizacion.horaIngreso,
            motivoCodigo: this.sharedService.checkinSelected.antecedentesHospitalizacion.motivoCodigo,
            sucursalHospitalizacion: this.sucursalHosp.nombreSucursal,
            diagnostico: this.sharedService.checkinSelected.antecedentesHospitalizacion.diagnostico,
            procedimientoCirugia: this.sharedService.checkinSelected.antecedentesHospitalizacion.procedimientoCirugia,
            medicoTratantePpn: this.sharedService.checkinSelected.antecedentesHospitalizacion.medicoTratantePpn,
            medicoTratanteNombre: this.sharedService.checkinSelected.antecedentesHospitalizacion.medicoTratanteNombre,
            codigoPabellon: this.sharedService.checkinSelected.antecedentesHospitalizacion.codigoPabellon,
            fechaPabellon:  this.formatDate(this.sharedService.checkinSelected.antecedentesHospitalizacion.fechaPabellon),
            horaPabellon: this.sharedService.checkinSelected.antecedentesHospitalizacion.horaPabellon,
            medicoTratanteRut: this.sharedService.checkinSelected.antecedentesHospitalizacion.medicoTratanteRut,
            modalidad: this.sharedService.checkinSelected?.antecedentesHospitalizacion?.modalidad?? null,
            cirugiaTraumatologica: this.sharedService.checkinSelected?.antecedentesHospitalizacion?.cirugiaTraumatologica ?? null
          });

          this.cdr.detectChanges();
        }, 1000)
        //console.log("Como está mi form", this.formInfoHosp.value)
    }
    else {
      setTimeout(() => {
        const selectMotivo = this.listaMotivos.find(f => f === this.selectedMotivo);

        this.formInfoHosp.get('motivoDescrip').setValue(selectMotivo);
        this.formInfoHosp.get('motivoDescrip').updateValueAndValidity();
        
        this.medicoControl.setValue('', { emitEvent: false });
        this.medicoControl.enable();

        this.formInfoHosp.patchValue({
          medicoTratanteNombre:'',
          fechaIngreso: moment(new Date()).format('yyyy-MM-DD'),
          cirugiaTraumatologica: null
        });

        this.cdr.detectChanges();
      }, 1000)
      console.log('fin al form vacio');
    }
    //console.log("Como queda mi form---------------->?", this.formInfoHosp)

  }



  resetForm(){
    let fechaNow = moment(new Date()).format('DD-MM-yyyy');
    let horaNow = moment(new Date()).format('HH:mm');

    this.formInfoHosp = this.fb.group({
      origenProcedencia: '',// , Validators.required null para poblar campo obligatorio a guardar en bbdd
      fechaIngreso: [this.formatDate(new Date()), [Validators.required, this.dateValidator]],//, Validators.required
      horaIngreso: horaNow,
      motivoCodigo: '',
      sucursalHospitalizacion: '',
      diagnostico: '',
      procedimientoCirugia: '',
      medicoTratantePpn: 0,
      medicoTratanteNombre: '',
      codigoPabellon: 0,
      fechaPabellon: '',
      horaPabellon: '',
      medicoTratanteRut: '',
      modalidad: '',
      motivoDescrip: '',
      cirugiaTraumatologica: [null, this.selectedMotivo === 'Cirugía' ? Validators.required : null]
    });

  }

  dateValidator(control: FormControl): { [key: string]: any } | null {
    const validDatePattern = /^\d{4}-\d{2}-\d{2}$/;
    return validDatePattern.test(control.value) ? null : { 'invalidDate': true };
  }

  trackByMotivos(index: number, item: any): any {
    return index;
  }


  onDateInput(event: Event): void {

    console.log('>>>>>>> onDateInput <<<<<<<')
    console.log('event', event);

    const input = event.target as HTMLInputElement;
    console.log('input', input);

    const value = input.value;
    console.log('value', value);
    console.log('this.formInfoHosp.controls', this.formInfoHosp.controls['fechaIngreso']);

    this.changeSelect(event, 'fechaIngreso');

    if (!/^\d{4}-\d{2}-\d{2}$/.test(value) && value.length > 0) {
      console.error("Formato de fecha incorrecto");
      this.formInfoHosp.controls['fechaIngreso'].setErrors({ 'invalidDate': true });
    } else {
      console.error("Formato de fecha correcto");
      this.formInfoHosp.controls['fechaIngreso'].setErrors(null);
    }

    console.log('this.isFechaIngresoError', this.isFechaIngresoError);
    console.log('this.formInfoHosp.get(fechaIngreso).errors?.invalidDate', this.formInfoHosp.get('fechaIngreso').errors?.invalidDate)
  }



  onDescargaDeclaracion(){
    //console.log(this.sharedService.checkinSelected);
    let idPrevision = this.sharedService.checkinSelected.codigoPrevision ?? 0;
    if(this.isapreDeclaraAccidente(idPrevision)){
      this.descargar();
    }
  }

  /** Método que descarga documento de Declaración de accidente según isapre */
  descargar() {
    this.loadingService.setLoading(true);
    let infoPdf: any;

    this.autoAdmisionService.declaracionAccAutoAdm(
      this.sharedService.checkinSelected.id.toString(),
      this.sharedService.checkinSelected.nombrePrevision).subscribe(
        (responseDoc: any) => {
          infoPdf = responseDoc;
          //console.log("this.apiDocService.declaracionAccidenteApi", responseDoc)

          if (!responseDoc || responseDoc.message == 'Plantilla no encontrada') {
            this.loadingService.setLoading(false);
            this.showSnackbar('Plantilla no encontrada');
            return;
          }
          else {
            this.autoAdmisionService.apiDoc(infoPdf).subscribe((resp: any) => {
              if (resp) {
                console.log(resp);
                descargaPdf(resp?.downloadData, resp?.originalResponse?.nombre);
                this.loadingService.setLoading(false);
              }
            }, (error) => {
              console.log(error);
              this.loadingService.setLoading(false);
              this.showSnackbar('Error en el servicio, intente nuevamente mas tarde');
            });
          }
        }, (err) => {
          console.log(err);
          this.loadingService.setLoading(false);
          this.showSnackbar('Error en el servicio, intente nuevamente mas tarde');
        }
      )
  }

  cambiarUrlBase(url, parteVieja, parteNueva) {
    return url.replace(parteVieja, parteNueva);
  }


  /** Evalúa si la isapre está pre-definida para descargar documento */
  isapreDeclaraAccidente(previsionId: number): boolean {
    //console.log('codigo recibido', previsionId, DEC_ACCIDENTE_ISAPRE.some(isapre => isapre.codigo === previsionId.toString()));
    this.flagDeclaraAcc = DEC_ACCIDENTE_ISAPRE.some(isapre => isapre.codigo === previsionId.toString());
    return (this.flagDeclaraAcc)? true: false;


  }

}
