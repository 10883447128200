import { ChangeDetectorRef, Component, ElementRef, NgZone, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { AdmisionService } from '../../../servicios/admision/admision.services';
import { SharedAutoAdmisionService } from '../../../servicios/autoAdmision/shared-auto-admision.service';
import { ClienteService } from '../../../servicios/cliente/cliente.service';
import { CuentaCorrienteService } from '../../../servicios/cuentaCorriente/cuenta-corriente.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { CONST_AUTOADM } from 'src/app/const';
import { Acompanante } from '../../../model/acompananteAutoAdm.model';
import { Responsable } from '../../../model/responsableDocAutoAdm.model';
import { AutoAdmisionService } from '../../../servicios/auto-admision/auto-admision.service';
import { LoaderService } from '../../../servicios/loader/loader.service';
import { SharepointService } from '../../../servicios/sharepoint/sharepoint.service';
import { MatSelect } from '@angular/material/select';
import { BehaviorSubject } from 'rxjs';

@Component({
  selector: 'app-paso3-auto-admision',
  templateUrl: './paso3-auto-admision.component.html',
  styleUrls: ['./paso3-auto-admision.component.css']
})
export class Paso3AutoAdmisionComponent implements OnInit {
  @ViewChild('acompananteSelect') acompananteSelect: MatSelect;

  /** Labels */
  labelNombre: string = 'Nombres';
  labelApellido: string = 'Apellido';
  labelCelular: string = 'Celular';
  labelEmail: string = 'Email';
  labelParentesco: string = 'Parentesco';
 
  /** Arrays */
  Acompanantes: string[] = ['Seleccione Opción', 'Sí', 'No'];
  Parentescos: string[] = [];
  parentescosFind : any[];

  /** Form */
  formDatosPaso3!: FormGroup;

  /** Booleans and Strings */
  esAcompanante: boolean = false;
  esOtro: boolean = false;
  isCheckboxSelected: boolean = false;
  isFinalizacion: boolean = false;
  isFinalizacionError: boolean = false;
  isAcompananteError: boolean = false;
  isNombreError : boolean = false;
  isApellidoError: boolean = false;
  isCelularError: boolean = false;
  isEmailError: boolean = false;
  isParentescoError: boolean = false;
  isRutError: boolean = false;
  isConfirm: boolean = false;
  respAcompChecked = false;
  respPacienChecked = false;
  respOtroChecked = false;
  isAcom: boolean = false;
  durationInSeconds = 5;
  tengoAcompanante: boolean = false;
  tengoResponsable: boolean = false;
  helperTextNombre: string;
  helperTextApellido: string;
  helperTextCelular: string;
  helperTextEmail: string;
  helperTextRut: string;
  errorType: string = '';
  estadoModal: boolean = false;

  /** variables cédula identidad */
  loadingNew: string = 'false';
  extensionFile='';
  fileDoc='';
  nameDoc='';
  fileDocFrente: string = '';
  fileDocDorso: string = '';
  extensionFileFrente: string = '';
  extensionFileDorso: string = '';
  isExito: boolean = false;
  isError: boolean = false;
  isErrorDocumentoReq: boolean = false;
  isErrorDocumento: boolean = false;
  dorsal: string = ''
  frontal: string = ''
  isDorsal = false;
  isFrontal = false;
  textFileError = '';
  reloadInput = 0;
  isErrorDorsoReq=false;
  isErrorFrenteReq=false;
  tituloCedulaIdentidad = CONST_AUTOADM.TITULO_ADJUNTA_CEDULA;
  formatoCedulaIdentidad = CONST_AUTOADM.FORMATO_CEDULA;
  msjeResponsablePagare = CONST_AUTOADM.MSJE_RESP_PAGARE;
  isSaving = false;
  private domMutationObserver: MutationObserver;

  constructor(
    private admisionService: AdmisionService,
    private clienteService: ClienteService,
    public router: Router,
    public sharedService: SharedAutoAdmisionService,
    private sharepointService: SharepointService,
    public fb: FormBuilder,
    private elementRef: ElementRef,
    private cuentaCorriente: CuentaCorrienteService,
    public snackBar: MatSnackBar,
    private autoAdmisionService:AutoAdmisionService,
    private loadingService: LoaderService,
    private cdr: ChangeDetectorRef,
    private ngZone: NgZone
  ) { 
  }

  ngOnInit() {
    this.sharedService.setStepper(3);
    this.resetForm();
    this.parentescoRellenar();
    this.obtenerAcompanante();
    this.obtenerResponsable();
    this.cargarDocsManuales();

    this.ngZone.runOutsideAngular(() => {
      this.domMutationObserver = new MutationObserver(() => {
        const button = document.getElementById('myButton3') as HTMLButtonElement;
        console.log("click button", button);
        button.click();
      });

      this.domMutationObserver.observe(this.elementRef.nativeElement, {
        childList: true,
        subtree: true,
        attributes: true
      });
    });
  }

  ngOnDestroy(): void {
    if (this.domMutationObserver) {
      this.domMutationObserver.disconnect();
    }
  }

  resetForm() {
    this.formDatosPaso3 = this.fb.group({
      acompanante: ['Seleccione Opción', Validators.required],
      nombre: ['', Validators.required],
      apellido: ['', Validators.required],
      celular: ['', Validators.required],
      email: ['', [Validators.required, Validators.email]],
      parentesco: ['Seleccione Opción', Validators.required],
      firma: [''],
      firmarutOtro: [''],
    });
  }
  changeSelect(ev: any, controlName: string) {
    const selectElement = ev.target as HTMLSelectElement;
    
    // Lógica para desplegar otros campos
    let valor;
    if (ev.value !== undefined) {
      valor = ev.value;
    } else if (ev.target.value !== undefined) {
      valor = ev.target.value;
    } else {
      return;
    }
  
    try {
      if (controlName === 'paciente' || controlName === 'acompanante2' || controlName === 'otro') {
        this.formDatosPaso3.patchValue({ firma: controlName });
      }
    } catch (error) {
      console.error('Error updating firma field', error);
    }
  
    try {
      if (controlName === 'acompanante') {
        if (valor?.toLowerCase() === 'sí') {
          this.isAcom = false;
          this.esAcompanante = true;
          this.cdr.detectChanges();
        } else if (valor === 'Seleccione Opción') {
          this.isAcom = false;
          this.esAcompanante = false;
          this.respAcompChecked = false;
        } else {
          if (this.formDatosPaso3.value.firma === 'acompanante2') {
            this.formDatosPaso3.patchValue({ firma: '' });
            this.respAcompChecked = false;
          }
          this.respAcompChecked = false;
          this.isAcom = true;
          this.esAcompanante = false;
        }
      } else if (controlName === 'otro') {
        this.esOtro = true;
      } else if (controlName === 'paciente' || controlName === 'acompanante2') {
        this.esOtro = false;
      }
  
      const control = this.formDatosPaso3.get(controlName);
      if (control) {
        control?.setValue(valor);
      } else {
        console.warn(`Control ${controlName} does not exist in the form`);
      }
    } catch (error) {
      console.error('Error processing selection change', error);
    }
  
    try {
      this.marcarConError(controlName, valor);
    } catch (error) {
      console.error('Error marcarConError', error);
    }
  }

  marcarConError(controlName: string, valor: any) {
    switch (controlName) {
      case 'nombre':
        this.isNombreError = valor;
        break;
      case 'celular':
        this.isCelularError = valor;
        break;
      case 'email':
        this.isEmailError = valor;
        break;
      case 'apellido':
        this.isApellidoError = valor;
        break;
      case 'acompanante':
        this.isAcompananteError = valor;
        this.isAcompananteError  = false;
        break;
      case 'parentesco':
        this.isParentescoError = valor;
        this.isParentescoError = false;
        break;
      default:
        break;
    }

  }
  /** Manejo de Acompañante */

  async obtenerAcompanante() {
    // Check if there's no "acompañante" and "estadoAutoadmision" is true
    if (this.sharedService.checkinSelected.conAcompanante == false && this.sharedService.checkinSelected.estadoAutoadmision == true) {
      // Delay updating the form to give a smoother experience
      setTimeout(() => {
        this.formDatosPaso3.patchValue({
          acompanante: 'No' 
        });
        this.isAcom = true;
        this.cdr.detectChanges(); // Trigger change detection after updating the form
      }, 1000);
    } else {
      try {
        // Fetch the acompañante details if conditions are not met
        const response: any = await this.autoAdmisionService.obtenerAcompHash(this.sharedService.checkinSelected.id).toPromise();
  
        // Check if the response contains a valid acompañante
        if (response != null) {
          // Find the relationship based on 'relacionPaciente'
          const parentesco = this.parentescosFind.find(p => p.llave.toString() === response.relacionPaciente);
  
          // Update the form with the accompanying person's details
          this.formDatosPaso3.patchValue({
            nombre: response.nombres,
            apellido: `${response.apellidoPaterno} ${response.apellidoMaterno}`,
            celular: response.telefonoContacto,
            email: response.email ? response.email : '',
            parentesco: parentesco ? parentesco.valor : 'Seleccione Opción',
            acompanante: 'Sí'
          });
  
          this.esAcompanante = true;
          this.tengoAcompanante = true;
        } else {
          // Handle case where no acompañante is found
          this.esAcompanante = false;
          this.tengoAcompanante = false;
        }
      } catch (error) {
        console.error('Error al obtener acompañante:', error);
      }
  
      this.cdr.detectChanges(); // Ensure that changes are reflected in the view
    }
  }
  


  crearObjetoAcomp(response?: any): Acompanante {
  let objetoAcomp = new Acompanante();
  if (response) {
    objetoAcomp.documentoId = response.documentoId || 0;
    objetoAcomp.documentoNumero = response.documentoNumero || "";
    objetoAcomp.apellidoPaterno = response.apellidoPaterno || "";
    objetoAcomp.apellidoMaterno = response.apellidoMaterno || "";
    objetoAcomp.nombres = response.nombres || "";
    objetoAcomp.telefonoContacto = response.telefonoContacto || "";
    objetoAcomp.direccion = response.direccion || "";
    objetoAcomp.ciudad = response.ciudad || "";
    objetoAcomp.codigoComuna = response.codigoComuna || 0;
    objetoAcomp.nombreComuna = response.nombreComuna || "";
    objetoAcomp.codigoPais = response.codigoPais || 0;
    objetoAcomp.nombrePais = response.nombrePais || "";
    objetoAcomp.relacionPaciente = response.relacionPaciente || "";
  }
  return objetoAcomp;
}
    
asignarValoresAcomp(objetoAcomp: Acompanante) {
  objetoAcomp.nombres = this.formDatosPaso3.value.nombre;
  objetoAcomp.apellidoPaterno = this.formDatosPaso3.value.apellido;
  objetoAcomp.telefonoContacto = this.formDatosPaso3.value.celular;
  objetoAcomp.email = this.formDatosPaso3.value.email;
  

  const parentesco = this.parentescosFind.find(p => p.valor === this.formDatosPaso3.value.parentesco);
  

  objetoAcomp.relacionPaciente = parentesco ? parentesco.llave : 0;
}


manejarCreacionAcomp(objetoAcomp: Acompanante) {
  this.autoAdmisionService.crearAcompHash(objetoAcomp, this.sharedService.checkinSelected.id).subscribe(
    () => {
      this.estadoModal = true;
      console.log("EstadoModal Acomp 1", this.estadoModal )
      //console.log("Creado el Acompañante");
    },
    (error) => {
      this.estadoModal = false;
      console.error('[ Error  crearAcompanante ]', error);
    }
  );
}
modificarAcompanante(objetoAcomp: Acompanante) {
  this.autoAdmisionService
    .modificarAcompHash(objetoAcomp, this.sharedService.checkinSelected.id)
    .subscribe(
      () => {
        this.estadoModal = true;
        console.log("EstadoModal Acomp 2", this.estadoModal )
        //console.log("Modificado el Acompañante");
      },
      (error) => {
        this.estadoModal = false;
        console.error('[ Error  modificarAcompanante ]', error);
      }
    );
}

guardarAcompanante(objetoAcomp: Acompanante) {

    if (this.tengoAcompanante) {
 
      this.modificarAcompanante(objetoAcomp);
    } else {
     
      this.manejarCreacionAcomp(objetoAcomp);
    }
   
}


setearAcompanante() {
  let objetoAcomp = this.crearObjetoAcomp();
  this.asignarValoresAcomp(objetoAcomp);
  this.guardarAcompanante(objetoAcomp);
}

/* Manejo de Responsables*/
async obtenerResponsable() {
  try {

    const response: any = await this.autoAdmisionService.obtenerResponHash(this.sharedService.checkinSelected.id).toPromise();

    if (response != null) {

      const tipoResponsable = response.tipoResponsableDocumento;


      this.respPacienChecked = false;
      this.respAcompChecked = false;
      this.respOtroChecked = false;
      this.tengoResponsable = true;


      setTimeout(() => {

        if (tipoResponsable === 'paciente') {
          this.respPacienChecked = true;
          this.formDatosPaso3.patchValue({ firma: 'paciente' });
        } else if (tipoResponsable === 'acompanante') {
          this.respAcompChecked = true;
          this.formDatosPaso3.patchValue({ firma: 'acompanante' });
        } else if (tipoResponsable === 'otro') {
          this.respOtroChecked = true;
          this.esOtro = true;
          this.formDatosPaso3.patchValue({ firma: 'otro', firmarutOtro: response.documentoNumero });
        }

        
        this.cdr.detectChanges();
      }, 1000);  
    }
  } catch (error) {

    console.error('Error al obtener responsable:', error);
    this.tengoResponsable = false;

    this.cdr.detectChanges();
  }
}


asignarValoresResp(objetoResp: Responsable) {

  if(this.formDatosPaso3.value.firma === 'paciente'){
    //Paciente


  objetoResp.documentoId = this.sharedService.checkinSelected.documentoId;
  objetoResp.documentoNumero = this.sharedService.checkinSelected.documentoNumero;
  objetoResp.apellidoMaterno = this.sharedService.checkinSelected.apellidoMaterno;
  objetoResp.apellidoPaterno = this.sharedService.checkinSelected.apellidoPaterno;
  objetoResp.nombres = this.sharedService.checkinSelected.nombres;
  objetoResp.direccion = this.sharedService.checkinSelected.direccion;
  objetoResp.ciudad = this.sharedService.checkinSelected.ciudad;
  objetoResp.codigoComuna = this.sharedService.checkinSelected.codigoComuna;
  objetoResp.nombreComuna = this.sharedService.checkinSelected.nombreComuna;
  objetoResp.codigoPais = this.sharedService.checkinSelected.codigoPais;
  objetoResp.nombrePais = this.sharedService.checkinSelected.nombrePais;
  objetoResp.tipoResponsableDocumento =  'paciente'
  objetoResp.telefonoContacto = this.sharedService.checkinSelected.telefono_movil;
  } else if (this.formDatosPaso3.value.firma === 'acompanante2'){
     //Acompañante
  
  objetoResp.apellidoPaterno = this.formDatosPaso3.value.apellido;
  objetoResp.nombres = this.formDatosPaso3.value.nombre;
  objetoResp.tipoResponsableDocumento =  'acompanante'
  objetoResp.telefonoContacto = this.formDatosPaso3.value.celular;

  } else {
    //Otro
   
  objetoResp.documentoNumero = this.formDatosPaso3.value.firmarutOtro;
  const firmaValue = this.formDatosPaso3.value.firma;


  const tipoResponsable = firmaValue === 'acompanante2' ? 'acompanante' : firmaValue;


  objetoResp.tipoResponsableDocumento = tipoResponsable;

  }
 
}


manejarCreacionResp(objetoResponsable: Responsable) {
  this.autoAdmisionService
  .crearResponHash(objetoResponsable, this.sharedService.checkinSelected.id).subscribe(
    () => {
      this.estadoModal = true;
      console.log("EstadoModal Respo 1", this.estadoModal )
      //console.log("Creado el Responsable");
    },
    (error) => {
      this.estadoModal = false;
      console.error('[ Error  crearResponsable ]', error);
    }
  );
}

modificarResp(objetoResponsable: Responsable) {
  this.autoAdmisionService
  .modificarResponHash(objetoResponsable, this.sharedService.checkinSelected.id).subscribe(
    () => {
      this.estadoModal = true;
      console.log("EstadoModal Respon 2", this.estadoModal )
      //console.log("Modificando el Responsable");
    },
    (error) => {
      this.estadoModal = false;
      console.error('[ Error  modificarResponsable ]', error);
    }
  );
}


guardarResponsable(objetoResp: Responsable) {
  if (this.tengoResponsable) {

    this.modificarResp(objetoResp);
  } else {

    this.manejarCreacionResp(objetoResp);
  }
}

setearResponsable() {
  let objetoResp = this.crearObjetoResponsable();
  this.asignarValoresResp(objetoResp);
  this.guardarResponsable(objetoResp);
}



crearObjetoResponsable(): Responsable {
  let objetoResp = new Responsable();
  
  objetoResp.documentoId = 0;
  objetoResp.documentoNumero = "";
  objetoResp.apellidoPaterno = "";
  objetoResp.apellidoMaterno = "";
  objetoResp.nombres = "";
  objetoResp.direccion = "";
  objetoResp.ciudad = "";
  objetoResp.codigoComuna = 0;
  objetoResp.nombreComuna = "";
  objetoResp.codigoPais = 0;
  objetoResp.nombrePais = "";
  objetoResp.tipoResponsableDocumento = "";
  objetoResp.telefonoContacto = "";

  return objetoResp;
}


//Actualizar estado de autoAdmision
actualizarAdmision() {
  try {
    if(this.formDatosPaso3.value.acompanante === 'Sí'){
      this.sharedService.checkinSelected.conAcompanante = true;
    }else{
      this.sharedService.checkinSelected.conAcompanante = false;
    }
    this.sharedService.checkinSelected.estadoAutoadmision = true;

    this.autoAdmisionService.modificarAdmision(
      this.sharedService.checkinSelected.id.toString(),
      this.sharedService.checkinSelected,
      this.sharedService.checkinSelected.usuarioCreador
    ).subscribe(
      () => {
        this.estadoModal = true;
        console.log("EstadoModal Actualizar", this.estadoModal )
        //console.log('La admisión se ha actualizado con éxito.');
      },
      (error) => {
        this.estadoModal = false;
        console.error('Hubo un error al actualizar la admisión:', error);
      }
    );
  } catch (error) {
    console.error('Hubo un error al intentar actualizar la admisión:', error);
  }
}




  validarCampos(): boolean {
    this.validarDocsRequeridos();
    const nombres = this.formDatosPaso3.value.nombre;
    const regexNombres = /^[a-zA-ZáéíóúÁÉÍÓÚüÜñÑ\s]*$/;

    const apellidos = this.formDatosPaso3.value.apellido;
    const regexApellido = /^[a-zA-ZáéíóúÁÉÍÓÚüÜñÑ\s]*$/;

    const correo = this.formDatosPaso3.value.email;
    const regexEmail = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}$/;

    const celular = this.formDatosPaso3.value.celular;
    const regexCelular = /^[0-9]{9}$/;

    const firma = this.formDatosPaso3.value.firma;
    const firmarutOtro = this.formDatosPaso3.value.firmarutOtro;

    this.isAcompananteError = !this.formDatosPaso3.value.acompanante || this.formDatosPaso3.value.acompanante?.toLowerCase() === 'seleccione opción';

    if (this.formDatosPaso3.value.acompanante && this.formDatosPaso3.value.acompanante?.toLowerCase() === 'sí') {
        this.isApellidoError = !regexApellido.test(apellidos.trim()) || !apellidos.trim();
        this.isParentescoError = !this.formDatosPaso3.value.parentesco || this.formDatosPaso3.value.parentesco?.toLowerCase() === 'seleccione opción';
        this.isEmailError = !regexEmail.test(correo);
        this.isCelularError = !regexCelular.test(celular);
        this.isNombreError = !regexNombres.test(nombres.trim()) || !nombres.trim();
        this.isApellidoError = !regexNombres.test(apellidos.trim()) || !apellidos.trim();
        
        if (!regexNombres.test(nombres.trim()) || !nombres.trim()) {
          this.helperTextNombre = CONST_AUTOADM.HELPER_TEXT_NOMBRE;
        }

        if (!regexApellido.test(apellidos) || !apellidos.trim()) {
          this.helperTextApellido = CONST_AUTOADM.HELPER_TEXT_APELLIDO;
        } 
        

        if (!regexCelular.test(celular)) {
          this.helperTextCelular = CONST_AUTOADM.HELPER_TEXT_CELULAR;
        }

       
        if (!regexEmail.test(correo)) {
          this.helperTextEmail = CONST_AUTOADM.HELPER_TEXT_CORREO;
        } 

    } else {
        this.isApellidoError = false;
        this.isParentescoError = false;
        this.isEmailError = false;
        this.isCelularError = false;
        this.isNombreError = false;
    }

    if (firma && firma?.toLowerCase() === 'otro') {
      this.validacionRutIncorrecto(firmarutOtro);
    
      if (!this.formDatosPaso3.value.firmarutOtro || !this.formDatosPaso3.value.firmarutOtro.trim()) {
        this.errorType = 'incorrect';
        this.isRutError = true;
      } 
     
      else if (this.formDatosPaso3.value.firmarutOtro?.toLowerCase() === this.sharedService.checkinSelected.documentoNumero) {
        this.errorType = 'sameAsPaciente';
        this.isRutError = true;
      } 
      else {
        //this.isRutError = false;
      }
    } else {
      this.isRutError = false;   
    }
    //console.log("Como queda mi form", this.formDatosPaso3.value);
    this.isErrorDocumentoReq = (this.isErrorDorsoReq || this.isErrorFrenteReq)? true: false;
    console.log(this.isErrorDorsoReq , this.isErrorFrenteReq, 'this.isErrorDocumentoReq-->', this.isErrorDocumentoReq);
    
    this.isConfirm =
        this.isCelularError ||
        this.isEmailError ||
        this.isNombreError ||
        this.isApellidoError ||
        this.isAcompananteError ||
        this.isParentescoError ||
        this.isRutError || 
        this.isErrorDocumentoReq;
    return this.isConfirm;
}

  
  public onKeyUp(event: any) {
    const NUMBER_REGEXP = /^\s*(\-|\+)?(\d+-?|(\d*(\-.\d*)))([eE-][+-]?\d+)?\s*$/;
    let newValue = event.target.value;
    if (!NUMBER_REGEXP.test(newValue)) {
        event.target.value = newValue.slice(0, -1);
    }
    if (newValue.length > 20) {
        event.target.value = newValue.slice(0, -1);
    }
  }


  parentescoRellenar() {
    this.parentescosFind = this.sharedService.allParams?.filter(
      (elem) => elem.categoria === 'acompanante'
    );
  
    if (this.parentescosFind?.length > 0) {
      this.Parentescos = [];
      this.parentescosFind.forEach((mensaje) => {
        const parentescos = mensaje.valor.split(',');
        this.Parentescos.push(...parentescos);
      });
      this.Parentescos = Array.from(new Set(this.Parentescos));
    } else {
      console.error('No se encontraron mensajes de Isapre con los datos esperados.');
    }
  
    this.Parentescos.unshift('Seleccione Opción');
  }
    
  checkboxChanged(event: any) {
    this.isCheckboxSelected = event.target.checked;
  }

  showSnackbar(message: string) {
    this.snackBar.open(message, 'Cerrar', {
      duration: this.durationInSeconds * 1000,
      horizontalPosition: 'end',
      politeness: 'polite',
    });
  }

  async volver() {
    this.router.navigate(['autoAdmision/paso2', {ppn: this.sharedService.ppnHash }]);
  }

  close(){
    this.isFinalizacion = false;
    this.isFinalizacionError = false;
    this.router.navigate(['autoAdmision/antecedentesHospitalizacion',{ ppn: this.sharedService.ppnHash },]);
  }

  async guardar() {
    this.loadingNew = 'true';  // Deshabilita el botón al comenzar

    try {
        // Validar los campos del formulario
        this.validarCampos();

        if (!this.isConfirm) {
            await this.actualizarAdmision();

            const promises = [];
            if (this.formDatosPaso3.value.acompanante !== 'No') {
                promises.push(this.setearAcompanante());
            }
            promises.push(this.setearResponsable());

            await Promise.all(promises);
        } else {
            this.showSnackbar(CONST_AUTOADM.MSJ_FORM_ERROR);
        }
    } catch (error) {
        console.error('Error al guardar los datos:', error);
        this.showSnackbar('Ocurrió un error al guardar.');
        this.loadingNew = 'false';
    } finally {
        await this.delay(5000);
        await this.verificarEstadoGuardado();
            this.loadingNew = 'false';
        }
  }




verificarEstadoGuardado(){
  if(this.estadoModal){
    this.isFinalizacion = true;
  }

  // setTimeout(() => {
  //   this.router.navigate(['autoAdmision/antecedentesHospitalizacion', { ppn: this.sharedService.ppnHash }]);
  // }, 5000);  
}


  validacionRutIncorrecto(rutCompleto) {
    rutCompleto =  rutCompleto.replace(/\./g, '');
    rutCompleto  = rutCompleto.replace(".", "");

    if (!/^[0-9]+[-|‐]{1}[0-9kK]{1}$/.test(rutCompleto)) {
      this.isRutError = true;
      this.errorType = 'incorrect';
    } else {
      this.isRutError = true;
      this.errorType = 'incorrect';
    }

    var tmp = rutCompleto.split('-');
    var digv = tmp[1];
    var rut = tmp[0];
    if (digv == 'K') digv = 'k';

    let res = this.dv(rut)

    if (res.toString() == digv) {

    
      this.isRutError = false;

    } else {
    
      this.isRutError = true;
      this.errorType = 'incorrect';
    }


  }

  dv(T) {
    var M = 0, S = 1;
    for (; T; T = Math.floor(T / 10))
      S = (S + T % 10 * (9 - M++ % 6)) % 11;
    return S ? S - 1 : 'k';
  }

  redireccionamiento() {
    window.top.location.href  = 'https://www.clinicaalemana.cl/informacion-para-pacientes/todo-sobre-hospitalizacion';
  }

  salirError(){
    this.router.navigate([
      'autoAdmision/antecedentesHospitalizacion',
      { ppn: this.sharedService.ppnHash },
    ]);
    //this.sharedService.checkinSelected.id=undefined
  }

  /** CARGA DE Ci */
  async enviarDocumentosSharepoint() {
    this.loadingNew = 'true';
  
    if (!this.fileDocFrente || !this.fileDocDorso) {
      this.handleError('Faltan archivos por cargar');
      return;
    }
  
    try {
      const rut = this.sharedService.pacienteSelected.rut.replace('-', '');
      const metadata = this.getMetadata();
  
      // Intentar crear la carpeta con reintento
      const maxRetries = 3;
      let folderCreated = false;
      let retryCount = 0;
      while (!folderCreated && retryCount < maxRetries) {
        try {
          await this.createFolder(metadata);
          folderCreated = true;
        } catch (error) {
          retryCount++;
          console.error(`Error al crear la carpeta, intento ${retryCount}:`, error);
          if (retryCount >= maxRetries) {
            this.handleError('Error al crear la carpeta después de varios intentos');
            return; // Detener el proceso si no se puede crear la carpeta
          }
        }
      }
  
      const dataCargaFrente = this.getDataCarga('Frente de cédula', this.fileDocFrente, this.extensionFileFrente, rut);
      const dataCargaDorso = this.getDataCarga('Dorso de cédula', this.fileDocDorso, this.extensionFileDorso, rut);
  
      let responseFrente: any;
      let responseDorso: any;
  
      // Subir frente de cédula
      try {
        responseFrente = await this.uploadFile(dataCargaFrente) as any;
      } catch (error) {
        this.handleError('Error al cargar el frente del documento');
        console.error('Error en la carga del frente:', error);
        return; 
      }
  

      const maxRetriesUpload = 3;
      let retryUploadCount = 0;
      while (retryUploadCount < maxRetriesUpload) {
        try {
          responseDorso = await this.uploadFile(dataCargaDorso) as any;
          break; 
        } catch (error) {
          retryUploadCount++;
          console.error(`Error al cargar el dorso, intento ${retryUploadCount}:`, error);
          if (retryUploadCount >= maxRetriesUpload) {
            this.handleError('Error al cargar el dorso del documento después de varios intentos');
            return; 
          }

          await new Promise(resolve => setTimeout(resolve, 2000));
        }
      }
  

      if (responseFrente && responseDorso) {
        this.isExito = true;
      } else {
        this.isExito = false;
      }
    } catch (error) {
      this.handleError('Error general al cargar los documentos');
      console.error('Error general:', error);
    } finally {
      this.loadingNew = 'false';
    }
  }
  
  
  
  
  private getMetadata() {
    return {
      TipoIdentificacion: this.sharedService.pacienteSelected.isRut ? 'rut' : 'passport',
      Nombres: this.sharedService.pacienteSelected.nombres,
      PrimerApellido: this.sharedService.pacienteSelected.primer_apellido,
      SegundoApellido: this.sharedService.pacienteSelected.segundo_apellido,
      RutPasaportePaciente: this.sharedService.pacienteSelected.rut
    };
  }
  
  private getDataCarga(nombreDocumento: string, base64: string, extension: string, rut: string) {
    return {
      nombreCarpeta: this.sharedService.pacienteSelected.isRut ? rut : this.sharedService.pacienteSelected.rut,
      nombreDocumento: `${nombreDocumento}.${extension}`,
      base64: base64,
      usuario: 'Auto-Admision',
      metadata: this.getMetadata(),
      tipoDocumento: "docIdentidad",
      idAdmision: this.sharedService.checkinSelected.id.toString()
    };
  }
  
  private async createFolder(metadata: any) {
    const paramsFolder = {
      rutPasaporte: this.sharedService.pacienteSelected.rut,
      usuario: 'Auto-Admision',
      metadata: metadata
    };
  
    return new Promise<void>((resolve, reject) => {
      this.autoAdmisionService.crearFolder(paramsFolder, this.sharedService.ppnHash).subscribe(
        () => resolve(),
        (error) => reject(error)
      );
    });
  }
  
  private async uploadFile(dataCarga: any) {
    return this.autoAdmisionService.cargarFileCola(dataCarga, this.sharedService.ppnHash).toPromise();
  }
  
  private async verifyUploadStatus(uid: string) {
    for (let i = 0; i < 8; i++) {
      await this.delay(12000);
      const response: any = await this.autoAdmisionService.obtenerEstadoFileCola(uid).toPromise();
      if (response.estado === 'COMPLETADO') {
        return;
      }
      if (response.estado === 'ERROR') {
        throw new Error('Error al cargar el documento.');
      }
    }
    throw new Error('Error al cargar el documento.');
  }
  
  private handleError(message: string) {
    console.error(message);
    this.isError = true;
    this.textFileError = 'No fue posible guardar los documentos, por favor reintentalo más tarde.';
    this.loadingNew = 'false';
  }
  
  private delay(ms: number) {
    return new Promise(resolve => setTimeout(resolve, ms));
  }

  async verificarEstadoCarga(uid: string) {
    let exitoService = 0;
    let errorCounter = 0;
    let finalStatus = '';
    this.loadingService.setLoading(true);
    this.loadingNew = 'true';

    for (let i = 0; i < 8; i++) {
      await new Promise(resolve => setTimeout(resolve, 12000));
      const response2: any = await this.autoAdmisionService.obtenerEstadoFileCola(uid).toPromise();
      finalStatus = response2.estado;
      if (response2.estado === 'COMPLETADO' && exitoService === 0) {
        this.isExito = true;
        break;
      }

      if (response2.estado === 'ERROR') {
        this.isErrorDocumento = true;
        errorCounter++;
        if (exitoService === 0 && errorCounter === 50) {
          console.log("a-5")
          this.loadingService.setLoading(false);
          throw new Error('Error al cargar el documento.');
        }
      }
    }

    if (finalStatus === 'ERROR') {
      this.isErrorDocumento = true;
      this.loadingService.setLoading(false);
      this.loadingNew = 'false';
      throw new Error('Error al cargar el documento.');
    }
  }


  cargarDocsManuales() {
    this.autoAdmisionService.obtenerDocsManuales(this.sharedService.ppnHash).subscribe(
      (response: any) => {
        console.log("Response", response)
        console.log("CheckinSelected", this.sharedService.checkinSelected.id)
        let docsManuales = response.filter(
          (elmnt) => elmnt.tipoDocumento === 'docIdentidad' &&
            elmnt.idAdmision == this.sharedService.checkinSelected.id
        );
        docsManuales.forEach(doc => {
          if (doc.nombreDocumento.includes('Frente de cédula')) {
            this.frontal = doc.nombreDocumento;
            this.isFrontal = true;
            console.log("IsFrontal", this.isFrontal)
  
            console.log("Frontal", this.frontal)
          } else if (doc.nombreDocumento.includes('Dorso de cédula')) {
            this.dorsal = doc.nombreDocumento;
            this.isDorsal = true;
            console.log("IsDorsal", this.isDorsal)
            console.log("Dorsal", this.dorsal)
          }
        });
      },
      (error) => {
        console.error('cargarDocsManuales' + error);
      }
    );
  }

  closeModalDocs(type: string) {
    if (type == 'Exito') {
      this.isExito = false;
    } else {
      this.fileDocDorso = '';
      this.extensionFileDorso = '';
      this.dorsal = '';
      this.isError = false;
      this.isErrorDocumento = false;
      this.isErrorDocumentoReq = false;
    }
  }

  convertToBase64(file: File): Promise<string> {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {
        const base64String = reader.result?.toString().split(',')[1];
        if (base64String) {
          resolve(base64String); 
        } else {
          reject(new Error("Error al convertir archivo a Base64"));
        }
        reader.onerror = error => reject(error);
      }
    });
  }

  onFileSelected(event: any, tipo: string) {
    let file = event.target.value;
    console.log('file-->', file, ' tipo-->', tipo);
    this.validarDocsRequeridos();
    
    const resetFileData = () => {
      if (tipo === 'frente') {
        this.fileDocFrente = '';
        this.extensionFileFrente = '';
      } else if (tipo === 'dorso') {
        this.fileDocDorso = '';
        this.extensionFileDorso = '';
      }
      // this.isErrorDorsoReq = false;
      // this.isErrorFrenteReq = false;
      this.validarDocsRequeridos();
    };
  
    if (!file) { //quito el archivo
      resetFileData();
      return;
    }
 
    const extension = file.name.split('.').pop()?.toLowerCase();
    const validExtensions = ['jpg', 'jpeg', 'png', 'pdf', 'docx'];
  
    if (validExtensions.includes(extension)) {     
      this.convertToBase64(file).then((base64: string) => {
        if (tipo === 'frente') {
          this.fileDocFrente = base64;
          this.extensionFileFrente = extension;
          this.isErrorFrenteReq = false;
        } else if (tipo === 'dorso') {
          this.fileDocDorso = base64;
          this.extensionFileDorso = extension;
          this.isErrorDorsoReq = false;
        }
        if(this.fileDocDorso!=='' && this.fileDocDorso===this.fileDocFrente){
          this.reloadInput++;
          resetFileData(); 
          this.isError = true;
          this.textFileError = 'Los archivos deben ser distintos.'
        }
        
        if ((this.fileDocFrente !== '' && this.fileDocDorso !== '') 
          && (!this.isErrorDorsoReq && !this.isErrorFrenteReq) ) {
          this.enviarDocumentosSharepoint();
         //alert('cargando docs');
        }       
      });
    }     
    else {
      resetFileData();
      this.isError = true;
      this.textFileError = CONST_AUTOADM.ERROR_FORMATO_CEDULA;
    }
    
    // else{
    //   this.isError = true;
    //   this.textFileError = 'Ambos documentos son obligatorios';
    // }
  }

  validarDocsRequeridos(){
    if (this.fileDocFrente !== '' && this.fileDocDorso === '') {
      this.isErrorDorsoReq = true;
      //this.textFileError = 'Ambos documentos son obligatorios';
    }
    if (this.fileDocFrente === '' && this.fileDocDorso !== '') {
      this.isErrorFrenteReq = true;
      //this.textFileError = 'Ambos documentos son obligatorios';
    }
    if ((this.fileDocFrente === '' && this.fileDocDorso === '') 
      || (this.fileDocFrente !== '' && this.fileDocDorso !== '')) {
      this.isErrorFrenteReq = false;
      this.isErrorDorsoReq = false;
    }
  }
  
  /** FIN CARGA Ci */
}
