import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { ErrorHandler, Inject, Injectable, NgZone } from '@angular/core';
import { ErrorDialogService } from './errors/error-dialog.service';

/**
 * Clase para la manipulación global de errores del sistema
 */
@Injectable({
  providedIn: 'root'
})
export class GlobalErrorHandler  implements ErrorHandler {

  httpHeaders = {
    headers: new HttpHeaders().set('Content-Type', 'application/json')
  };

  constructor(
    private errorDialogService: ErrorDialogService,
    private zone: NgZone,
    private http: HttpClient
  ) { }

  /**
   * Implementación del control de errores transversal
   * @param error Excepción no contralada enviada desde el sistema
   */
  handleError(error: any) {

    try {
      // Check if it's an error from an HTTP response
        if (!(error instanceof HttpErrorResponse)) {
          error = error?.rejection || error; // get the error object
        }
        
        if (error && String(error).indexOf("Error Integracion") != -1){
          this.zone.run(() =>
            this.errorDialogService.openDialog(
              'Ha ocurrido un error con un sistema externo, favor comuníquese con el administrador del sistema' || 'Undefined client error'
            )
          );

          return;
        }
        
    } catch (errorcatch: any) {
      console.log('Problemas para registrar el error');
    }
  }
}