import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { RequestAdmision } from '../../model/requestAdmision.model';
import { CONSTANTES } from 'src/app/const';



@Injectable({
  providedIn: 'root'
})
export class AdmisionService {

  estadoAdmision: number = 1;

  getAdmisionJerarquia() {
    throw new Error('Method not implemented.');
  }
  private headers: HttpHeaders;
  private requestAdmision: RequestAdmision;

  constructor(private http: HttpClient) {
    this.headers = new HttpHeaders();
    this.headers = this.headers.append('Content-Type', 'application/json');
  }

  admisiones(id) {
    return this.http.get(environment.urlMsSisnadhos + "/admision/" + id, {
      headers: this.headers
    });
  }

  testObtenerAdmisiones() {
    return this.estadoAdmision;
  }

  admisionesLista() {
    return this.http.get(environment.urlMsSisnadhos + "/admision/", {
      headers: this.headers
    });
  }


  crearAdmision(params: any, usuario: string) {
    return this.http.post(environment.urlMsSisnadhos + "/admision/" + usuario + "/usuario", params, {
      headers: this.headers
    });
  }

  modificarAdmision(id: string, params: any, usuario: string) {
    return this.http.put(environment.urlMsSisnadhos + "/admision/" + id + "/" + usuario + "/usuario", params, {
      headers: this.headers
    });
  }

  public getRequestAdmision() {
    return this.requestAdmision;
  }

  public setRequestAdmision(requestAdmision: RequestAdmision) {
    this.requestAdmision = requestAdmision;
  }


  crearAdmisionAntecedentes(id: any, params: any, usuario: string) {
    return this.http.post(environment.urlMsSisnadhos + "/admision/" + id + "/antecedentesHospitalizacion/" + usuario + "/usuario", params, {
      headers: this.headers
    });
  }

  modificarAdmisionAntecedentes(id: string, params: any, usuario: string) {
    return this.http.put(environment.urlMsSisnadhos + "/admision/" + id + "/antecedentesHospitalizacion/" + usuario + "/usuario", params, {
      headers: this.headers
    });
  }
  obtenerAdmisionAntecedentes(id) {
    return this.http.get(environment.urlMsSisnadhos + "/admision/" + id + "/antecedentesHospitalizacion/", {
      headers: this.headers
    });
  }

  obtenerAdmisionAntecedentesFecha(id) {
    return this.http.get(environment.urlMsSisnadhos + "/admision/" + id + "/antecedentesHospitalizacionFecha/", {
      headers: this.headers
    });
  }

  previsiones(id) {
    return this.http.get(environment.urlMsSisnadhos + "/admision/" + id + "/antecedentesPrevisionales/", {
      headers: this.headers
    });
  }

  crearAdmisionPrevision(params: any, id: any, usuario: string) {
    return this.http.post(environment.urlMsSisnadhos + "/admision/" + id + "/antecedentesPrevisionales/" + usuario + "/usuario", params, {
      headers: this.headers
    });
  }

  modificarAdmisionPrevision(id: string, params: any, usuario: string) {
    return this.http.put(environment.urlMsSisnadhos + "/admision/" + id + "/antecedentesPrevisionales/" + usuario + "/usuario", params, {
      headers: this.headers
    });
  }

  modificarOCrearAntecedentesPrev(id: string, params: any) {
    return this.http.put(environment.urlMsAutoadhos + "/admision/" + id + "/actualizarCrear-antecedentesPrev/", params, {
      headers: this.headers
    });
  }

  consultarHoldings(params: any) {
    return this.http.post(environment.urlMsSisnadhos + "/holding", params, {
      headers: this.headers
    });
  }

  consultarProcedencia(ppn: any) {
    return this.http.get(environment.urlMsSisnadhos + "/integracion/fase2/" + ppn + "/procedencia", {
      headers: this.headers
    });
  }

  obtenerAdmisionPorRut(rut: string, idDoc: any) {
    return this.http.get(environment.urlMsSisnadhos + "/admision/documento/" + rut + '?idDocumento=' + idDoc, {
      headers: this.headers
    });
  }

  ObtenerPPN(rut: string) {
    return this.http.get(environment.urlMsSisnadhos + "/admision/busquedaPPN/" + rut, {
      headers: this.headers
    });
  
  }
  
  ObtenerAdmisionPPN(ppn:number) {
    return this.http.get(environment.urlMsSisnadhos + "/admision/busquedaAdmision/" + ppn, {
      headers: this.headers
    });
  }


  cargaCondEsp() {
    return this.http.get(environment.urlMsSisnadhos + "/prm-condicion-especial-ingreso/vigentes", {
      headers: this.headers
    });
  }

  cargarFichaCei(ppn: any, holding: any, esaCod: any, cmeCod: any) {
    return this.http.get(environment.urlMsSisnadhos + "/integracion/fase2/" + ppn + "/fichaClinicaSAC/holding/" + holding + "/esaCod/" + holding + "/cmeCod/" + cmeCod + "", {
      headers: this.headers
    });
  }

  setFichaCond(admision: any, params: any) {
    return this.http.post(environment.urlMsSisnadhos + "/admision/" + admision + "/fichaCondicionEspecial", params, {
      headers: this.headers
    });
  }

  getFichaCond(admision: any) {
    return this.http.get(environment.urlMsSisnadhos + "/admision/" + admision + "/fichaCondicionEspecial", {
      headers: this.headers
    });
  }

  obtenerTiposIngreso(idAdmision: any) {
    return this.http.get(environment.urlMsSisnadhos + "/admision/" + idAdmision + '/tiposDeIngreso', {
      headers: this.headers
    });
  }

  guardarTiposIngreso(params: any, idAdmision: any) {
    return this.http.post(environment.urlMsSisnadhos + "/admision/" + idAdmision + '/tiposDeIngreso', params, {
      headers: this.headers
    });
  }

  subcondicionIndicador(idAdmision: number, request: any) {
    return this.http.post(environment.urlMsSisnadhos + "/admision/" + idAdmision + '/subcondicion-indicador', request, {
      headers: this.headers
    });
  }

  modificarIndicadores(idAdmision: string, request: any) {
    return this.http.put(environment.urlMsSisnadhos + "/admision/" + idAdmision + '/indicadores-jerarquia', request, {
      headers: this.headers
    });
  }

  obtenerFichaPaciente(ppn: any, holding: any) {
    return this.http.get(`${environment.urlMsSisnadhos}/integracion/fase2/${ppn}/fichaPaciente/${holding}`);
  }

  obtenerCarpetaPaciente(ppn: any) {
    return this.http.get(`${environment.urlMsSisnadhos}/integracion/fase2/${ppn}/carpetaPaciente`);
  }

  obtenerPorCtaCte(id) {
    return this.http.get(environment.urlMsSisnadhos + "/admision/cuentaCorriente/" + id, {
      headers: this.headers
    });
  }

  obtenerCondicionEspecialPaciente(id) {
    return this.http.get(environment.urlMsSisnadhos + "/admision/" + id + "/detalleCondicionEspecial");
  }

  enviarCorreo(params: any, idAdmision: number, origen: string) {
    return this.http.post(environment.urlMsSisnadhos + "/correo-enviado/" + origen + "/origen/" + idAdmision + "/admisionId", params, {
      headers: this.headers
    });
  }

  correoEnviadoMarcar(id, params: any) {
    return this.http.post(environment.urlMsSisnadhos + "/admision/" + id + '/correo-enviado', params, {
      headers: this.headers
    });
  }

  correoEnviadoMarcarEditar(id, params: any) {
    return this.http.put(environment.urlMsSisnadhos + "/admision/" + id + '/correo-enviado', params, {
      headers: this.headers
    });
  }

  correoEnviadoConsulta(id) {
    return this.http.get(environment.urlMsSisnadhos + "/admision/" + id + "/correo-enviado");
  }

  obtenerIndicadoresCalugas(holding, ppn) {
    return this.http.get(environment.azureADConfig.authUrl.urlAppWidget + holding + "/holdingId/" + ppn + "/ppn");
  }

  obtenerCheckIn(idDoc: any) {
    return this.http.get(environment.urlMsSisnadhos + "/admision/admision-vigencia/" + idDoc + '/documentoNumero', {
      headers: this.headers
    });
  }

  obtenerUltimaAdmisionRango(documentoNumero: string, intervaloBusqueda: any) {
    return this.http.get(environment.urlMsSisnadhos + "/admision/ultima-admision/" + documentoNumero
      + '/' + intervaloBusqueda.llave + '/' + intervaloBusqueda.valor, {
      headers: this.headers
    });
  }

  accionCheckIn(id, accion) {
    return this.http.get(environment.urlMsAutoadhos + "/integracion/check-in/" + id + '/admisionId/' + accion + '/accion', {
      headers: this.headers
    });
  }


  obtenerRolesYPerfiles(params: any) {
    return this.http.post(environment.azureADConfig.authUrl.urlRoles, params);
  }

  guardarPermiso(params: any) {
    return this.http.post(environment.urlMsSisnadhos + "/admision/admision-permiso", params);
  }

  eliminarPermiso(user) {
    return this.http.delete(environment.urlMsSisnadhos + "/admision/admision-permiso/" + user + "/usuario");
  }

  obtenerPermisos(user) {
    return this.http.get(environment.urlMsSisnadhos + "/admision/admision-permiso/" + user + "/usuario");
  }

  obtenerAdmisionesVigentesCheckin(ppn) {
    return this.http.get(environment.urlMsSisnadhos + "/admision/admision-vigencia/" + ppn + "/ppn", {
      headers: this.headers
    });
  }

  obtenerAdmisionesFirmaPoder(fechaDesde: string, fechaHasta: string, requerimientoPoder: string, estadoInfoSalud?: string, ppn?: number) {
    let paramsSymbol = '?';
    let estInfoSaludPath = '';
    let ppnPath = '';

    if (estadoInfoSalud) {
      estInfoSaludPath = paramsSymbol + 'estadoInfoSalud=' + estadoInfoSalud;
      paramsSymbol = '&';
    }

    if (ppn) {
      ppnPath = paramsSymbol + 'ppn=' + ppn
    }

    return this.http.get(environment.urlMsSisnadhos + "/admision/admision-firma-poder/" + fechaDesde + "/fechaDesde/" + fechaHasta + "/fechaHasta/" + requerimientoPoder + "/requerimientoPoder" + estInfoSaludPath + ppnPath, {
      headers: this.headers
    });
    /*
    return this.http.get("http://localhost:80/v1" + "/admision/admision-firma-poder/" + fechaDesde + "/fechaDesde/" + fechaHasta + "/fechaHasta/" + requerimientoPoder + "/requerimientoPoder" + estInfoSaludPath + ppnPath, {
      headers: this.headers
    });*/
  }

  obtenerMenuAdmision() {
    return this.http.get(environment.urlMsSisnadhos + "/admision-menu/", {
      headers: this.headers
    });
  }

  obtenerAdmisionReporte() {
    return this.http.get(environment.urlMsSisnadhos + "/admision-reporte", {
      headers: this.headers
    });
  }

  activarIndicadorAService(params: any) {
    return this.http.post(environment.urlMsSisnadhos + "/indicador-a", params);
  }

  obtencionHojaIngreso(idAdmision) {
    return this.http.get(environment.urlMsSisnadhos + "/hoja-ingreso/admisionId/" + idAdmision);
  }

  obtenerAdmisionesRealizadas(fechaDesde: string, fechaHasta: string, ppn?: number) {
    let paramsSymbol = '?';
    let ppnPath = '';
    let fechaHastaPath = '';
    let fechaDesdePath = '';

    if (fechaDesde) {
      fechaDesdePath = paramsSymbol + 'fechaDesde=' + fechaDesde
      paramsSymbol = '&'
    }
    if (fechaHasta) {
      fechaHastaPath = paramsSymbol + 'fechaHasta=' + fechaHasta
      paramsSymbol = '&'
    }
    if (ppn) {
      ppnPath = paramsSymbol + 'ppn=' + ppn
    }

    return this.http.get(environment.urlMsSisnadhos + "/admision/admision-realizada/prueba" + fechaDesdePath + fechaHastaPath + ppnPath, {
      headers: this.headers
    });/*
    return this.http.get("http://localhost:80/v1" + "/admision/admision-realizada/prueba" + fechaDesdePath + fechaHastaPath + ppnPath, {
      headers: this.headers
    });*/
  }

  obtenerAdmisionesRealizadasDataExcel(fechaDesde: string, fechaHasta: string, ppn?: number) {
    let paramsSymbol = '?';
    let ppnPath = '';
    let fechaHastaPath = '';
    let fechaDesdePath = '';

    if (fechaDesde) {
      fechaDesdePath = paramsSymbol + 'fechaDesde=' + fechaDesde
      paramsSymbol = '&'
    }
    if (fechaHasta) {
      fechaHastaPath = paramsSymbol + 'fechaHasta=' + fechaHasta
      paramsSymbol = '&'
    }
    if (ppn) {
      ppnPath = paramsSymbol + 'ppn=' + ppn
    }

    return this.http.get(environment.urlMsSisnadhos + "/admision/admision-realizada/allData" + fechaDesdePath + fechaHastaPath + ppnPath, {
      headers: this.headers
    });
    /*
    return this.http.get("http://localhost:80/v1" + "/admision/admision-realizada/allData" + fechaDesdePath + fechaHastaPath + ppnPath, {
      headers: this.headers
    });*/

  }


  obtenerPlantillaCorreo(holding, plantilla) {
    return this.http.get(environment.urlMsSisnadhos + "/parametros-correo/" + holding + "/holdingId/" + plantilla + "/tipo-correo");
  }

  guardarImgHojaIngreso(id) {
    return this.http.post(environment.urlMsSisnadhos + "/hoja-ingreso/" + id + '/admisionId', {
      headers: this.headers
    });
  }

  consultaRespaldo(idAdmision) {
    return this.http.get(environment.urlMsSisnadhos + "/consulta-respaldo/admisionId/" + idAdmision);
  }

  guardarSegurosComplementarios(idAdmision: string, params: any) {
    return this.http.post(environment.urlMsAutoadhos + "/auto-admision/seguro-complementario-autoadm/" + idAdmision + "/seguros", params, {
      headers: this.headers
    });
  }


  obtenerSegurosComplementarios(idAdmision) {
    return this.http.get(environment.urlMsAutoadhos + "/auto-admision/" + idAdmision + "/seguros");
  }

  guardarAdmisionComunicacion(params: any) {
    return this.http.post(environment.urlMsSisnadhos + "/admision-comunicacion-cta-cte", params, {
      headers: this.headers
    });
  }

  obtenerAdmisionComunicacion(idAdmision) {
    return this.http.get(environment.urlMsSisnadhos + "/admision-comunicacion-cta-cte/idAdmision/" + idAdmision);
  }

  obtenerResumenAdmision(idAdmision) {
    return this.http.get(environment.urlMsSisnadhos + "/admision/resumen/" + idAdmision);
  }



}
