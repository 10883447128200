import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class LoaderService {

  private loadingSubject = new Subject<boolean>();
  private loading: boolean = false;

  setLoading(loading: boolean) {
    this.loading = loading;
    this.loadingSubject.next(loading);
  }

  getLoading(): Observable<boolean> {
    return this.loadingSubject.asObservable();
  }

  getLoadingAuto(): Observable<boolean> {
    return this.loadingSubject.asObservable();
  }
}