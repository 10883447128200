import { Injectable } from '@angular/core'

@Injectable()
export class Verificaciones {
    constructor() {
    }

    soloLetras(rut) {
        if (/^([a-zA-Z ñáéíóúÁÉÍÓÚ]{2,60})$/.test(rut)) {
            return true;
        } else {
            return false;
        }
    }


    soloNumero(rut) {
        return !isNaN(parseFloat(rut)) && isFinite(rut);
    }

    validRut(rut: string) {
        let resp = 0;
        const pos = rut.indexOf('-');
        const numero = rut.substr(0, pos);
        const digito = rut.substr(pos + 1, rut.length);
        if (numero.length >= 6 && numero.length <= 8) {
          
            const valid = this.detectAlfa(digito);
            if (valid === 1) {
                resp = 1;
            } else {
                resp = 0;
            }
        } else {
            resp = 0;
        }
        return resp;
    }

    detectAlfa(texto) {
        texto = texto.toLowerCase();
        const numeros = '0123456789';
        const letras = 'abcdefghijklmnñopqrstuvwxyz';
        let a = 0;
        for (let i = 0; i < texto.length; i++) {
            if (numeros.indexOf(texto.charAt(i), 0) !== -1) {
                a = 1;
            } else if (letras.indexOf(texto.charAt(i), 0) !== -1) {
                a = 1;
            } else {
                a = 0;
                return a;
            }
        }
        return a;
    }


    valida(rutCandidato) {
        let salida = 0;
        if (this.soloLetras(rutCandidato) === true) {
            salida = 2;
        } else if (this.soloNumero(rutCandidato) === true) {
            salida = 3;
        } else if (this.validRut(rutCandidato) === 1) {
            salida = 1;
        } else if (this.detectAlfa(rutCandidato) === 1) {
            salida = 3;
        } else {
            salida = 0;
        }
        return salida;
    }

}
