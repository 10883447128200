import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { environment } from "src/environments/environment";
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';

@Injectable({
    providedIn: 'root'
})
export class AutoAdmisionService {
    private headers: HttpHeaders;
    //private token:string="eyJhbGciOiJSUzI1NiIsInR5cCI6IkpXVCIsImtpZCI6IlFqTkdSVUUyT1VWRU1UQTJSVGt3TmpBd09EWTRNelE0UVRoRU1FSkVOVGd5T1RaQ1JFRXdRZyJ9.eyJpc3MiOiJodHRwczovL2FsZW1hbmEtcG9jLmF1dGgwLmNvbS8iLCJzdWIiOiJhZHxpZHNjZXJ0fDEyNzk3OTgxMiIsImF1ZCI6WyJodHRwczovL2FsZW1hbmEtcG9jLmF1dGgwLmNvbS9hcGkvdjIvIiwiaHR0cHM6Ly9hbGVtYW5hLXBvYy5hdXRoMC5jb20vdXNlcmluZm8iXSwiaWF0IjoxNzMwODIyNTI0LCJleHAiOjE3MzA5MDg5MjQsInNjb3BlIjoib3BlbmlkIHByb2ZpbGUgZW1haWwgcmVhZDpjdXJyZW50X3VzZXIgdXBkYXRlOmN1cnJlbnRfdXNlcl9tZXRhZGF0YSBkZWxldGU6Y3VycmVudF91c2VyX21ldGFkYXRhIGNyZWF0ZTpjdXJyZW50X3VzZXJfbWV0YWRhdGEgY3JlYXRlOmN1cnJlbnRfdXNlcl9kZXZpY2VfY3JlZGVudGlhbHMgZGVsZXRlOmN1cnJlbnRfdXNlcl9kZXZpY2VfY3JlZGVudGlhbHMgdXBkYXRlOmN1cnJlbnRfdXNlcl9pZGVudGl0aWVzIG9mZmxpbmVfYWNjZXNzIiwiZ3R5IjpbInJlZnJlc2hfdG9rZW4iLCJwYXNzd29yZCJdLCJhenAiOiJ4cExCZGQ2VTA3UWNwenE1TTd0bHFvWDJ1Qm9jeElJcyJ9.aqkhOZxR2sDoSqML8hojmXOMsJyenfWa9eg6g-tBAvNcP81E6cZekmZoY0FtHsaY3ZEUgrhbVtNYvPH-m-ytozQo_m_dwUk-Rax5MRXwG2aDARK1l9_lDvjxvzaBUICFX_9c0Vc0oq3S7b3-oY-K2yM1P9aRJT1iY_Aep1pjVd3S6XTe7ehk1BGoy7llSivUA3ZOiuQ8FYGII-J8itokEhtpWIGlZJwwvs_DRbNjZp1rGR6gtdOLlgH6d3VqY4pb08q9xBuBqHe_78txosf-fXxBJgmVZKa-T79ebwQo6JqkGXV2LyJkCFP_igVPMQTYSdk8BLPbj27jtHfDUiGCKQ";
    private token:string="";
    private param: HttpParams;
    constructor(private http: HttpClient) {
        this.init();
    }

    private async init(): Promise<void> {
        this.headers = new HttpHeaders({
            'Content-Type': 'application/json'
        });
        await this.waitForHash();
        this.param = new HttpParams().set('token', this.token);
    }

    private waitForHash(): Promise<void> {
        return new Promise((resolve) => {
            const interval = setInterval(() => {
                const tokenGlobal = (window as any).tokenGlobal;
                if (tokenGlobal) {
                    this.token = tokenGlobal;
                    clearInterval(interval);
                    resolve();
                }
            }, 100);
        });
    }

    apiDoc(params: any): Observable<any> {
        return this.http.post(environment.urlMsAutoadhos + '/auto-admision/api-doc', params);
    }


    obtenerAdmisionesVigentesCheckinHash(hash: string) {
        return this.http.get(environment.urlMsAutoadhos +  "/auto-admision/admision-vigencia/" + hash + "/ppnHash", {
            headers: this.headers,
            params: this.param
        });
    }


    ObtenerAdmisionPPNHash(ppn: number) {
        return this.http.get(environment.urlMsAutoadhos + "/auto-admision/busquedaAdmision/" + ppn, {
            headers: this.headers,
            params: this.param
        });
    }

    ultimaAdmisionPacienteFase2PpnHash(holdingId: any, ppn: any) { // revisar
        return this.http.get(environment.urlMsAutoadhos + '/auto-admision/pacienteUltimaAdmision/' + holdingId + '/holdingId/' + ppn + '/ppnHash'), {
            headers: this.headers,
            params: this.param
        }
    }

    crearResponHash(params: any, id: any) {
        return this.http.post(environment.urlMsAutoadhos + '/auto-admision/' + id + '/responsableDocumento', params, {
            headers: this.headers,
            params: this.param
        });
    }

    modificarResponHash(params: any, id: any) {
        console.log(params);
        console.log(id);
        return this.http.put(environment.urlMsAutoadhos + '/auto-admision/' + id + '/responsableDocumento', params, {
            headers: this.headers,
            params: this.param
        });
    }

    obtenerResponHash(id: any) {
        return this.http.get(environment.urlMsAutoadhos + '/auto-admision/' + id + '/responsableDocumento', {
            headers: this.headers,
            params: this.param
        });
    }

    crearAcompHash(params: any, id: any) {
        return this.http.post(environment.urlMsAutoadhos + '/auto-admision/' + id + environment.urlAcompanante, params, {
            headers: this.headers,
            params: this.param
        });
    }

    modificarAcompHash(params: any, id: any) {
        return this.http.put(environment.urlMsAutoadhos + '/auto-admision/' + id + environment.urlAcompanante, params, {
            headers: this.headers,
            params: this.param
        });
    }

    obtenerAcompHash(id) {
        return this.http.get(environment.urlMsAutoadhos + '/auto-admision/' + id + environment.urlAcompanante, {
            headers: this.headers,
            params: this.param
        });
    }

    cargarFileCola(params: any, hash: string) {
        params.ppnHash = hash;
        return this.http.post(environment.urlMsAutoadhos + `/auto-admision/crearDocumento/${hash}/ppnHash`, params, {
            headers: this.headers,
            params: this.param
        });
    }

    obtenerDocsManuales(ppnHash: any) {
        return this.http.get(environment.urlMsAutoadhos + '/auto-admision/' + ppnHash + '/obtener-documentos', {
            headers: this.headers,
            params: this.param
        });
    }

    crearFolder(params: any, hash: string) {
        params.ppnHash = hash;
        return this.http.post(environment.urlMsAutoadhos + `/auto-admision/crearFolder/${hash}`, params, {
            headers: this.headers,
            params: this.param
        });
    }

    obtenerEstadoFileCola(uid: any) {
        return this.http.get(environment.urlMsAutoadhos + `/auto-admision/${uid}/obtener-estado`, {
          headers: this.headers,
          params: this.param
        });
    }

    crearDirecciones(rq, ppn, token): Observable<any> {
        return this.http.post(environment.urlMsAutoadhos + "/auto-admision/integracion-cliente/" + ppn + '/direccion', rq, {
            headers: this.headers,
            params: this.param
        });
    }

    obtenerDirecciones(ppn, token?): Observable<any> {
        return this.http.get(environment.urlMsAutoadhos + "/auto-admision/integracion-cliente/" + ppn + '/direcciones', {
            headers: this.headers,
            params: this.param
        });
    }

    modificarDirecciones(rq, ppn, token): Observable<any> {
        return this.http.put(environment.urlMsAutoadhos + "/auto-admision/integracion-cliente/" + ppn + '/direcciones', rq, {
            headers: this.headers,
            params: this.param
        });
    }

    obtenerContactos(ppn, token?): Observable<any> {
        return this.http.get(environment.urlMsAutoadhos + "/auto-admision/integracion-cliente/" + ppn + '/contactos', {
            headers: this.headers,
            params: this.param
        });
    }

    modificarContacto(rq, ppn, token, tipo): Observable<any> {
        if (tipo == "put") {
            return this.http.put(environment.urlMsAutoadhos + "/auto-admision/integracion-cliente/" + ppn + '/contactos', rq, {
                headers: this.headers,
                params: this.param
            });
        } else {
            return this.http.post(environment.urlMsAutoadhos + "/auto-admision/integracion-cliente/" + ppn + '/contactos', rq, {
                headers: this.headers,
                params: this.param
            });
        }
    }

    modificarCliente(rq, ppn, token): Observable<any> {
        return this.http.put(environment.urlMsAutoadhos + "/auto-admision/integracion-cliente/" + ppn + '/cliente', rq, {
            headers: this.headers,
            params: this.param
        });
    }

    obtenerClientePorHash(documento: string, tipoDocumento: string): Observable<any> {
        /** TODO: Parametrizar el Holding cuándo se disponga de más de 1 **/
        return this.http.get(environment.urlMsAutoadhos + "/auto-admision/integracion-cliente/" + documento + '?holdingId=' + 2 + '&tipoDocumento=' + tipoDocumento, {
            headers: this.headers,
            params: this.param
        });
    }
    /**ppn del body debe estar hasheado */
    obtenerPabellonDisponible(idAdmision: number, body: any) {
        return this.http.post(environment.urlMsAutoadhos + `/auto-admision/${idAdmision}/pabellon-disponible`, body, {
            headers: this.headers,
            params: this.param
        });
    }

    /** clon de obtener antecedente prev de nva adm, pero sin ppn */
    previsiones(id) {
        return this.http.get(environment.urlMsAutoadhos + "/auto-admision/" + id + "/antecedentesPrevisionales/", {
            headers: this.headers,
            params: this.param
        });
    }

    /** clon de crear nva adm */
    crearAdmision(params: any, usuario: string) {
        return this.http.post(environment.urlMsAutoadhos + "/auto-admision/" + usuario + "/usuario", params, {
            headers: this.headers,
            params: this.param
        });
    }

    /** clon de modificar nva adm */
    modificarAdmision(id: string, params: any, usuario: string) {
        return this.http.put(environment.urlMsAutoadhos + "/auto-admision/" + id + "/" + usuario + "/usuario", params, {
            headers: this.headers,
            params: this.param
        });
    }

    actualizarAutoadmsion(idAdmision: string, body: any): Observable<any> {
        return this.http.post(environment.urlMsAutoadhos + `/auto-admision/${idAdmision}/actualizar`, body, {
            headers: this.headers,
            params: this.param
        });
    }

    profesionalesServices(params:any): Observable<any>{
        return this.http.get(environment.urlMsAutoadhos +"/auto-admision/profesional?fullText=" + params, {
          headers: this.headers,
          params: this.param
        });
      }

      /** este método se usa en el front de nueva admisión, y es invocado desde el back de sisnadhos */
      obtenerAdmisionesRealizadas(fechaDesde: string, fechaHasta: string, ppn?: number) {
        let paramsSymbol = '?';
        let ppnPath = '';
        let fechaHastaPath = '';
        let fechaDesdePath = '';

        if (fechaDesde) {
          fechaDesdePath = paramsSymbol + 'fechaDesde=' + fechaDesde
          paramsSymbol = '&'
        }
        if (fechaHasta) {
          fechaHastaPath = paramsSymbol + 'fechaHasta=' + fechaHasta
          paramsSymbol = '&'
        }
        if (ppn) {
          ppnPath = paramsSymbol + 'ppn=' + ppn
        }

        return this.http.get(environment.urlMsAutoadhos + "/auto-admision/admision-realizada/prueba" + fechaDesdePath + fechaHastaPath + ppnPath, {
          headers: this.headers,
          params: this.param
        });
      }

    obtenerClienteAutoadmision(idAdm: number){
        return this.http.get( environment.urlMsAutoadhos+ "/auto-admision/obtener-cli/" + idAdm,{
            headers: this.headers,
            params: this.param
        });
    };

    modificarClienteAutoadmision(idAdm: number, body: any): Observable<any> {
        return this.http.post(environment.urlMsAutoadhos + "/auto-admision/crear-cli/"+ idAdm, body, {
            headers: this.headers,
            params: this.param
        });
    }

    /** Crea o actualiza un registro en la tabla ahp_agenda_pabellon */
    actualizarOCrearPabellon(idAdm: number, body: any): Observable<any> {
        return this.http.post(environment.urlMsAutoadhos + `/auto-admision/${idAdm}/actualizarOCrearPabellon`, body, {
            headers: this.headers,
            params: this.param
        });
    }

    //Estos métodos consultan al backend para consultar con el token si es que tiene acceso o no.
    consultarInformacionUsuario(): Observable<any> {
        return this.http.get(environment.urlMsAutoadhos + "/auto-admision/get-access/", {
          headers: this.headers,
          params: this.param
        }).pipe(
          catchError((error) => {
            console.error('Error al consultar información del usuario:', error);
            return throwError(() => new Error('Error al consultar acceso'));
          })
        );
      }

    obtenerInfoPaciente():Observable<any> {
        return this.http.get(environment.urlMsAutoadhos + "/auto-admision/infoPaciente", {
            headers: this.headers,
            params: this.param
        });
    }


    declaracionAccAutoAdm(admision: string, isapre: string): Observable<any> {
        return this.http.get(environment.urlMsAutoadhos + `/auto-admision/${admision}/isapre/${isapre}`, {
            headers: this.headers,
        });
    }


}
