import { Component, Input, OnInit, Output } from '@angular/core';
import { SharedAutoAdmisionService } from '../../../servicios/autoAdmision/shared-auto-admision.service';

@Component({
  selector: 'app-stepper',
  templateUrl: './stepper.component.html',
  styleUrls: ['./stepper.component.css']
})
export class StepperComponent implements OnInit {
  
  paso: number;
  constructor(public sharedService: SharedAutoAdmisionService
  ) {

   }

  ngOnInit(): void {
    console.log('paso', this.paso)
    this.sharedService.getStepperEmitter().subscribe((paso: number) => 
      {console.log('paso', paso) 
        this.paso = paso;
      });
  
  }

}
