<div *ngIf="screenType !== 'mobile'">
    <!-- Encabezado Desktop/Tablet-->
    <div class="row align-items-center justify-content-between px-4 px-lg-5" style="padding-top: 2rem;">
        <div class="col-12 d-flex flex-row align-items-center justify-content-between">
            <div class="col-auto">
                <cas-text variant="button" class="custom-text">
                    <span class="checkin-title-desktop">Check-in de admisión</span>
                </cas-text>
            </div>
            <div class="col-auto" *ngIf="!cargandoDatos">
                <cas-button text="Hacer check-in" variant="primary1" size="small" (click)="nuevaAutoAdmision()"></cas-button>
            </div>
        </div>
    </div>
    <!-- Contenido EMPTY O CHECKINS -->
    <ng-container *ngIf="listaCheckIn?.length === 0 && !cargandoDatos; else listaCheckInTemplate"><!---->
        <div class="d-flex flex-column align-items-center justify-content-center" style="height: 80vh;">
            <img class="img-fluid" [src]="imgEmpty" [alt]="msjSinCheckin" style="height: 30vh;">
            <div class="col-auto px-4 py-3">
                <cas-text style="text-align: center;" variant="headline4">{{msjSinCheckin}}</cas-text>
            </div>
        </div>
    </ng-container>
    <!-- FIN Contenido EMPTY O CHECKINS -->
</div>

<div *ngIf="screenType === 'mobile'">
    <!-- Encabezado Mobile -->
    <div class="d-flex align-items-center justify-content-start ps-3" style="padding-top: 2rem;">
        <div class="col-12 d-flex flex-row align-items-center justify-content-between pe-2">
            <div class="col-auto">
                <cas-text variant="button" class="custom-text" *ngIf="listaCheckIn?.length ===0">
                    <span>Check-in de admisión</span>
                </cas-text>
                <cas-text variant="button" class="custom-text" *ngIf="listaCheckIn?.length >0">
                    <span>Check-in de<br>admisión</span>
                </cas-text>
            </div>
            <div class="col-auto" *ngIf="listaCheckIn?.length >0"><!---->
                <cas-button text="Hacer check-in" variant="primary1" size="small" (click)="nuevaAutoAdmision()"></cas-button>
            </div>
        </div>
    </div>
    <!-- Contenido EMPTY O CHECKINS -->
    <ng-container *ngIf="listaCheckIn?.length === 0 && !cargandoDatos; else listaCheckInTemplate"><!---->
        <div class="d-flex flex-column align-items-center justify-content-center" style="height: 80vh;">
            <img class="img-fluid" [src]="imgEmpty" [alt]="msjSinCheckin">
            <div class="col-10 px-4 py-1">
                <cas-text style="text-align: center;" variant="headline4">{{msjSinCheckin}}</cas-text>
            </div>
            <div class="col-auto py-3">
                <cas-button text="Hacer check-in" variant="primary1" size="small" (click)="nuevaAutoAdmision()"></cas-button>
            </div>
        </div>
    </ng-container>
    <!-- FIN Contenido EMPTY O CHECKINS -->
</div>

<!-- Lista de check-ins -->
<ng-template #listaCheckInTemplate>
    <div class="row" style="padding-bottom: 4rem;">
        <div class="col-lg-12 px-4 px-lg-5">
            <div *ngFor="let item of listaCheckIn" id="card-{{item?.id}}" class="cardCheck row mb-3 bg-white">
                <div class="col-12 checkin-card p-3">
                    <div class="row d-none d-lg-flex align-items-center">
                        <div class="col-lg-3 textMiddle motivoValor" style="color: #01A49A">
                            <span *ngIf="(item?.motivoValor!==null); else sinMotivoTemplate">{{item?.motivoValor}}</span>
                        </div>
                        <div class="col-lg-3">
                            <div class="text-start" style="color: #364855; font-size: 14px; text-align: left!important;">
                                <span *ngIf="(!!item?.medicoTratanteNombre); else elseTemplate">{{item?.medicoTratanteNombre}}</span>
                            </div>
                            <mat-divider *ngIf="screenType === 'mobile'"></mat-divider>
                            <!--
                            <div class="textMiddle medicoTratante" style="color: #63717B; font-size: 14px;">
                                {{item?.fechaIngresoText}}
                                <span *ngIf="(item?.fechaIngresoText!==null); else elseTemplate"></span>
                            </div>-->
                        </div>
                        <div class="col-lg-3">
                            <div>
                                <span style="color:#364855;">Estado:</span>
                            </div>
                            <div>
                                <span [ngClass]="estadoAutoAdmision(item).clase">
                                    {{estadoAutoAdmision(item).texto}}
                                </span>
                            </div>
                        </div>
                        <div class="col-lg-3 d-flex justify-content-end align-items-center">
                            <div class="d-flex align-items-center mr-6 icon-container">
                                <app-icon-ds class="iconButton" iconName="pencil" size="16" style="color:#01736C !important;cursor:pointer" (click)="editarCheckIn(item)"></app-icon-ds>
                                <span class="ml-2" style="cursor:pointer" (click)="editarCheckIn(item)">Editar</span>
                            </div>
                            <div class="d-flex align-items-center icon-container" *ngIf="!item?.estadoAutoadmision">
                                <app-icon-ds class="iconButton" iconName="trash" size="16" style="color:#01736C !important" (click)="eliminarCheckIn(item?.id)"></app-icon-ds>
                                <span class="ml-2" (click)="eliminarCheckIn(item?.id)">Eliminar</span>
                            </div>
                        </div>
                    </div>
                    <div class="row d-lg-none">
                        <div class="col-12 textMiddle motivoValor" style="color: #01A49A">
                            <span *ngIf="(item?.motivoValor!==null); else sinMotivoTemplate">{{item?.motivoValor}}</span>
                        </div>
                        <div class="col-12 textMiddle medicoTratante" style="color: #364855; font-size: 14px;">
                            <span *ngIf="(!!item?.medicoTratanteNombre); else elseTemplate">{{item?.medicoTratanteNombre}}</span>
                        </div>
                        <!--
                        <div class="col-12 textMiddle medicoTratante" style="color: #4D5D68; font-size: 14px;">Fecha de Ingreso:</div>
                        <div class="col-12 textMiddle medicoTratante" style="color: #63717B; font-size: 14px;">
                            <span *ngIf="(item?.fechaIngresoText!==null); else elseTemplate">{{item?.fechaIngresoText}}</span>
                        </div>-->
                        <div class="col-12 estado-container textMiddle" style="color: #364855;">
                            <div>
                                <span>Estado:</span>
                                <span [ngClass]="estadoAutoAdmision(item).clase">
                                    {{estadoAutoAdmision(item).texto}}
                                </span>
                            </div>
                        </div>
                        <div class="col-12 d-flex justify-content-end align-items-center">
                            <div class="d-flex align-items-center mr-6 icon-container">
                                <app-icon-ds class="iconButton" iconName="pencil" size="16" style="color:#01736C !important;" (click)="editarCheckIn(item)"></app-icon-ds>
                                <span class="ml-2">Editar</span>
                            </div>
                            <div class="d-flex align-items-center icon-container" *ngIf="!item?.estadoAutoadmision">
                                <app-icon-ds class="iconButton" iconName="trash" size="16" style="color:#01736C !important;" (click)="eliminarCheckIn(item?.id)"></app-icon-ds>
                                <span class="ml-2">Eliminar</span>
                            </div>
                        </div>
                    </div>
                    <ng-template #sinMotivoTemplate>
                        <span>Sin información</span>
                    </ng-template>
                    <ng-template #elseTemplate>
                        <span>--</span>
                    </ng-template>
                </div>
            </div>
        </div>
    </div>
</ng-template>
<!--FIN  Lista de check-ins -->

<!--OLD MODAL CTA CTE ABIERTA -->
<div class="body-cas" *ngIf="cuentaCorrieteAbierta">
    <cas-modal width="550px" open="true">
        <cas-text variant="display3">Hospitalización Vigente</cas-text> 
        <br>
        <cas-text variant="headline5">Hola {{nombrePaciente}}, ¡lo sentimos! No puedes avanzar con la autoadmisión, ya que tienes una hospitalización activa.</cas-text>
        <div class="top-right-icon">
            <cas-icon name="flat-close" size="16" (click)="redireccionamiento()"></cas-icon>
        </div>
        <div class="bottom">
            <div class="separator"></div> 
            <button class="custom-button" (click)="redireccionamiento()">Aceptar</button>
        </div> 
    </cas-modal>
</div>