import { Component, EventEmitter,Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-search-ds',
  templateUrl: './search-ds.component.html',
  styleUrls: ['./search-ds.component.scss']
})
export class SearchDsComponent implements OnInit {

  constructor() { }
  @Input () placeholder: string = "Search";
  @Input () size: string = "Fourth action";
  @Input () iconPosition: string = "Fourth action";

  left: boolean = false;
  right: boolean = false;
  both: boolean = false;
  special: boolean = false;

  ngOnInit(): void {
    if(this.iconPosition === 'left'){
      this.left = true;
    }
    if(this.iconPosition === 'right'){
      this.right = true;
    }
    if(this.iconPosition === 'both'){
      this.both = true;
    }
    if(this.iconPosition === 'special'){
      this.special = true;
    }
  }

}
