import { Component, OnInit, Output, ViewChild } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { SharedAutoAdmisionService } from 'src/app/servicios/autoAdmision/shared-auto-admision.service';
import { filter } from 'rxjs/operators'; 
import { LoaderService } from 'src/app/servicios/loader/loader.service';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ClienteService } from 'src/app/servicios/cliente/cliente.service';

@Component({
  selector: 'app-auto-admision',
  templateUrl: './auto-admision.component.html',
  styleUrls: ['./auto-admision.component.css']
})
export class AutoAdmisionComponent{

  formDatosPersonales: FormGroup;
  mostrarAntecedentes: boolean = false;
  mostrarPaso1: boolean = false;
  mostrarPaso2: boolean = false;
  mostrarPaso3: boolean = false;

  constructor(
    private router: Router, 
    private activatedRoute: ActivatedRoute,
    private sharedAutoAdmisionService: SharedAutoAdmisionService,
    private loadingService: LoaderService,
    private sharedService: SharedAutoAdmisionService,
    private fb: FormBuilder,
    private clienteService: ClienteService
  ) {
    this.router.events.pipe(
      filter(event => event instanceof NavigationEnd)
    ).subscribe(() => {
      this.checkRoute();
    });

    this.checkRoute();
    this.toggleEstilo();
    this.loadingService.setLoading(false);
  }



  toggleEstilo() {
    this.sharedAutoAdmisionService.toggleEstilo(false);
  }


  checkRoute() {
    const currentRoute = this.activatedRoute.snapshot.firstChild?.routeConfig?.path;
    this.mostrarAntecedentes = (currentRoute === 'antecedentesHospitalizacion');
    this.mostrarPaso1 = (currentRoute === 'paso1');
    this.mostrarPaso2 = (currentRoute === 'paso2');
    this.mostrarPaso3 = (currentRoute === 'paso3');
  }

  button(){
    this.loadingService.setLoading(true);
  }
}
