import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class CuentaCorrienteService {
  private headers: HttpHeaders;

  constructor(private http: HttpClient) {
    this.headers = new HttpHeaders();
    this.headers = this.headers.append('Content-Type', 'application/json');
    this.headers = this.headers.append('Access-Control-Allow-Origin', '*');
    this.headers = this.headers.append('Access-Control-Allow-Methods', 'OPTIONS,POST,GET');
    this.headers = this.headers.append('Access-Control-Allow-Headers', 'Origin, Content-Type, X-Requested-With');
   }

  numeroCuentaCorriente(idAdmision:any):Observable<any>{
    return this.http.post(environment.urlMsSisnadhos + '/integracion/fase2/admisionId/' + idAdmision,{},{
      headers: this.headers
    })
  }

  numeroCuentaCorrientesSendJson(json:any):Observable<any>{
    return this.http.post("http://10.62.33.106:7011/prueba-cas/sonda",json,{
      headers: this.headers
    })
  }

  ultimaAdmisionPacienteFase2(holdingId:any, ppn:any){
    return this.http.get(environment.urlMsSisnadhos + '/integracion/fase2/pacienteUltimaAdmision/'+ holdingId + '/holdingId/' + ppn + '/ppn')
  }

  modificarAdmisionFase(idAdmision:any){
    return this.http.get(environment.urlMsSisnadhos + '/integracion/fase2/modificarAdmisionFase2/'+ idAdmision + '/admisionId');
  }
}
