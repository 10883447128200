import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AntecedentesHospitalizacionComponent } from './componentes/autoAdmision/antecedentes-hospitalizacion/antecedentes-hospitalizacion.component';
import { Paso1AutoAdmisionComponent } from './componentes/autoAdmision/paso1-auto-admision/paso1-auto-admision.component';
import { Paso2AutoAdmisionComponent } from './componentes/autoAdmision/paso2-auto-admision/paso2-auto-admision.component';
import { AutoAdmisionComponent } from './componentes/autoAdmision/auto-admision.component';
import { StepperComponent } from './componentes/autoAdmision/stepper/stepper.component';
import { Paso3AutoAdmisionComponent } from './componentes/autoAdmision/paso3-auto-admision/paso3-auto-admision.component';

const routes: Routes = [
  { path: 'autoAdmision', component:AutoAdmisionComponent, children: [
    { path: 'antecedentesHospitalizacion', component:AntecedentesHospitalizacionComponent},
    { path: 'paso1', component:Paso1AutoAdmisionComponent},
    { path: 'paso2', component:Paso2AutoAdmisionComponent},
    { path: 'paso3', component:Paso3AutoAdmisionComponent},
    { path: 'stepper', component:StepperComponent}
  ],
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }

