import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-checkbox-ds',
  templateUrl: './checkbox-ds.component.html',
  styleUrls: ['./checkbox-ds.component.scss']
})

export class CheckboxDsComponent implements OnInit {

  @Input () disabled: boolean = false;
  @Input () checked: boolean = false;
  @Input () indeterminate: boolean = false;
  @Input () size: string="";
  @Input () labelsize: string="";
  @Input () label: string = "Default";
  @Input () name: string = "Name";

  constructor() { }

  ngOnInit(): void {
  }

}
