import { Pais } from "./pais.model";
import { Ciudad } from "./ciudad.model";
import { Comuna } from "./comuna.model";
import { Region } from "./region.model";

export class Contacto {
    direccion: string;
    pais: Pais;
    ciudad: Ciudad;
    comuna: Comuna;
    region: Region;
    email: string;
    telefonoCelular:string;
    telefonoFijo?:string;
  

    //Inicializa una instancia de la clase TextoLibre
    constructor() {
        this.direccion = "";
        this.pais =   { id: 0, nombre: null };
        this.ciudad = { id: 0, nombre: null };
        this.comuna = { id: 0, nombre: null };
        this.region = { id: 0, nombre: null };
        this.email = "";
        this.telefonoCelular = "";
        this.telefonoFijo = "";
    }

}

 export interface ContactInfo {
    classification: GenericType;
    type: GenericType ;
    value: string;
    status: string;
  }

  export interface GenericType {
    id: number;
    description: string;
  }