<div class="form-check">
    
    <input type="checkbox" name="{{name}}" class="form-check-input check-alemana" [indeterminate]="indeterminate" [disabled]="disabled"  [checked]="checked"  id="myCheckbox" [ngClass]="{'size-3': size === 's3', 'size-2': size === 's2', 'size-1': size === 's1'}"  >
    <label [ngClass]="{'custom-label-size3': labelsize === 's3', 'custom-label-size2': labelsize === 's2', 'custom-label-size1': labelsize === 's1'}">
        {{label}}
    </label>
    <script>
        var checkbox = document.getElementById("myCheckbox");
        checkbox.indeterminate = true
    </script>
</div>