
<div *ngIf="size !== 'small'">
    <div [ngClass]="{'input-group': iconName !=='', 'position-wrap':iconName !=='' && iconPosition ==='right'}">
        <span *ngIf="iconName !=='' && iconPosition ==='left'" class="input-icon" id="basic-addon24"
              [ngClass]="{
                    'icon-size':iconName !=='' && iconPosition ==='left' && size === 'regular',
                    'disabled-input': disabled == 'true',
                    'input-error': state === 'error',
                    'hospitalizados': variant === 'hospitalizados',
                    'urgencias': variant ==='urgencias',
                    'ambulatorios': variant ==='ambulatorios',
                    'message-valid': messageValid !=='',
                    'message-invalid': messageInvalid !==''}">

              <i [class]="classIcon"></i>
        </span>
        <div class="form-floating" [ngClass]="{'full-input-left': iconName !=='' && iconPosition ==='left','full-input-right': iconName !=='' && iconPosition ==='right', 'full-input-right-regular': iconName !=='' && iconPosition ==='right' && size === 'regular', 'icon-margin':iconName !=='' && iconPosition ==='left' && size === 'regular'}">
            <input
                    [attr.maxLength] = "maxlengthDs"
                    class="form-control input-input"
                    [id]="idDs"
                    placeholder=""
                    (keyup)="onKey($event)"
                    (change)="onTextChange($event)"
                    [disabled]="disabled == 'true'"
                    [value]="value"
                    [type]="type"
                    [ngClass]="{
                    'disabled-input': disabled == 'true',
                    'input-error': state === 'error',
                    'hospitalizados': variant === 'hospitalizados',
                    'urgencias': variant ==='urgencias',
                    'ambulatorios': variant ==='ambulatorios',
                    'regular': size === 'regular',
                    'medium': size === 'medium',
                    'message-valid': messageValid !=='',
                    'message-invalid': messageInvalid !=='',
                    'input-icon-input':iconName !=='' && iconPosition ==='left',
                    'input-icon-input-right':iconName !=='' && iconPosition ==='right'}"
            >
            <label class="input-label" for="floatingInput" [ngClass]="{
           'label-medium': size === 'medium'}">
                {{initialLabel}}
            </label>
            <div *ngIf="message !==''" class="message-input">
                {{message}}
            </div>
            <div *ngIf="messageValid !==''" class="valid-input">
                {{messageValid}}
            </div>
            <div *ngIf="messageInvalid !==''" class="invalid-input">
                {{messageInvalid}}
            </div>
        </div>
        <span *ngIf="iconName !=='' && iconPosition ==='right'" class="input-icon" id="basic-addon25"
              [ngClass]="{
                    'icon-size':iconName !=='' && iconPosition ==='right' && size === 'regular',
                    'disabled-input': disabled == 'true',
                    'input-error': state === 'error',
                    'hospitalizados': variant === 'hospitalizados',
                    'urgencias': variant ==='urgencias',
                    'ambulatorios': variant ==='ambulatorios',
                    'message-valid': messageValid !=='',
                    'message-invalid': messageInvalid !==''}">

               <i [class]="classIcon"></i>
        </span>
    </div>

</div>

<div *ngIf="size === 'small'">

    <label for="exampleFormControlInput1" class="form-label label-small"> {{initialLabel}}</label>
    <div [ngClass]="{'input-group': iconName !==''}">
        <span *ngIf="iconName !=='' && iconPosition ==='left'"  class="input-group-text icon-input" id="basic-addon1" [ngClass]="{
                'disabled-input': disabled == 'true',
                'input-error': state === 'error',
                'hospitalizados': variant === 'hospitalizados',
                'urgencias': variant ==='urgencias',
                'ambulatorios': variant ==='ambulatorios',
                'message-valid': messageValid !=='',
                'message-invalid': messageInvalid !==''}">

                <i [class]="classIcon"></i>

        </span>
        <input
                class="form-control form-control-sm input-small"
                [attr.maxLength] = "maxlengthDs"
                [id]="idDs"
                (keyup)="onKey($event)"
                (change)="onTextChange($event)"
                [disabled]="disabled == 'true'"
                [placeholder]="placeholder"
                [value]="value"
                [type]="type"
                [ngClass]="{
                'disabled-input': disabled == 'true',
                'input-error': state === 'error',
                'hospitalizados': variant === 'hospitalizados',
                'urgencias': variant ==='urgencias',
                'ambulatorios': variant ==='ambulatorios',
                'message-valid': messageValid !=='',
                'message-invalid': messageInvalid !==''}"
        >
        <span *ngIf="iconName !=='' && iconPosition ==='right'"  class="input-group-text icon-input" id="basic-addon2" [ngClass]="{
                'disabled-input': disabled,
                'input-error': state === 'error',
                'hospitalizados': variant === 'hospitalizados',
                'urgencias': variant ==='urgencias',
                'ambulatorios': variant ==='ambulatorios',
                'message-valid': messageValid !=='',
                'message-invalid': messageInvalid !==''}">

                <i [class]="classIcon"></i>

        </span>
        <div *ngIf="message !==''" class="message-input">
            {{message}}
        </div>
        <div *ngIf="messageValid !==''" class="valid-input">
            {{messageValid}}
        </div>
        <div *ngIf="messageInvalid !==''" class="invalid-input">
            {{messageInvalid}}
        </div>
    </div>

</div>

