import { Injectable } from '@angular/core'

@Injectable()
export class Filtros{
    constructor(){
    }

    formatearRut(rut:any){
        if (!rut) {
            return '';
        }
        var sRut;
            var sRutFormateado = '';
            var strRut = rut.replace(/^0+/, '');
            strRut = strRut.replace(/[-|\.]/g, '');
            sRut = strRut;
            var sDV = sRut.charAt(sRut.length - 1);
            sRut = sRut.substring(0, sRut.length - 1);
            while (sRut.length > 3) {
                sRutFormateado = "." + sRut.substr(sRut.length - 3) + sRutFormateado;
                sRut = sRut.substring(0, sRut.length - 3);
            }
            sRutFormateado = sRut + sRutFormateado;
            sRutFormateado += "-" + sDV;

            return sRutFormateado;
    }

    formatearFecha(date:any){

        if (!date) {
            return '';
        }
    }


    quitarPuntos(rut:string) {
        return rut.replace(/[\.]/g, '');
    }


    validarVacio(elemento:any)
    {
        if(isNaN(elemento)){
            return false;
        }
        else{
            return true;
        }
    }

    validarNumerico(numero:number){
        if((!numero && numero !== 0) || isNaN(numero)){
            return false;
        }
        else{
            return true;
        }
    }
    
    validarEmail(email:string)
    {
        if (/^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i.test(email)){
          return true;
        } 
        else 
        {
          return false;
        }
    }

    calcularEdad(fecha) {
        var hoy = new Date();
        var cumpleanos = new Date(fecha);
        var edad = hoy.getFullYear() - cumpleanos.getFullYear();
        var m = hoy.getMonth() - cumpleanos.getMonth();
        if (m < 0 || (m === 0 && hoy.getDate() < cumpleanos.getDate())) {
            edad--;
        }
        return edad;
    }

    obtenerDigitoVerificador(rut:string):string 
    {  
      let T = parseInt(rut)
      var M=0,S=1;
	  for(;T;T=Math.floor(T/10))
      S=(S+T%10*(9-M++%6))%11;
      
      return S?(S-1).toString():'k';
    }

    idInputMaxLength(e, max) {
        let selector = document.querySelector('#' + e.currentTarget.id + ' div input') as HTMLFormElement;
    
        if (selector) {
            let finalValue = '';
            let selectorArray = selector.value.split('');
            for (let index = 0; index < selectorArray.length; index++) {
                if (selector.value.length > max) {
                    finalValue = selector.value.slice(0, -1);
                    selector.value = finalValue;
                }
            }
        }
    }

      convertirFechaAIso(fechaString:string){
        // Crear un objeto de fecha a partir de la cadena de fecha y hora
        const fecha = new Date(fechaString);

        // Obtener los componentes de fecha y hora
        const year = fecha.getFullYear();
        const month = ("0" + (fecha.getMonth() + 1)).slice(-2);
        const day = ("0" + fecha.getDate()).slice(-2);
        const hour = ("0" + fecha.getHours()).slice(-2);
        const minute = ("0" + fecha.getMinutes()).slice(-2);
        const second = ("0" + fecha.getSeconds()).slice(-2);

        // Construir la cadena en formato ISO 8601
        const isoString = `${year}-${month}-${day}T${hour}:${minute}:${second}Z`;

        return isoString; // "2023-03-02T11:06:00Z"
      }

}
