import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { Observable } from 'rxjs';
import { stringify } from 'querystring';



@Injectable({
  providedIn: 'root'
})
export class ListaValoresServices {
  private headers: HttpHeaders;

  constructor(private http: HttpClient) {
    this.headers = new HttpHeaders();
    this.headers = this.headers.append('Content-Type', 'application/json');
  }


  // ObtenerSucursalesServices(holding) {
  //     return this.http.post(environment.urlMsSisnadhos + environment.urlSucursales+"/"+ holding, {
  //       headers: this.headers
  //     });
  // }

  ObtenerSucursalesServices(params:any) {
    return this.http.get(environment.urlMsAutoadhos + environment.urlSucursales + '?holdingId='+ params, {
      headers: this.headers
    });
  }

  obtenerSucursalHos(idSucursal, holding) {
    return this.http.get(environment.urlMsSisnadhos + "/admision-sucursal/obtener-sucursal/" + idSucursal + '/sucursalId/'+ holding + '/holdingId', {
      headers: this.headers
    });
  }

  // obtenerMotivoInformacionMedica() : Observable<Object> {
  //     return this.http.get(environment.urlMsSisnadhos +"/motivo", {
  //       headers: this.headers
  //     });

  // }

  // obtenerCotizanteInformacionPrevisional() {
  //   return this.http.get(environment.urlMsSisnadhos +"/cotizante", {
  //     headers: this.headers
  //   });
  // }

  // obtenerSegurosInformacionPrevisional() {
  //   return this.http.get(environment.urlMsSisnadhos +"/seguro", {
  //     headers: this.headers
  //   });
  // }

  // obtenerIsapresPost(){
  //   let request = { srvMessage :"" }
  //   return this.http.post( environment.urlMsSisnadhos + environment.urlIsapres, request , {
  //     headers: this.headers,
  //   });
  // }

  obtenerIsapres() : Observable<any> {
    return this.http.get( environment.urlMsSisnadhos + environment.urlIsapres ,{
      headers: this.headers,
    });
  }
  
  obtenerInfoPaciente() : Observable<any> {
    return this.http.get( environment.urlMsSisnadhos + "/integracion/parametroGeneralClinica/infoPaciente",{
      headers: this.headers,
    });
  }
  // obtenerTipoIngreso() : Observable<any> {
  //   return this.http.get( environment.urlMsSisnadhos + '/tipo-ingreso', {
  //     headers: this.headers,
  //   });
  // }

  obtenerParametrosGenerales(param:string,holdingId:string,sucursalId:string): Observable<any> {
    return this.http.get( environment.urlMsAutoadhos + '/prm-admision/' + param + '?holdingId='  + holdingId + '?sucursalId=' + sucursalId, {
      headers: this.headers,
    });
  }
}
