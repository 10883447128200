import { Component, OnInit } from '@angular/core';
import { LoaderService } from 'src/app/servicios/loader/loader.service';

@Component({
  selector: 'app-loader',
  templateUrl: './loader.component.html',
  styleUrls: ['./loader.component.css']
})
export class LoaderComponent implements OnInit {
  loadingAuto: boolean = false;

  constructor(public loaderAuto: LoaderService) { }

  ngOnInit(): void {
    this.loaderAuto.getLoadingAuto().subscribe((value) => {
      this.loadingAuto = value;
   });
  }



}
