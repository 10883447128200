import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';

@Component({
  selector: 'app-input-ds',
  templateUrl: './input-ds.component.html',
  styleUrls: ['./input-ds.component.scss']
})
export class InputDsComponent implements OnInit {

  @Input() initialLabel: string = "Label";
  @Input() disabled: any = false;
  @Input() state: string = "none";
  @Input() placeholder: string = "";
  @Input() value: string = "";
  @Input() type: string = "text";
  @Input() variant: string = "default";
  @Input() size: string = "regular";
  @Input() message: string = "";
  @Input() messageValid: string = "";
  @Input() messageInvalid: string = "";
  @Input() iconName: string = "";
  @Input() iconPosition: string = "left";
  @Input() idDs: string = "";
  @Input() maxlengthDs: string = "";

  @Output () onInputText: EventEmitter<string> = new EventEmitter();
  @Output () onChange: EventEmitter<string> = new EventEmitter();

  classIcon: string = "";

  constructor() { }

  ngOnInit(): void {
    //this.disabled = this.disabled === "true";
    this.classIcon = "bi bi-"+this.iconName;
  }

  onKey(event: Event) {
    // @ts-ignore
    this.onInputText.emit(event.target.value);
  }

  onTextChange(event: Event) {
    // @ts-ignore
    this.onChange.emit(event.target.value);
  }

}
