import { Component, EventEmitter, HostListener, OnInit, Output } from '@angular/core';
import { ActivatedRouteSnapshot, Router } from '@angular/router';
import { MatSnackBar } from '@angular/material/snack-bar';
import { AdmisionService } from '../../../servicios/admision/admision.services';
import { CuentaCorrienteService } from '../../../servicios/cuentaCorriente/cuenta-corriente.service';
import { ListaValoresServices } from '../../../servicios/listaValores/listaValores.services';
import { SharedAutoAdmisionService } from '../../../servicios/autoAdmision/shared-auto-admision.service';
import { PrmAdmsionServices } from '../../../servicios/parametrosGeneralesAdmsion/parametrosGeneralesAdmsion.services';
import { CheckinModel } from '../../../model/checkinModel.model';
import { LoaderService } from '../../../servicios/loader/loader.service';
import { CONST_AUTOADM } from '../../../const';
import { AutoAdmisionService } from '../../../servicios/auto-admision/auto-admision.service';
@Component({
  selector: 'app-antecedentes-hospitalizacion',
  templateUrl: './antecedentes-hospitalizacion.component.html',
  styleUrls: ['./antecedentes-hospitalizacion.component.css'],
})
export class AntecedentesHospitalizacionComponent implements OnInit {

  mostrarCheckIn = false;
  listaCheckIn = [];
  checkInSelected = '';
  banderaSinCheckIn = false;
  cuentaCorrieteAbierta = false;
  sourceMotivo = [];
  nombrePaciente = '';
  hash:string ="";
  //hash:string="395208E4BDEE5ACD4CB8DB37C470A0022F46A565";
  cargandoDatos: boolean = true;
  durationInSeconds = 5;
  notieneCheckin: boolean = false;
  estado: boolean = false;
  msjSinCheckin = CONST_AUTOADM.MSJ_SIN_CHECKINS;

  imgEmpty = "../../../../assets/images/empty.svg"
  screenType: 'mobile' | 'tablet' | 'desktop' = 'desktop';

  @Output() dataPaso2AutoAdmision = new EventEmitter<any>();

  constructor(
    private admisionService: AdmisionService,
    private cuentaCorriente: CuentaCorrienteService,
    private router: Router,
    private listaValoresServices: ListaValoresServices,
    private sharedService: SharedAutoAdmisionService,
    private prmsAdmision: PrmAdmsionServices,
    private loadingService: LoaderService,
    private snackBar: MatSnackBar,
    private autoAdmisionService:AutoAdmisionService
  ) {}

  async ngOnInit(): Promise<void> {
    this.detectScreenSize();
    this.loadingService.setLoading(true);
    this.sharedService.setStepper(0);
    this.obtenerTodosLosParams();
    const snapshot = this.router.routerState.snapshot;
    const root = snapshot.root;
    await this.waitForHash();
    console.log("Hash", this.hash);
    this.sharedService.ppnHash = this.hash;
    await Promise.all([
      this.obtenerTodosLosParams(),
      this.llamarSucursales()
    ]);
    if (this.hash) {
      await this.obtenerVigencia(this.hash);
    } else {
      this.showSnackbar(CONST_AUTOADM.MSJ_NOTFOUND_INFO);
      //this.redireccionamiento();
      this.loadingService.setLoading(false);
    }
  }

  private waitForHash(): Promise<void> {
    return new Promise((resolve) => {
      const interval = setInterval(() => {
        const ppnHash = (window as any).ppnHashGlobal;
        if (ppnHash) {
          this.hash = ppnHash;
          clearInterval(interval);
          resolve();
        }
      }, 100);
    });
  }

  @HostListener('window:resize', ['$event'])
  onResize(event: any) {
    this.detectScreenSize();
  }

  detectScreenSize() {
    const width = window.innerWidth;
    if (width < 768) {
      this.screenType = 'mobile';
    } else if (width >= 768 && width < 1280) {
      this.screenType = 'tablet';
    } else {
      this.screenType = 'desktop';
    }
  }

  async obtenerVigencia(ppn: any) {
    await this.obtenerListaMotivo();
    this.listarCheckIns(ppn);
  }

  redireccionamiento() {
    window.top.location.href = 'https://www.clinicaalemana.cl/informacion-para-pacientes/todo-sobre-hospitalizacion';
  }

  showSnackbar(message: string) {
    this.snackBar.open(message, 'Cerrar', {
      duration: this.durationInSeconds * 1000,
      horizontalPosition: 'end',
      politeness: 'polite',
    });
  }
  showSnackbarLimiteCheckin(message: string) {
    this.snackBar.open(message, 'Cerrar', {
      duration: this.durationInSeconds * 1000,
      verticalPosition: 'top',
      horizontalPosition: 'end',
      politeness: 'polite',
    });
  }


  nuevaAutoAdmision() {
    if (this.listaCheckIn.length <= 4) {
      this.sharedService.emitData(this.sharedService.ppnHash);
      this.sharedService.checkinSelected = new CheckinModel();// esto para limpiar el model
      this.sharedService.checkinSelected.ppnPaciente = this.sharedService.ppnHash
      this.verificarExistenciaSucHosp();
      console.log('que hay aqui',this.sharedService.checkinSelected)
      this.router.navigate(['autoAdmision/paso1']);
    }else{
      this.showSnackbarLimiteCheckin(CONST_AUTOADM.MSJ_LIMITE_CHECKIN);
    }

  }

  estadoAutoAdmision(item: any): { texto: string; clase: string } {
    const estado = item?.estadoAutoadmision;
    if (estado) {
      return { texto: 'Completado', clase: 'completado' };
    } else {
      return { texto: 'Por completar', clase: 'por-completar' };
    }
  }

  async listarCheckIns(ppn: any) {
    try {
      const response: any = await this.autoAdmisionService
        .obtenerAdmisionesVigentesCheckinHash(ppn)
        .toPromise();
      if (response?.length > 0) {
        response?.forEach((item: any) => {
          item.motivoValor = ' ';
        });
        this.cargaCheckIn(response);
        this.sharedService.tieneCheckin = true;
        this.mostrarCheckIn = true;
      } else {
        if (this.banderaSinCheckIn == true) {
          this.cargaCheckIn(response);
          this.mostrarCheckIn = true;
          this.banderaSinCheckIn = false;
        } else {
          this.sharedService.tieneCheckin = false;
          this.notieneCheckin = true;
          this.cargandoDatos = false;
          this.loadingService.setLoading(false);
        }
      }
    } catch (error) {
      console.error('Error al obtener los check-ins:', error);
          }
  }

  obtenerListaMotivo(): Promise<void> {
    return new Promise((resolve, reject) => {
      this.listaValoresServices
        .obtenerParametrosGenerales('motivo', '', '')
        .subscribe(
          (respuesta: any) => {
            this.sourceMotivo = respuesta;
            this.sharedService.motivos = this.sourceMotivo;
            resolve();
          },
          (error: any) => {
            console.error('Error al obtener la lista de motivos:', error);
            reject(error);
          }
        );
    });
  }

  async cargaCheckIn(response): Promise<void> {
    this.cargandoDatos = true; // Mostrar spinner y mensaje
    //await this.obtenerListaMotivo();
    this.mostrarCheckIn = true;

    const listadoCheckIn = response.map((iterator) => {
      if (iterator.antecedentesHospitalizacion) {
        const motivoMatched = this.sourceMotivo.find(
          (motivo: any) =>
            motivo.llave == iterator.antecedentesHospitalizacion?.motivoCodigo
        );
        const fechaString = iterator.antecedentesHospitalizacion?.fechaIngreso?.split(
          'T'
        );

        const formattedFecha = this.formatFechaHora(
          fechaString[0],
          iterator.antecedentesHospitalizacion?.horaIngreso
        );

        return {
          ...iterator,
          id: iterator.id,
          fechaIngresoText: formattedFecha,
          medicoTratanteNombre:
            iterator.antecedentesHospitalizacion.medicoTratanteNombre,
          motivo: iterator.antecedentesHospitalizacion.motivoCodigo,
          motivoValor: motivoMatched ? motivoMatched.valor : null,
        };
      } else {
        return {
          ...iterator,
          id: iterator.id,
          //fechaIngreso: null,
          fechaIngresoText: null,
          medicoTratanteNombre: null,
          motivo: null,
          motivoValor: null,
        };
      }
    });

    this.listaCheckIn = listadoCheckIn;
    console.log('lista', this.listaCheckIn)
    if (this.listaCheckIn.length === 0) {
      this.checkInSelected = '';
    }

    this.cargandoDatos = false;
    this.loadingService.setLoading(false);
  }

  async eliminarCheckIn(checkIn): Promise<void> {
    try {
      await this.admisionService.accionCheckIn(checkIn, 'ANULAR').toPromise();
      this.listaCheckIn = this.listaCheckIn.filter(
        (item) => item.id !== checkIn
      );

      if (this.listaCheckIn.length === 1) {
        this.banderaSinCheckIn = true;
      }

      this.checkInSelected = '';
      if (this.listaCheckIn.length === 0) {
        this.notieneCheckin = true;
        this.mostrarCheckIn = false;
        this.sharedService.tieneCheckin = false;
      }
    } catch (error) {
      console.error('Error al eliminar el check-in:', error);
    }
  }

  async editarCheckIn(item: any) {
    this.sharedService.emitData(item);
    this.sharedService.checkinSelected = item;
    this.sharedService.checkinSelected.ppnPaciente = this.sharedService.ppnHash
    this.verificarExistenciaSucHosp();
    this.router.navigate(['autoAdmision/paso1']);

  }

  public formatFechaHora(fecha: string, hora: string): string {
    const meses = [
      'enero',
      'febrero',
      'marzo',
      'abril',
      'mayo',
      'junio',
      'julio',
      'agosto',
      'septiembre',
      'octubre',
      'noviembre',
      'diciembre',
    ];
    const [year, monthIndex, day] = fecha.split('-');
    const month = meses[parseInt(monthIndex) - 1];
    const formattedHora = hora.slice(0, 5);
    return `${day} de ${month} ${year}, ${formattedHora}`;
  }

  obtenerTodosLosParams() {
    this.prmsAdmision.parametrosAdmsion()
      .subscribe(
        (respuesta: any) => {
          this.sharedService.allParams = respuesta;
          this.sharedService.listaCotizantes = respuesta?.filter((e)=>e.categoria ==='cotizante');
          this.sharedService.listaSeguros = respuesta?.filter((e)=>e.categoria ==='seguro');
          this.sharedService.msjeDescargaDeclaracion = respuesta?.filter((e)=>e.categoria ==='declaracionAccidente');
        },
        (error: any) => {
          console.error('Error al obtener la lista de parámetros:', error);
        }
      );
  }

  llamarSucursales() {
    this.listaValoresServices.ObtenerSucursalesServices(2)
      .subscribe((resp: any) => {
        if (resp) {
          //console.log(resp, 'resp');
          this.sharedService.allSucursales = resp;
          this.sharedService.sucursalesString = resp?.map((e) => { return e.nombreSucursal });
        }
      },
        (error) => {
          console.log(error); // llamar a snackbar con error
        });
  }

  verificarExistenciaSucHosp(){
    if (this.sharedService.checkinSelected.sucursalHospId !== null && this.sharedService.checkinSelected.sucursalHospId !== 0 && this.sharedService.checkinSelected.sucursalHospId !== undefined) {
      this.sharedService.sucHospInicial = this.sharedService.allSucursales.find((e) => e.sucursalId.toString() ==
        this.sharedService.checkinSelected.sucursalHospId.toString());
    }
    else{
      this.sharedService.sucHospInicial = {
        "holdingId": 2,
        "sucursalOrigenId": 1,
        "nombreSucursal": "Vitacura",
        "sucursalHospId": 1,
        "centroCostoOrigen": "9970",
        "sucursalId": 1
      }
    }
  }
}
