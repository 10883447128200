<div class="container justify-content-center" style="background-color: transparent; padding: 0;">
  <div class="header-sep">
    <cas-text variant="headline2">Completa la información de tu acompañante.</cas-text>
  </div>

  <div class="container w-100 px-3 py-4 card" style="margin-top: 20px; border-radius: 8px; margin-bottom: 50px;">
    <form [formGroup]="formDatosPaso3" class="" style="border: none;">

      <div class="row gx-1 gy-2 mt-1">
        <div class="ancho-input acompanante">
          <mat-form-field class="custom-select-material input-auto w-100"
            [ngClass]="{'custom-select-material': isAcompananteError==false, 'custom-select-material-err':isAcompananteError==true }">
            <mat-select #acompananteSelect id="acompananteSelect" formControlName="acompanante"
              placeholder="¿Quieres registrar un acompañante?" (selectionChange)="changeSelect($event, 'acompanante')">
              <mat-option *ngFor="let acompanante of Acompanantes" [value]="acompanante">
                {{ acompanante }}
              </mat-option>
            </mat-select>
            <mat-hint *ngIf="isAcompananteError" class="error-text">Debes seleccionar una opción.</mat-hint>
          </mat-form-field>
        </div>
        <!-- **** Nombre ****-->
        <div *ngIf="esAcompanante" class="ancho-input nombre">
          <cas-input formControlName="nombre" label="Nombres" label="{{labelNombre}}" class="input-auto w-100"
            [placeholder]="labelNombre" [helperText]="helperTextNombre" isInputError="{{isNombreError}}"
            value="{{this.formDatosPaso3.value.nombre}}" (change)="changeSelect($event, 'nombre')" fullWidth required>
          </cas-input>
        </div>
      
        <!-- **** Apellido ****-->
        <div *ngIf="esAcompanante" class="ancho-input apellido">
          <cas-input formControlName="apellido" label="Apellidos" [placeholder]="labelApellido" label="{{labelApellido}}"
            class="input-auto w-100" value="{{this.formDatosPaso3.value.apellido}}" [helperText]="helperTextApellido"
            isInputError="{{isApellidoError}}" (change)="changeSelect($event, 'apellido')" fullWidth required>
          </cas-input>
        </div>
      
        <!-- **** Celular ****-->
        <div *ngIf="esAcompanante" class="ancho-input celular">
          <cas-input formControlName="celular" label="Celular" [helperText]="helperTextCelular"
            isInputError="{{isCelularError}}" label="{{labelCelular}}" [placeholder]="labelCelular" class="input-auto w-100"
            value="{{this.formDatosPaso3.value.celular}}" (change)="changeSelect($event, 'celular')" fullWidth required
            (keyup)="onKeyUp($event)">
          </cas-input>
        </div>
      
        <!-- **** Email ****-->
        <div *ngIf="esAcompanante" class="ancho-input email">
          <cas-input formControlName="email" label="Email" label="{{labelEmail}}" [helperText]="helperTextEmail"
            isInputError="{{isEmailError}}" [placeholder]="labelEmail" class="input-auto w-100"
            value="{{this.formDatosPaso3.value.email}}" (change)="changeSelect($event, 'email')" fullWidth required>
          </cas-input>
        </div>
      
        <!-- **** Parentesco ****-->
        <div *ngIf="esAcompanante" class="ancho-input parentesco">
          <mat-form-field class="custom-select-material input-auto w-100"
            [ngClass]="{'custom-select-material': isParentescoError==false, 'custom-select-material-err':isParentescoError==true }">
            <mat-select formControlName="parentesco" placeholder="Parentesco"
              (selectionChange)="changeSelect($event, 'parentesco')">
              <mat-option *ngFor="let parentesco of Parentescos" [value]="parentesco">
                {{ parentesco }}
              </mat-option>
            </mat-select>
            <mat-hint *ngIf="isParentescoError" class="error-text">Debes seleccionar una opción.</mat-hint>
          </mat-form-field>
        </div>
      
      
      
      
      
      </div>

          <div class="header-sep my-2 pt-3">
            <cas-text variant="headline5">Responsable del pagaré y mandato.</cas-text>
          </div>

          <!-- <div class="col-9 ps-0 pe-1 cas-alert" style="width: 100%;">
            <cas-alert text='{{this.msjeResponsablePagare}}' 
              variant="warning" 
              size="small">
            </cas-alert>
          </div> -->

          <!-- Inicio. Mensaje sin convenio -->
          <div class="col-xl-12 col-lg-12 col-md-12 py-1">
            <div style="border: 1px solid #FFB300; background: #FFF8E1; border-radius:9px; padding: 16px">
              <div class="row">
                <div class="col-1" style="text-align: center;">
                  <mat-icon class="material-icons-outlined ntt-icono-error">error_outline</mat-icon>
                </div>
                <div class="col-11" style="text-align: left;">{{ this.msjeResponsablePagare }}</div>
              </div>
            </div>
          </div>
          <!-- Fin. Mensaje sin convenio -->
          
          <div class="header-sep mt-2 mb-2">
          <cas-text variant="headline5">¿Quién lo firmará?</cas-text> 
          </div>
        
          
        
          <div class="row box mt-3">
            <div class="col-lg-12">
                <div class="row align-items-start">
                    <!-- Paciente -->
                    <div class="col-md-2 mb-3 mb-md-0" style="width: 125px !important;">
                        <div class="acom-check">
                            <app-radio-ds 
                                label="Yo mismo" 
                                (change)="changeSelect($event, 'paciente')" 
                                name="sinAcompa" 
                                radioCategory="normal" 
                                idDs="paciente" 
                                size="s3"
                                [isChecked]="respPacienChecked">
                            </app-radio-ds>
                        </div>
                    </div>
                    
                    <!-- Acompañante -->
                    <div class="col-md-3 mb-3 mb-md-0" style="width: 155px !important">
                        <div class="acom-check" style="width: 139px;">
                            <app-radio-ds 
                                label="Acompañante"  
                                [isDisabled]="isAcom"  
                                (change)="changeSelect($event, 'acompanante2')" 
                                name="sinAcompa" 
                                idDs="acompa" 
                                radioCategory="normal" 
                                size="s3"
                                [isChecked]="respAcompChecked">
                            </app-radio-ds>
                        </div>
                    </div>
                    
                    <!-- Otro -->
                    <div class="col-md-2 mb-3 mb-md-0" style="width: 90px !important;">
                        <div class="acom-check">
                            <app-radio-ds 
                                label="Otro" 
                                (change)="changeSelect($event, 'otro')" 
                                name="sinAcompa" 
                                radioCategory="normal" 
                                idDs="otro" 
                                size="s3"
                                [isChecked]="respOtroChecked">
                            </app-radio-ds>
                        </div>
                    </div>
                    
                    <!-- RUT Input -->
                    <div class="col-md-4" style="margin-top: -27px">
                      <div *ngIf="esOtro" style="display:flex;flex-wrap:nowrap;flex-direction:row;justify-content:space-around" class="mt-3">
                        <div style="width:30%">
                          <div style="width:100% ;padding-top:16px;padding-left:22px;"  class="custom-input-material">
                            Rut
                          </div>
                        </div>
                        <div style="width: 100%">
                          <mat-form-field  
                            style="width:100%;display: flex;align-items: center; margin-bottom: 15px;" 
                            class="custom-input-material"  
                            [ngClass]="{'custom-select-material': !isRutError, 'custom-select-material-err': isRutError}"
                          >
                            <input matInput formControlName="firmarutOtro" (input)="validacionRutIncorrecto($event.target.value)" 
                                  [errorStateMatcher]="isRutError ? customErrorStateMatcher : null" 
                                  [value]="formDatosPaso3.value.firmarutOtro">
                            <mat-hint 
                              *ngIf="isRutError && errorType === 'incorrect'" 
                              class="error-text" 
                              style="width: 214px !important;margin-top: 12px !important;"
                            >
                            El Rut es Incorrecto/ obligatorio
                            </mat-hint>
                            <mat-hint *ngIf="isRutError && errorType === 'sameAsPaciente'" class="error-text" style="width: 214px !important;margin-top: 12px !important;">El Rut no puede ser igual al del Paciente</mat-hint>
                          </mat-form-field>
                        </div>
                  </div>
                </div>
            </div>
          </div>
        </div>
  

        <!--- ADJUNTAR CI --->
        <!-- Adjunta cedula -->
        <div class="section-container w-100 mt-3 mb-1" style="margin-top: 1vh;">
          <cas-text variant="headline5">{{tituloCedulaIdentidad}}</cas-text>
          <cas-text variant="caption2">{{formatoCedulaIdentidad}}
          </cas-text>
          <div class="d-flex flex-wrap align-center justify-content-center w-100 mt-1" (mouseover)="validarDocsRequeridos();">
            <!-- Input para adjuntar el frente de la cédula -->
            <div class="col-12 col-md-6 col-xl-6 py-1 pe-1">
              <cas-input-file label="Lado frontal del documento" fullWidth fileName="{{frontal}}" [disabled]="isFrontal" [attr.isError]="isErrorFrenteReq"
                (change)="onFileSelected($event, 'frente')"></cas-input-file>
              <mat-hint *ngIf="isErrorFrenteReq" class="error-text ps-2" style="color:#E53935;">
                Adjunta el frente de la cédula
              </mat-hint>
            </div>
        
            <div class="col-12 col-md-6 col-xl-6 py-1 pe-1">
              <cas-input-file label="Parte de atrás del documento" fullWidth fileName="{{dorsal}}"  [attr.isError]="isErrorDorsoReq"
                [disabled]="isDorsal" (change)="onFileSelected($event, 'dorso')">
              </cas-input-file>
              <mat-hint *ngIf="isErrorDorsoReq" class="error-text ps-2" style="color:#E53935;">
                Adjunta el dorso de la cédula
              </mat-hint>
            </div>
          </div>
        
        </div>
          <!-- Fin Adjunta cedula -->
        
        <div class="body-cas" *ngIf="isExito">
          <cas-modal width="500px" open="true">
            <div class="icon-container">
              <app-icon-ds class="iconButton" iconName="check-circle-fill" size="32" color="green"></app-icon-ds>
            </div>
            <cas-text variant="headline1" style="text-align: center;">Éxito</cas-text>
            <br>
            <cas-text variant="body1" style="text-align: center;">La carga de Documentos se ha realizado
              correctamente</cas-text>
            <div class="top-right-icon">
              <cas-icon name="flat-close" size="16" (click)="closeModalDocs('Exito')"></cas-icon>
            </div>
            <div class="bottom">
              <div class="separator"></div>
              <button class="custom-button" style="width: 90%;" (click)="closeModalDocs('Exito')">Continuar</button>
            </div>
          </cas-modal>
        </div>
        
        
        <div class="body-cas" *ngIf="isError">
          <cas-modal width="500px" open="true">
            <div class="icon-container">
              <app-icon-ds class="iconButton" iconName="x-circle-fill" size="32" color="red"></app-icon-ds>
            </div>
            <cas-text variant="headline1" style="text-align: center;">¡Lo sentimos!</cas-text>
            <br>
            <cas-text variant="body1" style="text-align: center;">{{textFileError}}</cas-text>
            <div class="top-right-icon">
              <cas-icon name="flat-close" size="16" (click)="closeModalDocs('Error')"></cas-icon>
            </div>
            <div class="bottom">
              <div class="separator"></div>
              <button class="custom-button" style="width: 90%;" (click)="closeModalDocs('Error')">Entendido</button>
            </div>
          </cas-modal>
        </div>
        <!-- <div class="body-cas" *ngIf="isErrorDocumentoReq">
          <cas-modal width="500px" open="true">
            <div class="icon-container">
              <app-icon-ds class="iconButton" iconName="info-circle" size="32" color="#FFB300"></app-icon-ds>
            </div>
            <cas-text variant="headline1" style="text-align: center;">Recuerda</cas-text>
            <br>
            <cas-text variant="body1" style="text-align: center;">Ambos documentos son obligatorios</cas-text>
            <div class="top-right-icon">
              <cas-icon name="flat-close" size="16" (click)="closeModalDocs('Error')"></cas-icon>
            </div>
            <div class="bottom">
              <div class="separator"></div>
              <button class="custom-button" style="width: 90%;" (click)="closeModalDocs('Error')">Entendido</button>
            </div>
          </cas-modal>
        </div> -->
        
        <!--FIN ADJUNTAR CI-->

          <!-- **** Confirmación ****-->
          <div class="d-flex mt-4 ancho-input">
            <cas-checkbox name="name checkbox" class="me-2 mt-1" (change)="checkboxChanged($event)"></cas-checkbox>
            <cas-text variant="body1">
              Declaro que la información entregada es fidedigna, asumo total responsabilidad y entiendo que será almacenada en mi
              ficha clínica.
            </cas-text>
          </div>
        <div style="display: none;">
          <button #myButton3 id="myButton3">Click me!</button>
        </div>
      
    </form>
     <!-- **** Botones ****-->
     <div class="botonera d-flex flex-wrap gap-2 w-100 my-4">
      <cas-button text="Volver" variant="text1" (click)="volver()"></cas-button>
    
      <div (mouseover)="validarDocsRequeridos();">
        <cas-button text="Finalizar" variant="primary1" (click)="guardar()" loading="{{loadingNew}}"
          disabled="{{!isCheckboxSelected || loadingNew || isSaving }}">
        </cas-button>
      </div>
    
      
    </div>  
    <!-- **** Fin Botones ****-->          
  </div>

  <div class="body-cas" *ngIf="isFinalizacion">
    <cas-modal width="400px" open="true">
      <div class="icon-container">
        <app-icon-ds class="iconButton" iconName="check-circle-fill" size="32" color="green"></app-icon-ds> 
      </div>
      <cas-text variant="headline1" style="text-align: center;">Check-in realizado con éxito</cas-text>
      <br>
      <cas-text variant="headline5" style="text-align: center;">Ahora te invitamos a conocer más información sobre el proceso de hospitalización en nuestra clínica.</cas-text>
      <div class="top-right-icon">
        <cas-icon name="flat-close" size="16" (click)="close()"></cas-icon>
      </div>
      <div class="bottom">
        <div class="separator"></div>
          <button class="custom-button" style="width: 90%;" (click)="redireccionamiento()">Conocer más</button>
      </div> 
    </cas-modal>
  </div>


  <div class="body-cas" *ngIf="isFinalizacionError">
    <cas-modal width="400px" open="true">
      <div class="icon-container">
        <app-icon-ds class="iconButton" iconName="x-circle-fill" size="32" color="red"></app-icon-ds> 
      </div>
      <cas-text variant="headline1" style="text-align: center;">¡Lo sentimos!</cas-text>
      <br>
      <cas-text variant="headline5" style="text-align: center;">No logramos realizar tu check-in. Te recomendamos volver a intentarlo más tarde.</cas-text>
      <div class="top-right-icon">
        <cas-icon name="flat-close" size="16" (click)="close()"></cas-icon>
      </div>
      <div class="bottom">
        <div class="separator"></div>
        <button class="custom-button" style="width: 90%;" (click)="salirError()">Entendido</button>
      </div> 
    </cas-modal>
  </div>
</div>
<app-loader></app-loader>
