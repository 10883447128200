import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, of, forkJoin } from 'rxjs';
import { concatMap, catchError } from 'rxjs/operators';
import { environment } from '../../../environments/environment';
import { ClienteUtil } from './cliente.util';
import { IndicadoresService } from '../indicadores/indicadores.service';
import { Cliente } from 'src/app/model/cliente.model';
import { CONSTANTES } from '../../const';

export class ClienteException {
  mensaje: string;
  status: number

  constructor(status, mensaje) {
    this.status = status;
    this.mensaje = mensaje;
  }
}

@Injectable({
  providedIn: 'root'
})
export class ClienteService {

  private headers: HttpHeaders;
  private direccionPacienteApiResponse: any;
  private contactoPacienteApiResponse: any;
  private pacienteApiResponse: any;
  private cotizante: any;
  private paso1ResponseCopia: any;

  public allPaises: any[] = [];
  public paisesString: any[] = [];
  public allRegiones: any[] = [];
  public regionesString: any[] = [];
  public allComunas: any[] = [];
  public comunasString: any[] = [];
  public allPrevisiones: any[] = [];
  public previsionesString: any[] = [];


  constructor(
    private http: HttpClient,
    private clienteUtil: ClienteUtil,
    private indicadoresService: IndicadoresService
  ) {
    this.headers = new HttpHeaders();
    this.headers = this.headers.append('Content-Type', 'application/json');
    this.headers = this.headers.append('Access-Control-Allow-Origin', '*');
    this.headers = this.headers.append('Access-Control-Allow-Methods', 'OPTIONS,POST,GET');
    this.headers = this.headers.append("Access-Control-Allow-Headers", "Content-Type, Authorization, X-Requested-With");

  }

  recuperarToken() {
    return localStorage.getItem('token');
  }


  obtenerClientePorDocumentoPaso1(documento: string, tipoDocumento: string, token: string, holding: string): Observable<any> {
    var ppn = undefined;
    var rsCliente = undefined;
    var rsContacto = undefined;


    return this.obtenerClientePorDocumento(documento, tipoDocumento)
      .pipe(
        concatMap(rs => { rsCliente = rs; this.pacienteApiResponse = rsCliente; ppn = rsCliente.ppn; return this.obtenerContacto(ppn, token); }),
        catchError(err => {
          if (err.status == 0 || (err.status == 404 && !rsCliente)) {
            throw new ClienteException(err.status, 'cliente');
          }

          return of(err.status)
        }),
        concatMap(rs => { rsContacto = rs; this.contactoPacienteApiResponse = rsContacto; return this.obtenerDirecciones(ppn, token); }),
        catchError(err => {
          if (err instanceof ClienteException) {
            throw err;
          }
          return of(null)
        }),
        concatMap(rs => {
          this.direccionPacienteApiResponse = rs;
          let response = this.clienteUtil.construirRespuestaObtenerClientePorDocumento(rsCliente, rsContacto, rs);
          this.paso1ResponseCopia = JSON.parse(JSON.stringify(response));

          return of(response);
        })
      );
  }


  obtenerClientePorDocumento(documento: string, tipoDocumento: string): Observable<any> {
    /** Cambiar el Holding cuándo se disponga de más de 1 **/
    return this.http.get(environment.urlMsSisnadhos + "/integracion/cliente/" + documento + '?holdingId='+2+'&tipoDocumento=' + tipoDocumento, {
      headers: this.headers,
    });
  }

  obtenerDirecciones(ppn, token?): Observable<any> {
    return this.http.get(environment.urlMsSisnadhos + "/integracion/cliente/" + ppn + '/direcciones', {
      headers: this.headers,
    });
  }

  obtenerContacto(ppn, token?): Observable<any> {
    return this.http.get(environment.urlMsSisnadhos + "/integracion/cliente/" + ppn + '/contactos', {
      headers: this.headers,
    });
  }

  modificarCliente(rq, ppn, token): Observable<any> {
    return this.http.put(environment.urlMsSisnadhos + "/integracion/cliente/" + ppn + '/cliente', rq, {
      headers: this.headers,
    });
  }

  modificarDirecciones(rq, ppn, token): Observable<any> {
    return this.http.put(environment.urlMsSisnadhos + "/integracion/cliente/" + ppn + '/direcciones', rq, {
      headers: this.headers,
    });
  }

  crearDirecciones(rq, ppn, token): Observable<any> {
    return this.http.post(environment.urlMsSisnadhos + "/integracion/cliente/" + ppn + '/direccion', rq, {
      headers: this.headers,
    });
  }

  modificarContacto(rq, ppn, token, tipo): Observable<any> {
    if (tipo == "put") {
      return this.http.put(environment.urlMsSisnadhos + "/integracion/cliente/" + ppn + '/contactos', rq, {
        headers: this.headers,
      });
    } else {
      return this.http.post(environment.urlMsSisnadhos + "/integracion/cliente/" + ppn + '/contactos', rq, {
        headers: this.headers,
      });
    }

  }

  getDireccionPacienteApiResponse() {
    return this.direccionPacienteApiResponse;
  }

  stDireccionPacienteApiResponse(direccion) {
    this.direccionPacienteApiResponse = direccion;
  }

  getContactoPacienteApiResponse() {
    return this.contactoPacienteApiResponse;
  }

  setContactoPacienteApiResponse(contacto) {
    this.contactoPacienteApiResponse = contacto;
  }

  getPacienteApiResponse() {
    return this.pacienteApiResponse;
  }

  setPacienteApiResponse(cliente) {
    this.pacienteApiResponse = cliente;
  }

  getPacienteResponseCopia() {
    return this.paso1ResponseCopia;
  }

  setPacienteResponseCopia(paso1) {
    this.paso1ResponseCopia = paso1;
  }

  reset() {
    this.pacienteApiResponse = null;
    this.direccionPacienteApiResponse = null;
    this.contactoPacienteApiResponse = null;
    this.contactoPacienteApiResponse = null;
  }


  existenCambiosDireccion(dataActual: any, dataOriginal: any) {
    let cambios = [];
    /* comuna, ciudad, direccion, pais */
    if ((dataActual.comuna.id !== dataOriginal.cliente.contacto.comuna.id ) && dataActual.comuna.id !== "") {
      cambios.push("comuna");
    }

    if ((dataActual.ciudad.nombre !== dataOriginal.cliente.contacto.ciudad.nombre) && dataActual.ciudad.nombre !== "") {
      cambios.push("ciudad");
    }

    if ((dataActual.pais.id !== dataOriginal.cliente.contacto.pais.id) && dataActual.pais.id !== "") {
      cambios.push("pais");
    }

    if ((dataActual.direccion !== dataOriginal.cliente.contacto.direccion) && dataActual.direccion !== "") {
      cambios.push("direccion");
    }

    return cambios.length > 0;
  }

  existenCambioContacto(dataActual: any, dataOriginal: any) {
    /* email, celular, t. fijo */
    let respuesta = {
      cambios: [],
      conCambios: false,
      mostrarModal: {
        mensaje:'',
        mostrar: false
      }
    };

    if ((dataActual.email !== dataOriginal.cliente.contacto.email ) && dataActual.email != "") {
      if (dataOriginal.cliente.contacto.email == undefined || dataOriginal.cliente.contacto.email == null || dataOriginal.cliente.contacto.email == "") {
        respuesta.cambios.push({ tipo: "email", body: this.crearCuerpoModificarContacto('email', dataActual.email), api: "post" });
        respuesta.conCambios = true;
      } else {
        respuesta.cambios.push({ tipo: "email", body: this.crearCuerpoModificarContacto('email', dataActual.email), api: "put" });
        respuesta.conCambios = true;
      }

    }else{
      respuesta.mostrarModal.mensaje = CONSTANTES.EMAIL_OBLIGATORIO;
      respuesta.mostrarModal.mostrar = true;
    }
    if ((dataActual.telefonoCelular !== dataOriginal.cliente.contacto.telefonoCelular) && dataActual.telefonoCelular != "") {
      if (dataOriginal.cliente.contacto.telefonoCelular == undefined || dataOriginal.cliente.contacto.telefonoCelular == null || dataOriginal.cliente.contacto.telefonoCelular == "") {
        respuesta.cambios.push({ tipo: "celular", body: this.crearCuerpoModificarContacto('celular', dataActual.telefonoCelular), api: "post" });
        respuesta.conCambios = true;
      } else {
        respuesta.cambios.push({ tipo: "celular", body: this.crearCuerpoModificarContacto('celular', dataActual.telefonoCelular), api: "put" });
        respuesta.conCambios = true;
      }
    }else{
      respuesta.mostrarModal.mensaje = CONSTANTES.TELEFONO_OBLIGATORIO;
      respuesta.mostrarModal.mostrar = true;
    }
    if ((dataActual.telefonoFijo !== dataOriginal.cliente.contacto.telefonoFijo) && dataActual.telefonoFijo != "") {
      if (dataOriginal.cliente.contacto.telefonoFijo == undefined || dataOriginal.cliente.contacto.telefonoFijo == null || dataOriginal.cliente.contacto.telefonoFijo == "") {
        respuesta.cambios.push({ tipo: "fijo", body: this.crearCuerpoModificarContacto('fijo', dataActual.telefonoFijo), api: "post" });
        respuesta.conCambios = true;
      } else {
        respuesta.cambios.push({ tipo: "fijo", body: this.crearCuerpoModificarContacto('fijo', dataActual.telefonoFijo), api: "put" });
        respuesta.conCambios = true;
      }
    }else{
      respuesta.mostrarModal.mensaje = CONSTANTES.FIJO_OBLIGATORIO;
      respuesta.mostrarModal.mostrar = true;
    }
    return respuesta;
  }

  crearRequestModificarDireccion(dataActual: any, rsDireccionApi: any, from?: any) {
    //TODO:Actualizar el 2 en duro cuando tengamos informacion real de la sesion del paciente
    let holding = sessionStorage.getItem('Holding')??'2';
    let company;
    let virtual;
    if (holding=="2"){
        company = 2;
        virtual = 1
    }

    if (holding=="3"){
      company = 3;
      virtual = 2
    }

    if (holding=="6"){
    company = 6;
    virtual = 9
    }
    
    let request;
    if (rsDireccionApi) {
      request = JSON.parse(JSON.stringify(rsDireccionApi)).addresses[0];
    }
    else {
      request =
      {
        address: "",
        commune:
          { id: 0 },
        city: "",
        country:
          { id: 0 },
        status: "S",
        classification: 1,
        client_type: "N",
        company_id: company,
        virtual_master_id: virtual
      }
    }
    
    if(from == 'autoAdmision'){
      console.log('dataActual', dataActual)
      request.address = dataActual.direccion;
      request.commune.id = dataActual.comuna.id;
      request.city =  dataActual.ciudad;
      request.country.id = dataActual.pais.id;
      request.country.name = dataActual.pais.nombre;
      request.commune.name = dataActual.comuna.nombre;

    }else{
      request.address = dataActual.direccion;
      request.commune.id = dataActual.comuna.id;
      request.city =  dataActual.ciudad.nombre;
      request.country.id = dataActual.pais.id;
    }
    
    console.log('crearRequestModificarDireccion')
    return request;
  }

  crearRequestModificarContacto(data: any, ppn: string, token: string) {
    /* aqui se crea un array de Observables */
    let modificarContactos = [];
    for (let i = 0; i < data.cambios.length; i++) {

      modificarContactos.push(this.modificarContacto(data.cambios[i].body, ppn, token, data.cambios[i].api));
    }
    return modificarContactos;
  }


  crearCuerpoModificarContacto(tipo, nuevoValor) {
    if(this.contactoPacienteApiResponse == undefined){
      this.contactoPacienteApiResponse = ''
    }
    let contactoApi = this.clienteUtil.obtenerDatosContacto(this.contactoPacienteApiResponse, tipo);
    if (contactoApi) {
      contactoApi = JSON.parse(JSON.stringify(contactoApi));
      contactoApi.value = nuevoValor;
    } else {
      let contactoApiVacio = {
        classification: {
          id: 1
        },
        type: {
          id: tipo == "email" ? 3 : tipo == "celular" ? 4 : 1
        },
        value: "",
        status: "S"
      }

      console.log(contactoApiVacio)
      contactoApi = contactoApiVacio;
      contactoApi.value = nuevoValor;
    }

    return contactoApi;
  }

  modificarListaContacto(observables) {
    let source = forkJoin(observables);
    source.subscribe((res => console.log(res)));
  }

  obtenerCotizante(documento: string, tipoDocumento: string, token: string): Observable<Cliente> {
    var ppn = undefined;
    var indicadores = undefined;
    var rsCliente = undefined;
    return this.obtenerClientePorDocumento(documento, tipoDocumento)
      .pipe(
        concatMap(rs => {
          this.cotizante = this.clienteUtil.construirRespuestaCotizante(rs, indicadores);
          return of(this.cotizante);
        })
      );
  }

  isUndefined(params) {
    return params == undefined ? true : false;
  }
  
}
