import { AntecedenteHosp } from "./antecedentesHosp.model";

export class CheckinModel {
    holdingId:                     number;
    sucursalId:                    number;
    empresaId:                     number;
    cuentaCorriente:               string;
    ppnPaciente:                   any;
    estadiaId:                     number;
    documentoId:                   number;
    documentoNumero:               string;
    nombres:                       string;
    apellidoPaterno:               string;
    apellidoMaterno:               string;
    nombreSocial:                  string;
    codigoGenero:                  number;
    codigoTipoPaciente:            number;
    fechaNaciemiento:              any;//Date;
    direccion:                     string;
    telefono:                      string;
    email:                         string;
    codigoComuna:                  number;
    nombreComuna:                  string;
    codigoPais:                    number;
    nombrePais:                    string;
    pacienteInternacional:         boolean;
    codigoPrevision:               number;
    nombrePrevision:               string;
    numeroFichaClinica:            string;
    presupuesto:                   boolean;
    numeroCarpeta:                 number;
    observaciones:                 string;
    derivadorPpn:                  number;
    derivadorNombre:               string;
    usuarioCreador:                string;
    fechaCreacion:                 any;//Date;
    telefono_movil:                string;
    requerimientoPoderCodigo:      string;
    requerimientoPoderDescripcion: string;
    estado:                        number;
    ciudad:                        string;
    seguroComplementario:          boolean;
    conAcompanante:                boolean;
    convenioAccidente:             string;
    cartaEmpresa:                  boolean;
    checkIn:                       boolean;
    vigencia:                      boolean;
    estInfoSalud:                  string;
    nacionalidad:                  string;
    sucursalHospId:                number;
    seguroInternacional:           boolean;
    aceptarHojaIngreso:            boolean;
    id:                            number;
    motivoValor?:                  string;
    autoAdmision?:                 boolean;
    estadoAutoadmision?:           boolean;
    antecedentesHospitalizacion?:   AntecedenteHosp;
    
    
    public constructor(){
        this.id =                            0;
        this.holdingId =                     0;
        this.sucursalId =                    0;
        this.empresaId =                     0;
        this.cuentaCorriente =               '';
        this.ppnPaciente =                   '';
        this.estadiaId =                     0;
        this.documentoId =                   0;
        this.documentoNumero =               '';
        this.nombres =                       '';
        this.apellidoPaterno =               '';
        this.apellidoMaterno =               '';
        this.nombreSocial =                  '';
        this.codigoGenero =                  0;
        this.codigoTipoPaciente =            0;
        this.fechaNaciemiento =              null;//Date;
        this.direccion =                     '';
        this.telefono =                      '';
        this.email =                         '';
        this.codigoComuna =                  0;
        this.nombreComuna =                  '';
        this.codigoPais =                    0;
        this.nombrePais =                    '';
        this.pacienteInternacional =         false;
        this.codigoPrevision =               0;
        this.nombrePrevision =               '';
        this.numeroFichaClinica =            '';
        this.presupuesto =                   false;
        this.numeroCarpeta =                 0;
        this.observaciones =                 '';
        this.derivadorPpn =                  0;
        this.derivadorNombre =               '';
        this.usuarioCreador =                '';
        this.fechaCreacion =                 null;//Date;
        this.telefono_movil =                '';
        this.requerimientoPoderCodigo =      '';
        this.requerimientoPoderDescripcion = '';
        this.estado =                        0;
        this.ciudad =                        '';
        this.seguroComplementario =          false;
        this.conAcompanante =                false;
        this.convenioAccidente =             '';
        this.cartaEmpresa =                  false;
        this.checkIn =                       false;
        this.vigencia =                      false;
        this.estInfoSalud =                  '';
        this.nacionalidad =                  '';
        this.sucursalHospId =                0;
        this.seguroInternacional =           false;
        this.aceptarHojaIngreso =            false;
        this.estadoAutoadmision =            false;
    }
}
