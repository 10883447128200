import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class PrmAdmsionServices {
  private headers: HttpHeaders;

  constructor(private http: HttpClient) {
    this.headers = new HttpHeaders();
    this.headers = this.headers.append('Content-Type', 'application/json');
  }

   parametrosAdmsion():Observable<any>{
      return this.http.get(environment.urlMsAutoadhos +"/prm-admision", {
        headers: this.headers
      });
   }
   
}
