import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';

@Component({
  selector: 'app-button-ds',
  templateUrl: './button-ds.component.html',
  styleUrls: ['./button-ds.component.scss']
})
export class ButtonDSComponent implements OnInit {

  @Input () color: string = "primary";
  @Input () outline: boolean = false;
  @Input () text: boolean = false;
  @Input () size: string = "";
  @Input () disabled: boolean = false;
  @Input () iconRight: boolean = false;
  @Input () iconLeft: boolean = false;
  @Input () iconName: string = "alarm";
  @Input () label: string = "Button";

  class: string;
  classIcon: string;

  @Output () onClickButton: EventEmitter<any> = new EventEmitter();

  constructor() { }

  ngOnInit(): void {
    this.class="btn btn-";
    this.classIcon="bi bi-"+this.iconName;

    if(this.outline===true){
      this.class=this.class+"outline-"+this.color
    }
    if(this.outline===false){
      this.class=this.class+this.color
    }
    if(this.size==="small"){
      this.class=this.class+" btn-sm"
    }
    if(this.size==="large"){
      this.class=this.class+" btn-lg"
    }
    if(this.text===true){
      this.class="btn btn-outline-light"
    }
  }

  clickBoton(event: Event) {
    this.onClickButton.emit(event);
  }

}
